<template>
  <v-text-field
    v-model="model"
    :class="selectedClass"
    :label="label"
    :suffix="perUnit ? currency.symbol + perUnit : currency.symbol"
    :readonly="readonly"
    :rules="allRules"
    :error-messages="errorMessages"
    @change="$emit('change', outputValue(model))"
    @input="$emit('input', outputValue(model))"
    @blur="$emit('blur', outputValue(model))"
  />
</template>

<script>
export default {
  name: 'CurrencyField',
  props: {
    value: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    currency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    selectedClass: {
      type: String,
      default: null
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    perUnit: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      model: null
    }
  },
  computed: {
    allRules () {
      const allRules = this.rules
      if (!this.required && (this.value === null || this.value === undefined || this.value === '')) {
        return []
      }
      if (this.type === 'number') {
        allRules.push(
          (val) => /^-?([0-9\s])+([.,]+[0-9]+)?$/.test(val)
        )
      }
      return allRules
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
      this.model = this.value
      }
    }
  },
  methods: {
    outputValue (value) {
      if (value && this.type === 'number') {
        //if the value is already a number, convert it to a string
        if (typeof value === 'number') {
          value = value.toString()
        }
        // Remove everything else except numbers and separators
        value = value.replaceAll(/[^\d.,-]/g, '')

        // Replace commas with dots. Otherwise the text value 
        // will not convert to number
        if (value.includes(',')) {
          value = value.replace(',', '.')
        }
        return Number(value)
      } 
      return value
    },
  }

}
</script>