<template>
  <v-card>
    <v-snackbar
      v-model="show"
      :bottom="b === 'bottom'"
      :left="x === 'left'"
      :right="x === 'right'"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
      :timeout="timeout"
      :color="color"
      aria-live="assertive"
    >
      {{ message }}
      <v-btn
        v-if="hasButton"
        small
        icon
        @click="show = false"
      >
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
export default {
  props: {
    show: Boolean,
    result: String,
    message: String,
    y: {
      default: 'top',
      type: String
    },
    x: {
      default: null,
      type: String
    },
    b: {
      default: 'bottom',
      type: String
    },
    timeout: {
      default: 4000,
      type: Number
    },
    hasButton: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      mode: '',
      // timeout: 4000
      color: 'success'
    }
  },
  mounted () {
    this.colorPicker()
  },
  methods: {
    colorPicker() {
      if (this.result === 'error') {
        this.color = 'error'
      } else {
        this.color = 'success'
      }
    } 
  }
}
</script>
<style scoped>
.v-snackbar {
  text-align: center;
  border-bottom-right-radius: 0.5em;
}
</style>
