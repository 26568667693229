<template>
  <div>
    <v-overlay :value="loadingSite">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-dialog
      v-model="showAddSiteDialog"
      persistent
      max-width="520"
    >
      <v-card>
        <AddSiteDialog
          :visible="showAddSiteDialog"
          :sites="selectableSites"
          :is-shown="showAddSiteDialog"
          :edit-site="editSite"
          :is-edit="editSite !== null"
          :hide-info="true"
          @close="closeAddSiteDialog"
          @add="addSite"
        />
      </v-card>
    </v-dialog>
    <v-expansion-panels
      :value="panelClosed ? 1 : 0"
      class="my-2"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('Sites') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container class="pa-2 justify-center align-center">
            <v-row
              v-for="site in selectedSites"
              :key="site.id_site"
              dense
              style="align-items: center;"
            >
              <div style="align-items: center; display:flex; justify-content:flex-start; width:100%;">
                <v-avatar
                  class="avatar"
                  color="blue lighten-5"
                >
                  <v-icon color="#00224f">
                    location_on
                  </v-icon>
                </v-avatar>
                <div class="site-content">
                  <div class="chip-title">
                    {{ site.siteName }}
                  </div>
                  <div class="chip-subtitle">
                    {{ site.units.length > 0 ? getUnitCodes(site.units) : $t("No units selected") }}<br>
                    {{ $t("Net floor area") }}: {{ formatArea(site.siteUnitArea) }}, {{ $t("Market rent simple") }}: {{ formatCurrencyPerMonth(site.siteUnitMarketRent) }}<br>
                  </div>
                </div>
                <div class="actions">
                  <v-btn
                    class="action"
                    icon
                    :aria-label="$t('Edit units') + site.siteName"
                    @click="editSelectedSite(site)"
                  >
                    <v-icon>
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="action"
                    icon
                    :aria-label="$t('Delete site') + site.siteName"
                    @click="deleteSite(site.id_site)"
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          class="action"
                          icon
                          :aria-label="$t('prospect.open_facility_management') + site.siteName"
                          @click="navigateSite(site.id_site)"
                        >
                          <v-icon>
                            open_in_new
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t('prospect.open_facility_management') }}</span>
                  </v-tooltip>
                </div>
              </div>
              <v-divider
                horizontal
                class="divider"
              />
            </v-row>
            <v-row class="py-2 px-1 mt-0">
              <v-btn
                :disabled="addingSite"
                text
                icon
                rounded
                class="add-site-btn"
                @click="showAddSiteDialog = true"
              >
                <v-icon>add</v-icon>
                {{ $t("Add site") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import AddSiteDialog from './AddSiteDialog.vue'

import { mapState } from 'vuex'

import helpers from "../../helpers"

export default {
  name: 'AddSiteForm',
  components: {
    AddSiteDialog
  },
  props: {
    selectedSites: {
      type: Array,
      default: () => []
    },
    panelClosed: {
      type: Boolean,
      default: false
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
      editSite: null,
      showAddSiteDialog: false,
      addingSite: false,
      loadingSite: false,
      units: []
    }
  },
  computed: {
    ...mapState('app', ['availableSites']),

    selectableSites () {      
      if (this.selectedSites.length !== 0 )  {
        const selectedIds = this.selectedSites.map(site => site.id_site)
        const returnedSites =  this.availableSites.filter(site => !selectedIds.includes(site.id_site))
        if (this.editSite) {
            returnedSites.unshift(this.editSite.site)
        }

        return returnedSites
      }
      
      return this.availableSites 
    },
  },
  methods: {
    formatArea (val){
      return helpers.format.formatData(val, "Area")
    },
    formatCurrencyPerMonth (val){
      return helpers.format.formatData(val, "CurrencyPerMonth", this.selectedCurrency.symbol)
    },
    closeAddSiteDialog () {
      this.editSite = null
      this.showAddSiteDialog = false
    },
    navigateSite (siteId) {
      const routerData = this.$router.resolve({ name: 'sites.facilitymanagement', params: { id_site: siteId}})
      window.open(routerData.href, '_blank')
    },
    getUnitCodes (siteUnits) {
      return siteUnits.map(unit => this.$t(unit.unit_code)).join(", ");
    },
    addSite (newSite, isEdit = false, oldSiteIndex = -1) {
      if (newSite && newSite.site) {
        const site = this.createSideObject(newSite)
        if (isEdit) {
          const index = this.selectedSites.findIndex(item => item.id_site === oldSiteIndex)
          this.$emit('edit', {index, site})
        } else {
          this.$emit('add', site)
        }
      }
      this.editSite = null
      this.showAddSiteDialog = false
    },
    deleteSite (siteId) {
      const index = this.selectedSites.findIndex(item => item.id_site === siteId)
      this.$emit('delete', index)
    },

    async editSelectedSite (site) {
      this.loadingSite = true
      const selectedSite = this.availableSites.find(item => item.id_site === site.id_site)
      let selectedBuilding = null
      const selectedFloors = []
      let selectedUnits = []

      // Units here don't have knowledge of their buildings or floors so we have to get that info
      if (site.units.length > 0) {
        this.loadingSite = true

        const buildings = await this.$rambollfmapi.buildings.list({ query: { siteId: site.id_site } })

        const buildingPromises = buildings.map(async building => {
          const { building_code: buildingCode } = building

          const floors = await this.$rambollfmapi.buildings.floors(buildingCode).list()

          const floorPromises = floors.map(async floor => {
            const { id: floorId } = floor

            const units = await this.$rambollfmapi.floors.units(floorId).list()

            const foundUnits = units.filter(item => site.units.findIndex(unit => unit.id_unit === item.id) !== -1)

            if (foundUnits.length > 0) {
              selectedBuilding = building
              selectedFloors.push(floor)
              selectedUnits = selectedUnits.concat(foundUnits)
            }
          })

          await Promise.all(floorPromises)
        })

        await Promise.all(buildingPromises)
      }

      this.editSite = {
        site: selectedSite,
        building: selectedBuilding,
        floors: selectedFloors,
        units: selectedUnits
      }

      this.showAddSiteDialog = true
      this.loadingSite = false
    },
    createSideObject (newSite) {
      const site = {
        id_site: newSite.site.id_site, 
        siteName: newSite.site.name, 
        siteUnitArea: 0,
        siteUnitMarketRent: 0,
        units: [],
      }

      newSite.units.forEach(unit => {
        if (unit.area) {
          site.siteUnitArea += unit.area

          if(unit.market_rent){
            site.siteUnitMarketRent += unit.market_rent * unit.area
          }
        }

        site.units.push({ id_unit: unit.id, unit_code: unit.unit_code })
      })

      return site
    }
  },
  emits: ['delete', 'add', 'edit']
}
</script>

<style scoped>
.add-site-btn {
  width: 50%;
  background: var(--c-color-accent) !important;
  color: white !important;
  margin-left: 70px;
}
.actions {
  margin: 10px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.action {
  margin: 0;
}
.avatar {
  margin: 10px;
}
.chip-title {
  font-size: 14px;
}
.chip-subtitle {
  font-size: 13px;
  font-weight: normal;
}
.divider {
  margin: 5px;
  margin-left: 70px;
}
.site-content {
  margin: 5px;
}
</style>
