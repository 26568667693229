<template>
  <div id="billing">
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
      :y="yPosition"
    />
    <v-dialog
      v-model="showContractModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateRentContractModal
          v-if="showContractModal"
          :contract-id="editedContractId"
          :out-rent="outRent"
          @close="onCreateRentContractModalClosed()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCreateInvoicesModal"
      persistent
      width="90%"
    >
      <v-card>
        <CreateInvoicesModal
          v-if="showCreateInvoicesModal"
          :draft-invoices="draftInvoices"
          :new-invoice-errors="newInvoiceErrors"
          @close="closeCreateInvoicesModal"
          @sent="
            showIndicator = true
            operationResult = 'sent'
          "
          @refreshData="refreshData"
        />
      </v-card>
    </v-dialog>
    <v-row
      class="fill-height"
      no-gutters
    >
      <v-dialog
        v-model="showRentReviewModal"
        persistent
        eager
        width="90%"
      >
        <v-card>
          <RentReviewModal
            v-if="showRentReviewModal"
            @save-success="rentReviewSaveSuccess"
            @close="(showRentReviewModal = false)"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showNewHandInvoiceModal"
        persistent
        eager
        width="90%"
      >
        <v-card>
          <NewHandInvoiceModal
            v-if="showNewHandInvoiceModal"
            :invoice-material="invoiceMaterialForHandInvoice"
            :is-existing-invoice="existingHandInvoice"
            @close="onCloseHandmadeModal"
            @created="
              showIndicator = true
              operationResult = 'created'
            "
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showNewRefundInvoiceModal"
        persistent
        eager
        width="90%"
      >
        <v-card>
          <NewRefundInvoiceModal
            :invoice-material="invoiceMaterialForCreditInvoice"
            :is-existing-invoice="existingCreditInvoice"
            @close="onCloseRefundModal"
            @created="
              showIndicator = true
              operationResult = 'created'
            "
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-row no-gutters>
      <BaseView>
        <template #selections>
          <h1 class="d-sr-only">
            {{ $t('Billing') }}
          </h1>
          <v-select
            v-model="billingMonth"
            :label="$t('Billing month')"
            :items="billingMonths"
            item-text="text"
            item-value="value"
          />
        </template>
        <template #buttons> 
          <v-btn
            v-if="hasApplicationPermissionByName('LASKUTUS_MUOKKAUS')"
            :disabled="loading"
            small
            outlined
            rounded
            @click="(showRentReviewModal = true)"
          >
            {{ $t('StartRentReview') }}
          </v-btn>
          <v-btn
            v-if="hasApplicationPermissionByName('LASKUTUS_MUOKKAUS')"
            :disabled="loading"
            small
            outlined
            rounded
            @click="(showNewHandInvoiceModal = true)"
          >
            {{ $t('New hand invoice') }}
          </v-btn>
          <v-btn
            v-if="hasApplicationPermissionByName('LASKUTUS_MUOKKAUS')"
            :disabled="loading"
            small
            outlined
            rounded
            @click="(showNewRefundInvoiceModal = true)"
          >
            {{ $t('New refund invoice') }}
          </v-btn>
        </template>
      </BaseView>
    </v-row>
    <div
      v-for="widget in allowedWidgets"
      :key="widget.id"
      class="widget px-4"
    >
      <DynamicWidget
        :id="widget.id"
        :type="widget.type"
        :data="widget.data"
        :title="$t(widget.id)"
        :loading="widget.loading"
        :maximized="widget.maximized"
        :date="selectedYearAndMonth"
        :hide-default-buttons="widget.hideDefaultButtons"
        @open="widget.maximized = $event"
        @triggerCustomEvents="triggerCustomEvents"
        @getAllFilteredData="widget.id == 'billing.unbilled_contracts'
          ? getFilteredUnbilledContracts($event)
          : undefined"
        @getSentInvoices="widget.id == 'billing.sent_invoices'
          ? getSentInvoices($event)
          : undefined"
        @getAllData="getAllData"
        @dataUpdate="updateArchiveData"
      >
        <template #table-buttons="props">
          <v-btn
            v-if="hasApplicationPermissionByName('LASKUTUS_MUOKKAUS') && widget.id === 'billing.unbilled_contracts'"
            :loading="openCreateInvoicesModalLoading"
            :disabled="openCreateInvoicesModalLoading"
            small
            outlined
            rounded
            @click="openCreateInvoicesModal(props.getAllFilteredData)"
          >
            {{ $t('CreateInvoices') }}
          </v-btn>
        </template>
        <template 
          v-if="widget.id === 'billing.unbilled_handmade_invoices_and_credit_notes'"
          #customComponent="{ item }"
        >
          <v-icon
            v-if="hasApplicationPermissionByName('LASKUTUS_MUOKKAUS')"
            right
            @click="deleteInvoice(item)"
          >
            delete
          </v-icon>
          <v-icon
            v-if="hasApplicationPermissionByName('LASKUTUS_MUOKKAUS')"
            right
            @click="modifyInvoice(item)"
          >
            edit
          </v-icon>
        </template>
        <template
          v-else-if="widget.id === 'billing.rent_review_bundles'"
          #customComponent="{ item }"
        >
          <v-tooltip
            v-if="item.layer == 0 && item.documentId != null"
            top
          >
            <template #activator="{ on }">
              <v-icon
                right
                @click="downloadReviewPdf(item.documentId)"
                v-on="on"
              >
                download
              </v-icon>
            </template>
            <span>
              {{ $t('rentReview.download_letters') }}</span>
          </v-tooltip>
        </template>
      </DynamicWidget>
    </div>
    <div
      v-if="loading"
      style="text-align: center; padding: 100px"
    >
      <div
        class="text-h5"
        style="margin-bottom: 2em"
      >
        {{ $t('Gathering information. Wait a second...') }}
      </div>
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </div>
  </div>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'
import DynamicWidget from '../components/DynamicWidget.vue'
import CreateRentContractModal from '../components/Leasing/Modals/CreateRentContracts/CreateRentContractModal.vue'
import RentReviewModal from '../components/Invoices/RentReviewModal.vue'
import NewHandInvoiceModal from '../components/Invoices/NewHandInvoiceModal.vue'
import BaseView from '../components/general/BaseView.vue'
import CreateInvoicesModal from '../components/Invoices/CreateInvoicesModal.vue'
import NewRefundInvoiceModal from '../components/Invoices/NewRefundInvoiceModal.vue'
import Alert from '../components/Alert.vue'
import helpers from '../helpers'
import { invoiceStates, invoiceTypes } from '../constants/invoiceConstants'

export default {
  name: 'BillingTab',
  components: {
    DynamicWidget,
    CreateRentContractModal,
    RentReviewModal,
    NewHandInvoiceModal,
    BaseView,
    CreateInvoicesModal,
    NewRefundInvoiceModal,
    Alert
  },
  props: {
    siteId: { type: Number, default: undefined }
  },
  metaInfo () {
    return {
      title: `${this.$t('Billing')} ·`,
    }
  },
  data () {
    return {
      billingMonth: moment(this.currentDate).format('YYYY-MM'),
      billingMonths: [],
      loading: true,
      showRentReviewModal: false,
      showNewHandInvoiceModal: false,
      showNewRefundInvoiceModal: false,
      showContractModal: false,
      editedContractId: undefined,
      outRent: true,
      showCreateInvoicesModal: false,
      currentUnbilledContracts: [],
      draftInvoices: [],
      openCreateInvoicesModalLoading: false,
      invoiceMaterialForHandInvoice: {},
      invoiceMaterialForCreditInvoice: {},
      existingHandInvoice: false,
      existingCreditInvoice: false,
      newInvoiceErrors: [],

      // Alert component variables
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      yPosition: 'top',

      allWidgets: [
        {
          id: 'billing.unbilled_contracts',
          type: 'LazyTable',
          loading: true,
          hideDefaultButtons: true,
          data: {
            headers: [
              { text: 'Contract number', value: 'contractNumber', event: 'openContractModal' },
              { text: 'Target name', value: 'siteName' },
              { text: 'Site portfolio', value: 'sitePortfolio', format: 'Array' },
              { text: 'Units', value: 'apartments' },
              { text: 'Landlord', value: 'landlord' },
              { text: 'Tenant', value: 'tenant' },
              { text: 'Validity', value: 'isFixedTerm', format: 'Array' },
              { text: 'Start date', value: 'startDate', format: 'Date' },
              { text: 'create_rent_contract.billing_start_date', value: 'billingStartDate', format: 'Date' },
              { text: 'End date', value: 'endDate', format: 'Date' },
              { text: 'Net floor area', value: 'apartmentArea', format: 'Area' },
              { text: 'ContractType', value: 'contractType' },
              { text: 'Clientele', value: 'clientele' },
              { text: 'Last increase date', value: 'lastIncreaseDate', format: 'Date' },
              { text: 'Last created invoice date', value: 'lastCreatedInvoiceDate', format: 'MonthAndYear' },
            ],
            customFormatters: {
              isFixedTerm: (row) => {
                return row.isFixedTerm ? this.$t('Fixed term') : this.$t('Permanent')
              },
              sitePortfolio: (row) => {
                if (this.sitePortfolioDefs && this.sitePortfolioDefs.length > 0) {
                  var matchingItem = this.sitePortfolioDefs.find(item => item.id === parseInt(row.sitePortfolio));
                  if (matchingItem) {
                    return matchingItem.label;
                  }
                  return '-';
                }
              }
            },
            items: [],
            isFullTable: true,
            source: 'UnbilledContracts',
            triggerGetAllData: 0,
            customPagination: {
              sortBy: ['contractNumber'],
              itemsPerPage: 50,
              sortDesc: [ true ],
            },
            customFilterContent: {
              isFixedTerm: [
                { text: this.$t('Permanent'), value: '0' },
                { text: this.$t('Fixed term'), value: '1' },
              ],
              sitePortfolio: []
            },
          }
        },
        {
          id: 'billing.unbilled_contracts_with_suppression',
          type: 'DataTable',
          loading: true,
          hideDefaultButtons: true,
          data: {
            exportCsv: true,
            headers: [
              { text: 'Contract number', value: 'contractNumber', event: 'openContractModal' },
              { text: 'Target name', value: 'siteName' },
              { text: 'Units', value: 'apartments' },
              { text: 'Landlord', value: 'landlord' },
              { text: 'Tenant', value: 'tenant' },
              { text: 'Validity', value: 'isFixedTerm' },
              { text: 'Start date', value: 'startDate' },
              { text: 'create_rent_contract.billing_start_date', value: 'billingStartDate' },
              // { text: 'contract.landlord first term of notice day', value: 'earliestNoticeDate', format: 'Date' },
              // { text: 'contract.tenant first term of notice day', value: 'earliestNoticeDateTenant', format: 'Date' }, 
              { text: 'End date', value: 'endDate' },
              { text: 'Net floor area', value: 'apartmentArea', format: 'Area' },
              // { text: 'Post office', value: 'postalDistrict' },
              { text: 'ContractType', value: 'contractType' },
              // { text: 'Clientele', value: 'clientele' },
              { text: 'Last created invoice date', value: 'lastCreatedInvoiceDate' },
              { text: 'Automatic billing allowed', value: 'automaticBillingAllowed' },
              { text: 'Automatic billing reasoning', value: 'automaticBillingReasoning' }
            ],
            customFormatters: {},
            items: [],
            sortBy: 'contractNumber',
            isFullTable: false,
          }
        },
        {
          id: 'billing.unbilled_handmade_invoices_and_credit_notes',
          type: 'DataTable',
          loading: true,
          data: {
            headers: [
              { text: 'Contract number', value: 'contractNumber' },
              { text: 'Tenant', value: 'tenant'},
              { text: 'Site name', value: 'siteName'},
              { text: 'Units', value: 'units'},
              { text: 'invoice_net_sum', value: 'netSum', format: 'Euro'},
              { text: 'Month', value: 'month'},
              { text: 'Invoice type', value: 'invoiceType'},
              { text: 'Invoice legend', value: 'invoiceLegend' },
              { text: 'Invoice creation user', value: 'createdByUserName'},
              { text: this.$t('Actions'), value: 'customComponent' }
            ],
            customFormatters: {
              month: (row) => {
                const date = new Date(row.year, row.month)
                date.setMonth(row.month - 1);
                return this.$t(date.toLocaleString('en-US', { month: 'long' }));
              },
              invoiceType: (row) => {
                if (row.invoiceType == 'HandInvoice') return this.$t('invoice.HandInvoice')
                if (row.invoiceType == 'CreditInvoice') return this.$t('invoice.CreditInvoice')
                return ""
              }
            },
            items: [],
            triggerGetAllData: 0
          }
        },
        {
          id: 'billing.sent_invoices',
          type: 'ObjectTable',
          loading: true,
          hideDefaultButtons: true,
          data: {
            exportCsv: true,
            subItemsOnly: true,
            headers: [
              { text: 'invoice_sent_timestamp', value: 'stateTimestamp', format: 'TimeZ' },
              { text: 'invoice_count', value: 'invoiceCount', format: 'Amount' },
              { text: 'invoice_net_sum', value: 'netSum', format: 'Euro' },
              { text: 'invoice_number', value: 'reference' },
              { text: 'invoice_date', value: 'billingDate' },
              { text: 'invoice_period', value: 'billingPeriod' },
              { text: 'invoice_type', value: 'type' },
              { text: 'Contract number', value: 'contractNumber' },
              { text: 'Tenant', value: 'tenantName' },
              { text: 'Target name', value: 'siteNames' },
              { text: 'State', value: 'state' },
              { text: 'Actions', value: 'toggle' },
            ],
            customFormatters: {
              type: (row) => {
                if (row.invoiceCount != 1) {
                  return this.$t('Multiple')
                }
                return row.type
              },
              billingDate: (row) => {
                if (row.invoiceCount != 1) {
                  return this.$t('Multiple')
                }
                return this.$t(moment(row.billingDate, "D.M.YYYY").format("M/YYYY"));
              },
              billingPeriod: (row) => {
                if (row.invoiceCount != 1) {
                  return this.$t('Multiple')
                }
                return row.billingPeriod
              },
              state: (row) => {
                if (row.state === 'invoice.Unknown') {
                  return this.$t(' ')
                }
                return row.state;
              },
              reference: (row) => {
                if (row.invoiceCount != 1) {
                  return this.$t('Multiple')
                }
                return row.reference;
              },
              contractNumber: (row) => {
                if (row.invoiceCount != 1) {
                  return this.$t('Multiple')
                }
                return row.contractNumber?.length > 0 ? row.contractNumber : '-'
              },
              tenantName: (row) => {
                if (row.invoiceCount != 1) {
                  return this.$t('Multiple')
                }
                return row.tenantName
              },
              siteNames: (row) => {
                if (row.invoiceCount != 1) {
                  return this.$t('Multiple')
                }
                if (row.invoiceCount === 1 && row.subItems?.[0]?.length < 2) {
                  return row.siteName
                }
                return row.siteNames
              },
            },
            items: [],
          }
        },
        {
          id: 'billing.rent_review_bundles',
          type: 'ObjectTable',
          loading: true,
          hideDefaultButtons: true,
          data: {
            exportCsv: true,
            subItemsOnly: true,
            headers: [
              { text: 'rentReviewBundle.timestamp', value: 'rentReviewTimestamp', format: 'TimeZ' },
              { text: 'rentReviewBundle.paymentCount', value: 'paymentCount', format: 'Amount' },
              { text: 'Contract number', value: 'contractNumber', event: 'openContractModal' },
              { text: 'Payment domain', value: 'paymentDomainName' },
              { text: 'rentReview.beforeReview', value: 'netSum', format: 'Euro' },
              { text: 'rentReview.afterReview', value: 'reviewedNetSum', format: 'Euro' },
              { text: 'change', value: 'increasePercentage' },
              { text: 'rental_status.basic_rent', value: 'basicRent', format: 'Euro' },
              { text: 'rentReviewBundle.review_letters', value: 'customComponent' },
              { text: '', value: 'toggle', event: 'getReviewBundleData' },
            ],
            customFormatters: {
              contractNumber: (row) => {
                if (row.contractNumber?.length > 0) {
                  return row.contractNumber
                }
                return this.$t('Multiple')
              },
              paymentDomainName: (row) => {
                if (row.paymentDomainName) {
                  return row.paymentDomainName;
                }
                return this.$t('Multiple')
              },
              increasePercentage: (row) => {
                if (row.paymentCount === 1) {
                  return helpers.format.formatData(row.increasePercentage, 'Percentage')
                }
                return this.$t('Multiple')
              },
            },
            items: [],
            shouldRequestAllData: true,
          }
        },
        {
          id: 'billing.invoice_archive',
          type: 'DataTable',
          loading: false,
          hideDefaultButtons: false,
          data: {
            exportCsv: true,
            headers: [
              { text: 'Contract number', value: 'contractNumber', event: 'openContractModal' },
              { text: 'Tenant', value: 'tenant' },
              { text: 'Site name', value: 'site_name', },
              { text: 'Site identifier', value: 'site_identifier'},
              { text: 'invoice.gross_sum', value: 'grossSum', format: 'Euro' },
              { text: 'invoice.gross_refunded', value: 'refundedGrossSum', format: 'Euro' },
              { text: 'invoice.refund_open', value: 'nonRefundedSum', format: 'Euro' },
              { text: 'Invoice legend', value: 'invoiceLegend' },
              { text: 'invoice.refunded_legend', value: 'refundedLegend' },
              { text: 'Invoice type', value: 'invoiceType', format: 'Array' },
              { text: 'invoice_number', value: 'reference' },
              { text: 'invoice_date', value: 'billingDate' },
              { text: 'invoice_period', value: 'billingPeriod' },
              { text: 'invoice_sent_timestamp', value: 'stateTimestamp', format: 'TimeZ' },
              { text: 'State', value: 'state', format: 'Array' }
            ],
            footers: [
              { text: this.$t('Total') },
              {},
              {},
              {},
              { value: 'grossSum', format: 'Euro' },
              { value: 'refundedGrossSum', format: 'Euro' },
              { value: 'nonRefundedSum', format: 'Euro' }
            ],
            customFormatters: {
              invoiceType: (row) => {
                return row?.invoiceType ? this.$t('invoice.' + row.invoiceType) : ""
              },
              billingDate: (row) => {
                  const date = row.billingDate ? moment(row.billingDate.split('T')[0], "YYYY-MM-DD") : undefined
                  return date && date.isValid() ? date.format("M/YYYY") : ""
              },
              billingPeriod: (row) => {
                return row.billingPeriod
              },         
              state: (row) => {
                const rowText = 'invoice.' + row.state
                if (!row?.state || rowText === 'invoice.Unknown') {
                  return this.$t(' ')
                }
                return this.$t(rowText)
              }
            },
            customFilterContent: {
              state: invoiceStates.filter(state => state !== "Unknown").map((state) => { 
                return {
                  text: this.$t('invoice.' + state),
                  value: state
                }}),
              invoiceType: invoiceTypes.map(type => {
                return {
                  text: this.$t('invoice.' + type),
                  value: type
                }
              })
            },
            items: [],
            browseRowsPerPage: 100,
            sortBy: ['contractNumber', 'billingDate'],
            sortDesc: [false, false],
            multiSort: true
          }
        },
      ],
    }
  },
  computed: {
    ...mapState('app', ['currentDate', 'userWidgets']),
    ...mapState('billing', ['billingWidgets', 'widgetList']),
    ...mapGetters('app', ['hasApplicationPermissionByName', 'definitionsByGroupLabel']),
    allowedWidgets () {
      const widgetIds = this.userWidgets.reduce((prev, current) => {
        prev[current.name] = current.id
        return prev
      }, {})
      const aw = this.allWidgets.filter(w => !!widgetIds[w.id])
      return aw
    },
    sitePortfolioDefs () {
      return this.definitionsByGroupLabel('site.portfolio')
    },
    selectedYearAndMonth () {
      const date = new Date()
      date.setDate(1)
      if (this.billingMonth) {
        date.setMonth((new Date(this.billingMonth + '-01')).getMonth())
        date.setFullYear((new Date(this.billingMonth + '-01')).getFullYear())
      }

      return date
    }
  },
  watch: {
    billingWidgets () {
      this.updateAllWidgets()
    },
    billingMonth () {
      this.getBillingWidgets(this.selectedYearAndMonth)
    },
    userWidgets () {
      this.getBillingWidgets(this.selectedYearAndMonth)
    },
    currentDate () {
      this.formBillingMonths()
    },
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    operationResult: function (value) {
      if (value === 'error') {
        this.indicatorMessage = this.$t('Unsuccesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_unsuccesfull'))
      }
      if (value === 'success') {
        this.indicatorMessage = this.$t('Succesfull save')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.new_user_added'))
      }
      if (value === 'sent') {
        this.indicatorMessage = this.$t('Successful send')
      }
      if (value === 'created') {
        this.indicatorMessage = this.$t('InvoiceCreateSuccess')
      }
      if (value === 'review') {
        this.indicatorMessage = this.$t('rentReview.success')
        this.$store.dispatch('app/addAriaLive', this.$t('rentReview.success'))
      }
    },
    sitePortfolioDefs: {
      immediate: true,
      handler (newSitePortfolioDefs) {
        if (newSitePortfolioDefs && newSitePortfolioDefs.length > 0) {
          var billingUnbilledContractsWidget = this.allWidgets.find(widget => widget.id === 'billing.unbilled_contracts');

          if (billingUnbilledContractsWidget) {
            billingUnbilledContractsWidget.data.customFilterContent.sitePortfolio = newSitePortfolioDefs.map(item => ({
              text: item.label,
              value: item.id.toString()
            }));
          }
        }
      }
    }
  },
  mounted: async function () {
    // fetch search values for invoice archive search fields
    await this.getInvoiceArchiveSearchKeys(this.currentDate)
    this.formBillingMonths()
    this.loading = false
    if (this.userWidgets.length > 0) {
      this.getBillingWidgets(this.selectedYearAndMonth)
    }
  },
  methods: {
    ...mapActions('billing', ['getBillingWidgets', 'getOneBillingWidget', 'setSelectedWidgetName', 'setGetAll', 'getInvoiceArchiveSearchKeys']),
    getValidDate (val) {
      const date = moment(val.split('T')[0], "YYYY-MM-DD")
      return date.isValid() ? date.format("D.M.YYYY") : undefined
    },
    updateAllWidgets () {
      for (let i = 0; i < this.allWidgets.length; i++) {
        const report = this.widgetList[this.allWidgets[i].id]
        if (report && this.billingWidgets[report]) {
          this.allWidgets[i].data.items = this.billingWidgets[report]
          this.allWidgets[i].loading = false
          if (this.allWidgets[i].id === 'billing.unbilled_contracts_with_suppression') {
            this.allWidgets[i].data.items = this.billingWidgets[report].map((el) => ({
              ...el,
              isFixedTerm: el.isFixedTerm ? this.$t('Fixed term') : this.$t('Permanent'),
              automaticBillingAllowed: el.automaticBillingAllowed ? this.$t('Yes') : this.$t('No'),
              startDate: el.startDate ? this.getValidDate(el.startDate) : "",
              endDate: el.endDate ? this.getValidDate(el.endDate) : "",
              billingStartDate: el.billingStartDate ? this.getValidDate(el.billingStartDate) : "",
              lastCreatedInvoiceDate: helpers.format.formatData(el.lastCreatedInvoiceDate, 'MonthAndYear'),
            }))
          }
          else if (this.allWidgets[i].id === 'billing.sent_invoices') {
            this.allWidgets[i].data.items = this.billingWidgets[report].map((el, index) => ({
              ...el,
              id: index,
              showToggle: true,
              state: el.state ? this.$t('invoice.' + el.state) : '',
              billingDate: el.billingDate ? this.getValidDate(el.billingDate) : "",
              type: el.type ? this.$t('invoice.' + el.type) : '',
              subItems: el.invoices.map(inv => ({
                ...inv,
                state: el.state ? this.$t('invoice.' + el.state) : '',
                invoiceCount: 1,
                billingDate: inv.billingDate ? this.getValidDate(inv.billingDate) : "",
                type: inv.type ? this.$t('invoice.' + inv.type) : '',
              })),
            }))
          }
          else if (this.allWidgets[i].id === 'billing.rent_review_bundles') {
            this.allWidgets[i].data.items = this.billingWidgets[report].map((el, index) => ({
              ...el,
              id: index,
              showToggle: true,
              subItems: el.rentReviews ?? [],
            }))
          }
        }
      }
    },
    formBillingMonths () {
    const currentDate = this.currentDate
    this.billingMonths.push({
      text: moment(currentDate).subtract(2, 'months').format('YYYY') + ' ' +
            this.$t(moment(currentDate).subtract(2, 'months').format('MMMM')),
      value: moment(currentDate).subtract(2, 'months').format("YYYY-MM")
    },
    {
      text: moment(currentDate).subtract(1, 'months').format('YYYY') + ' ' +
            this.$t(moment(currentDate).subtract(1, 'months').format('MMMM')),
      value: moment(currentDate).subtract(1, 'months').format("YYYY-MM")
    },
    {
      text: moment(currentDate).format('YYYY') + ' ' +
            this.$t(moment(currentDate).format('MMMM')),
      value: moment(currentDate).format("YYYY-MM")
    },
    {
      text: moment(currentDate).add(1, 'months').format('YYYY') + ' ' + 
            this.$t(moment(currentDate).add(1, 'months').format('MMMM')),
      value: moment(currentDate).add(1, 'months').format("YYYY-MM")
    },
    {
      text: moment(currentDate).add(2, 'months').format('YYYY') + ' ' +
            this.$t(moment(currentDate).add(2, 'months').format('MMMM')),
      value: moment(currentDate).add(2, 'months').format("YYYY-MM")
    })
    },
    onCreateRentContractModalClosed () {
      this.showContractModal = false
      this.editedContractId = undefined
      this.refreshData()
    },
    async getSentInvoices (emit) {
      await this.refreshOneWidget('billing.sent_invoices', emit.getAll)
      emit.exportAsCsv()   
    },
    openContractModal (row) {
      this.editedContractId = row.contractId ?? row.idContract ?? row.id
      this.outRent = true
      this.showContractModal = true
    },
    triggerCustomEvents (event) {
      if (event.eventName === 'openContractModal') {
        this.openContractModal(event.row)
      }
      else if (event.eventName === 'getReviewBundleData') {
        this.getReviewBundleData(event.row)
      }
    },
    async openCreateInvoicesModal (getAllFilteredData) {
      this.openCreateInvoicesModalLoading = true
      var filteredData = await getAllFilteredData()
      this.currentUnbilledContracts = filteredData?.map(contract => contract.id)
      this.showCreateInvoicesModal = true
      var errors = await this.$rambollfmapi.invoices.createInvoicesFromContractList(this.currentUnbilledContracts, this.selectedYearAndMonth)
      if (Array.isArray(errors)) {
        this.newInvoiceErrors = errors
      }
      this.getDrafts()
      this.openCreateInvoicesModalLoading = false
    },
    async getDrafts () {
      this.draftInvoices = await this.$rambollfmapi.invoices.getInvoiceDrafts(this.currentUnbilledContracts, this.selectedYearAndMonth)
    },
    closeCreateInvoicesModal () {
      this.showCreateInvoicesModal = false
      this.draftInvoices = []
      this.newInvoiceErrors = []
      this.getBillingWidgets(this.selectedYearAndMonth)
    },
    async refreshData () {
      await this.getBillingWidgets(this.selectedYearAndMonth)
      //trigger updates to lazytables
      for (let i = 0; i < this.allowedWidgets.length; i++) {
        if (typeof this.allowedWidgets[i].data.triggerGetAllData !== 'undefined') {
          this.allowedWidgets[i].data.triggerGetAllData += 1
        }
      }
    },
    async refreshOneWidget (widgetId, getAll) {
      if (getAll !== undefined) {
        await this.setGetAll(getAll)
      }
      await this.setSelectedWidgetName(widgetId)
      await this.getOneBillingWidget(this.selectedYearAndMonth)
      this.updateAllWidgets()
      for (let i = 0; i < this.allowedWidgets.length; i++) {
        if (this.allowedWidgets[i].id == widgetId && typeof this.allowedWidgets[i].data.triggerGetAllData !== 'undefined') 
          this.allowedWidgets[i].data.triggerGetAllData += 1
      }
    },
    onCloseRefundModal () {
      this.showNewRefundInvoiceModal = false
      this.existingCreditInvoice = false
      this.invoiceMaterialForCreditInvoice = {}
      this.refreshOneWidget('billing.unbilled_handmade_invoices_and_credit_notes')
    },
    onCloseHandmadeModal () {
      this.showNewHandInvoiceModal = false
      this.existingHandInvoice = false
      this.invoiceMaterialForHandInvoice = {}
      this.refreshOneWidget('billing.unbilled_handmade_invoices_and_credit_notes')
    },
    updateCurrentUnbilledContracts (currentRows) {
      this.currentUnbilledContracts = currentRows ? currentRows.map(row => row.id) : []
    },
    async getFilteredUnbilledContracts (filteredData) {
      const data = await filteredData
      this.currentUnbilledContracts = data?.map(contract => contract.id)
      this.getDrafts()
    },
    async modifyInvoice (invoice) {
      this.existingHandInvoice = false
      this.existingCreditInvoice = false
      let invoiceMaterial = await this.$rambollfmapi.invoices.invoiceMaterial(invoice.id, this.selectedYearAndMonth)
      if (invoiceMaterial.invoiceType == 'HandInvoice') {
        this.invoiceMaterialForHandInvoice = invoiceMaterial
        this.showNewHandInvoiceModal = true
        this.existingHandInvoice = true
      }
      if (invoiceMaterial.invoiceType == 'CreditInvoice') {
        this.invoiceMaterialForCreditInvoice = invoiceMaterial
        this.showNewRefundInvoiceModal = true
        this.existingCreditInvoice = true
      }
    },
    async deleteInvoice (invoice) {
      if (confirm(this.$t("Do you really want to remove invoice"))) {
        await this.$rambollfmapi.invoices.delete(invoice.id)
        this.refreshOneWidget('billing.unbilled_handmade_invoices_and_credit_notes')
      }
    },
    rentReviewSaveSuccess () {
      this.showRentReviewModal = false
      this.refreshOneWidget('billing.rent_review_bundles')
      this.showIndicator = true
      this.operationResult = 'review'
    },
    async getReviewBundleData (row) {
      const widgetData = await this.$rambollfmapi.reports.list([ this.widgetList['billing.rent_review_bundles'] ], [], null, [], [], row.rentReviewBundle)
      const bundles = widgetData?.[this.widgetList['billing.rent_review_bundles']]
      const reviewWidgetIndex = this.allWidgets.findIndex(w => w.id === 'billing.rent_review_bundles')

      if (bundles?.length > 0 && bundles[0]?.rentReviews?.length > 0) {
        this.allWidgets[reviewWidgetIndex].data.items[row.id].subItems = bundles[0].rentReviews
      }
    },
    async getAllData (widget) {
      if (widget.id) {
        await this.refreshOneWidget(widget.id, true)
        if (typeof widget.callback === 'function') {
          widget.callback()
        }
      }
    },
    async updateArchiveData (event) {
      if (event.widgetId == 'billing.invoice_archive') {
        const index = this.allWidgets.map(w => w.id).indexOf('billing.invoice_archive')
        let invoicesWithNoContractIndex = -1
        if (index) {
          if (event.query.contractNumbers.includes(this.$t('InvoicesWithNoContract'))) {
            invoicesWithNoContractIndex = event.query.contractNumbers.indexOf(this.$t('InvoicesWithNoContract'))

            if (invoicesWithNoContractIndex > -1) {
              event.query.contractNumbers[invoicesWithNoContractIndex] = 'InvoicesWithNoContract'
            }            
          } else {
              event.query.contractNumbers = event.query.contractNumbers.filter(contractNumber => contractNumber !== "InvoicesWithNoContract"
            )
          }
          const invoiceArchive = await this.$rambollfmapi.invoices.getInvoiceArchive(event.query, this.currentDate)
          if (invoicesWithNoContractIndex > -1) {
            event.query.contractNumbers[invoicesWithNoContractIndex] = this.$t('InvoicesWithNoContract')
          }
          this.allWidgets[index].data.items = invoiceArchive
        }
      }
    },
    async downloadReviewPdf (documentId) {
      const progress = {}
      try {
        const document = await this.$rambollfmapi.documents.download(documentId, progress)
        if (document.name === 'Error') {
          throw new Error("Document not found")
        }
        if (document) {
          const blob = new Blob([document.data], { type: 'octet/stream' })
          const filename = document.headers["content-disposition"].match(/filename\s*=\s*(.+);/i)[1].replaceAll('"', '');
          helpers.saveAs(blob, filename)
        }
      } catch (error) {
        this.$log.error(error)
      }
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
  }
}
</script>

<style scoped>
.v-datatable tfoot {
  empty-cells: hide;
}

.col {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
}
</style>