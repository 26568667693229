import { i18n } from '../../../../../../plugins/i18n'

export default {
  getTemplate: (data) => {
    const {  
      tenant, 
      otherTenant, 
      uniqueSites,
      selectedUnitsAndStructures,
      selectedParkingSpaces,
      rentPeriod,
      noticePeriod,
      housingModification, 
      rent,
      contractPayments,
      separatePayments,
      singlePayments,
      rentReviews,
      guarantees,
    } = data
    return {
      sections: [
        {
          title: 'pdf.parties',
          id: 'landlord',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'landlord', // source object
          sourceDataType: 'object',
          columnStyles: 'default', // default is two columns, first is 50 wide
          template: [ // per-row template
            { key: 'landlord', value: 'name', header: i18n.t('pdf.landlord') },
            { key: 'landlord', value: 'phoneNumber', header: i18n.t('pdf.phone')},
            { key: 'landlord', value: 'address', header: i18n.t('pdf.address') },
            { key: 'landlord', value: 'businessId', header: i18n.t('pdf.y_code') },
            { key: 'landlord', value: 'contactPersonName', header: i18n.t('pdf.contact_person_name')},
            { key: 'landlord', value: 'contactPersonEmail', header: i18n.t('pdf.contact_person_email') },
            { key: 'landlord', value: 'contactPersonPhoneNumber', header: i18n.t('pdf.phone') },
            { key: 'landlord', value: 'accountNumber', header: i18n.t('pdf.bank_account') },
          ]
        },
        {
          title: '',
          id: 'tenant',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'tenant', // source object
          sourceDataType: 'object',
          columnStyles: 'default', // default is two columns, first is 50 wide
          template: getTenantTemplate(tenant)
        }, 
        {
          title: '',
          id: 'otherTenant',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'otherTenant', // source object
          sourceDataType: 'object',
          columnStyles: 'default', // default is two columns, first is 50 wide
          template: getTenantTemplate(otherTenant, 'otherTenant')
        },
        ...uniqueSites.map((s, index) => {
          return {
            title: index === 0 ? 'pdf.contract_sites': '',
            id: `site-${s.id}`,
            type: 'table',
            direction: 'column', // direction, row is default
            source: 'uniqueSites', // source array
            sourceDataType: 'array',
            columnStyles: 'default', // default is two columns, first is 50 wide
            template: [ // per-row template
              { key: 'uniqueSites', value: 'site', header: i18n.t("pdf.site_name") },
              { key: 'uniqueSites', value: 'address', header: i18n.t("pdf.address") },  
              { key: 'uniqueSites', value: 'spaceUsage', header: i18n.t("SpaceUsage") },
            ]
          }
        }),
        selectedUnitsAndStructures?.length === 0 ? {} :
        {
          title: 'pdf.units',
          id: 'selectedUnitsAndStructures',
          type: 'table',
          direction: 'row', // direction, row is default
          source: 'selectedUnitsAndStructures', // source array
          sourceDataType: 'array',
          columnStyles: 'default', // default is two columns, first is 50 wide
          hasSumRow: true,
          subInfoRow: 'pdf.areasNotChecked',
          template: [ // per-row template
            { key: 'selectedUnitsAndStructures', value: 'unitCode', header: i18n.t('pdf.unique_id')},
            { key: 'selectedUnitsAndStructures', value: 'usage', header: i18n.t('customPdf.usage') },
            { key: 'selectedUnitsAndStructures', value: 'agreedAreaText', header: i18n.t('pdf.amount') },
          ]
        },
        {
          title: 'pdf.additionalInfoUnits',
          id: 'additionalInfoUnits',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'additionalInfoUnits', // source string
          sourceDataType: 'string',
          columnStyles: {}, // one page width column
          template: [ // per-row template
            { value: 'additionalInfoUnits' },
          ]
        },
        selectedParkingSpaces?.length === 0 ? {} :
        {
          title: 'pdf.parkingSpaces',
          id: 'selectedParkingSpaces',
          type: 'table',
          direction: 'row', // direction, row is default
          source: 'selectedParkingSpaces', // source array
          sourceDataType: 'array',
          columnStyles: 'default', // default is two columns, first is 50 wide
          hasSumRow: true,
          template: [ // per-row template
            { key: 'selectedParkingSpaces', value: 'unitCode', header: i18n.t('pdf.unique_id')},
            { key: 'selectedParkingSpaces', value: 'usage', header: i18n.t('customPdf.usage') },
            { key: 'selectedParkingSpaces', value: 'agreedAmountText', header: i18n.t('pdf.amount') },
          ]
        },
        {
          title: 'pdf.additionalInfoParking',
          id: 'additionalInfoParking',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'additionalInfoParking', // source string
          sourceDataType: 'string',
          columnStyles: {}, // one page width column
          template: [ // per-row template
            { value: 'additionalInfoParking' },
          ]
        },
        {
          title: 'pdf.rent_time',
          id: 'rentPeriod',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'rentPeriod', // source object
          sourceDataType: 'object',
          columnStyles: 'default', // default is two columns, first is 50 wide
          template: getRentPeriodTemplate(rentPeriod)
        },
        {
          title: 'pdf.notice_period',
          id: 'noticePeriod',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'noticePeriod', // source object
          sourceDataType: 'object',
          columnStyles: 'default', // default is two columns, first is 50 wide
          headerInfoRow: 'pdf.notice_period_clause',
          template: getNoticePeriodTemplate(noticePeriod)
        },
        Object.entries(rent).length === 0 ? 
        {} 
        :
        {
          title: 'pdf.rent',
          id: 'rent',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'rent', // source object
          sourceDataType: 'object',
          columnStyles: 'default', // default is two columns, first is 50 wide
          headerInfoRow: 'pdf.tax_info',
          template: [ // per-row template
            { key: 'rent', value: 'rentPerMonth', header: i18n.t('pdf.rent_per_month') },
            { key: 'rent', value: 'separateRentPerMonth', header: i18n.t('pdf.separate_rent_per_month') },
            { key: 'rent', value: 'due', header: i18n.t('pdf.due') },
            { key: 'rent', value: 'rentPeriod', header: i18n.t('pdf.rent_period') },
            { key: 'rent', value: 'latePaymentInterest', header: i18n.t('pdf.late_payment_interest') },
          ]
        },
        {
          title: 'pdf.additionalInfoPayments',
          id: 'additionalInfoPayments',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'additionalInfoPayments', // source string
          sourceDataType: 'string',
          columnStyles: {}, // one page width column
          template: [ // per-row template
            { value: 'additionalInfoPayments' },
          ]
        },
        contractPayments?.length === 0 ? 
        {} 
        :
        {
          title: 'pdf.paymentValidity',
          id: 'contractPayments',
          type: 'table',
          direction: 'row', // direction, row is default
          source: 'contractPayments', // source array
          sourceDataType: 'array',
          columnStyles: 'default', // default is two columns, first is 50 wide
          template: [ // per-row template
            { key: 'contractPayments', value: 'paymentType', header: i18n.t('pdf.paymentType') },
            { key: 'contractPayments', value: 'startDate', header: i18n.t('pdf.starting') },
            { key: 'contractPayments', value: 'endDate', header: i18n.t('pdf.ending') },
            { key: 'contractPayments', value: 'reviewType', header: i18n.t('pdf.reviewType') },
            { key: 'contractPayments', value: 'netSum', header: i18n.t('pdf.netSum') },
            { key: 'contractPayments', value: 'target', header: i18n.t('pdf.allocation') },
          ]
        },
        separatePayments?.length === 0 ? 
        {} 
        :
        {
          title: 'pdf.separatePayments',
          id: 'separatePayments',
          type: 'table',
          direction: 'row', // direction, row is default
          source: 'separatePayments', // source array
          sourceDataType: 'array',
          columnStyles: 'default', // default is two columns, first is 50 wide
          template: [ // per-row template
            { key: 'separatePayments', value: 'legend', header: i18n.t('create_rent_contract.payment_legend') },
            { key: 'separatePayments', value: 'netSquareRent', header: i18n.t('create_rent_contract.payment_square_rent_tax_free') },
            { key: 'separatePayments', value: 'netSum', header: i18n.t('pdf.netSum_month') },
            { key: 'separatePayments', value: 'alv', header: `${i18n.t('VAT')} %` },
            { key: 'separatePayments', value: 'target', header: i18n.t('pdf.allocation') },
  
          ]
        },
        singlePayments?.length === 0 ? 
        {} 
        :
        {
          title: 'pdf.singlePayments',
          id: 'singlePayments',
          type: 'table',
          direction: 'row', // direction, row is default
          source: 'singlePayments', // source array
          sourceDataType: 'array',
          columnStyles: 'default', // default is two columns, first is 50 wide
          template: [ // per-row template
            { key: 'singlePayments', value: 'paymentType', header: i18n.t('pdf.paymentType') },
            { key: 'singlePayments', value: 'paymentDate', header: i18n.t('create_rent_contract.single_payment_date') },
            { key: 'singlePayments', value: 'netSum', header: i18n.t('pdf.netSum_month') },
            { key: 'singlePayments', value: 'paymentVat', header: `${i18n.t('VAT')} %` },
            { key: 'singlePayments', value: 'grossSum', header: i18n.t('pdf.grossSum_month') },
            { key: 'singlePayments', value: 'target', header: i18n.t('pdf.allocation') },
  
          ]
        },
        ...rentReviews?.map((r, index) => {
          if (r.reviewTypeDef === 'PaymentRentReviewIndex') {
            return {
              title: index === 0 ? 'pdf.rentReview' : '',
              id: `rentReview-${r.id}`,
              type: 'table',
              direction: 'column', // direction, row is default
              source: 'rentReviews', // source array
              sourceDataType: 'array',
              columnStyles: 'default', // default is two columns, first is 50 wide
              template: [ // per-row template
                { key: 'rentReviews', value: 'reviewType', header: i18n.t('pdf.reviewType') },
                { key: 'rentReviews', value: 'paymentType', header: i18n.t('pdf.paymentType') },  
                { key: 'rentReviews', value: 'netSum', header: i18n.t('pdf.netSum') },
                { key: 'rentReviews', value: 'indexHeading', header: i18n.t('pdf.indexHeading') },
                { key: 'rentReviews', value: 'baseindex', header: i18n.t('pdf.baseindex') },  
                { key: 'rentReviews', value: 'basicIndexPointNumber', header: i18n.t('pdf.basicIndexPointNumber') },
                { key: 'rentReviews', value: 'rentReviewStartDate', header: i18n.t('customPdf.rentReviewStartDate') },
                { key: 'rentReviews', value: 'reviewMonths', header: i18n.t('customPdf.reviewMonths') },  
                { key: 'rentReviews', value: 'indexMonths', header: i18n.t('customPdf.indexMonths') },
                { key: 'rentReviews', value: 'minimumIncrease', header: i18n.t('pdf.minimumIncrease') },
                { key: 'rentReviews', value: 'rentReviewBasedOn', header: i18n.t('pdf.rentReviewBasedOn') },  
                { key: 'rentReviews', value: 'reviewAdditionalInfo', header: i18n.t('pdf.reviewAdditionalInfo') },
                { key: 'rentReviews', value: 'dynamicReviewInfo', header: '' },
              ]
            }
          } else {
            return {
              title: index === 0 ? 'pdf.rentReview' : '',
              id: `rentReview-${r.id}`,
              type: 'table',
              direction: 'column', // direction, row is default
              source: 'rentReviews', // source array
              sourceDataType: 'array',
              columnStyles: 'default', // default is two columns, first is 50 wide
              template: [ // per-row template
                { key: 'rentReviews', value: 'reviewType', header: i18n.t('pdf.reviewType') },
                { key: 'rentReviews', value: 'paymentType', header: i18n.t('pdf.paymentTypes') },  
                { key: 'rentReviews', value: 'netSum', header: i18n.t('pdf.netSum') },
              ]
            }
          } 
        }),
        ...(guarantees?.length === 0 ? [
        {
          title:'pdf.guarantee',
          id: `guarantee-0`,
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'noGuarantees', // source string
          sourceDataType: 'string',
          columnStyles: {}, // one page width column
          template: [ // per-row template
            { value: 'noGuarantees' },
          ]
        } ]
        :
        guarantees.map((g, index) => {
          return {
            title: index === 0 ? 'pdf.guarantee': '',
            id: `guarantee-${g.id}`,
            type: 'table',
            direction: 'column', // direction, row is default
            source: 'guarantees', // source array
            sourceDataType: 'array',
            columnStyles: 'default', // default is two columns, first is 50 wide
            headerInfoRow: index === 0 ? 'pdf.guarantee_description' : undefined,
            template: [ // per-row template
              { key: 'guarantees', value: 'sum', header: i18n.t("pdf.guaranteeSum") },
              { key: 'guarantees', value: 'description', header: i18n.t("pdf.guaranteeDescription") },  
              { key: 'guarantees', value: 'type', header: i18n.t("pdf.guaranteeType") },
              { key: 'guarantees', value: 'agreedDateOfDelivery', header: i18n.t("pdf.guaranteeDateOfDelivery") },  
              { key: 'guarantees', value: 'dateOfExpiration', header: i18n.t("pdf.guaranteeDateOfExpiration") },
            ]
          }
        })),
        {
          title: 'pdf.housingModifications',
          id: 'housingModification',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'housingModification', // source object
          sourceDataType: 'object',
          columnStyles: 'default', // default is two columns, first is 50 wide
          template: getHousingModificationTemplate(housingModification)
        },
        {
          title: 'pdf.additional_conditions',
          id: 'contractConditions',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'contractConditions', // source string
          sourceDataType: 'string',
          columnStyles: {}, // one page width column
          template: [
            { value: 'contractConditions' },
          ]
        },
        {
          title: 'pdf.signatures',
          id: 'signatures',
          type: 'table',
          direction: 'column', // direction, row is default
          source: 'signees', // source object
          sourceDataType: 'object',
          columnStyles: 'default', // default is two columns, first is 50 wide
          headerInfoRow: 'pdf.signature_oath',
          template: [ // per-row template
            { key: 'signees', value: 'dateAndTime', header: i18n.t('pdf.dateTime') },
            { key: 'signees', value: 'landlordName', header: i18n.t('pdf.landlord') },
            { key: 'signees', value: 'dateAndTime', header: i18n.t('pdf.dateTime') },
            { key: 'signees', value: 'tenantName', header: i18n.t('pdf.tenant') },
          ]
        },
      ]
    }
  }
}

function getTenantTemplate (tenant, tenantSource = 'tenant') {

  if (tenant === undefined)
    return []

  if (tenant.tenantType === 1) {
    // Person tenant
    return [
      { key: tenantSource, value: 'name', header: i18n.t('pdf.tenant')},
      { key: tenantSource, value: 'identityNumber', header:  i18n.t('pdf.person_id') },
      { key: tenantSource, value: 'email', header:  i18n.t('pdf.email') },
      { key: tenantSource, value: 'phoneNumber', header:  i18n.t('pdf.phone') },
    ]
  } else {
    // Company tenant
    return [
      { key: tenantSource, value: 'name', header:  i18n.t('pdf.tenant') },
      { key: tenantSource, value: 'businessId', header:  i18n.t('pdf.y_code') },
      { key: tenantSource, value: 'address', header:  i18n.t('pdf.address') },
      { key: tenantSource, value: 'email', header:  i18n.t('pdf.email') },
      { key: tenantSource, value: 'phoneNumber', header:  i18n.t('pdf.phone') },
      { key: tenantSource, value: 'contactPersonName', header:  i18n.t('pdf.contact_person_name') },
      { key: tenantSource, value: 'contactPersonEmail', header:  i18n.t('pdf.contact_person_email') },
      { key: tenantSource, value: 'contactPersonPhoneNumber', header:  i18n.t('pdf.contact_person_phone')},
    ]
  }
}

function getRentPeriodTemplate (rentPeriod) {
  if (rentPeriod?.isFixedTerm) {
    // Fixed term contract
    if (rentPeriod?.noticePeriodTenant || rentPeriod?.noticePeriodLandlord) {
      return [
        { key: 'rentPeriod', value: 'validityType', header: i18n.t('pdf.contract_valid')},
        { key: 'rentPeriod', value: 'rentPeriodStartDate', header: i18n.t('pdf.start_date') },
        { key: 'rentPeriod', value: 'rentPeriodEndDate', header: i18n.t('pdf.end_date') },
        { key: 'rentPeriod', value: 'tenureStartDate', header: i18n.t('pdf.tenure_start_date') },
        { key: 'rentPeriod', value: 'billingStartDate', header: i18n.t('pdf.payments_start_date') },       
        { key: 'rentPeriod', value: 'noticePeriodTenant', header: i18n.t('pdf.tenantNoticePeriod') },
        { key: 'rentPeriod', value: 'noticePeriodLandlord', header: i18n.t('pdf.landlordNoticePeriod') },
        { key: 'rentPeriod', value: 'validityAdditionalInfo', header: i18n.t('pdf.contract_validity_additional_info')},
      ] 
    } else if (rentPeriod?.extensionNoticePeriod) {
      return [
        { key: 'rentPeriod', value: 'validityType', header: i18n.t('pdf.contract_valid')},
        { key: 'rentPeriod', value: 'rentPeriodStartDate', header: i18n.t('pdf.start_date') },
        { key: 'rentPeriod', value: 'rentPeriodEndDate', header: i18n.t('pdf.end_date') },
        { key: 'rentPeriod', value: 'tenureStartDate', header: i18n.t('pdf.tenure_start_date') },
        { key: 'rentPeriod', value: 'billingStartDate', header: i18n.t('pdf.payments_start_date') },
        { key: 'rentPeriod', value: 'extensionNoticePeriod', header: i18n.t('pdf.extensionNoticePeriod') },
        { key: 'rentPeriod', value: 'validityAdditionalInfo', header: i18n.t('pdf.contract_validity_additional_info')},
      ]
    } else {
      return [
        { key: 'rentPeriod', value: 'validityType', header: i18n.t('pdf.contract_valid')},
        { key: 'rentPeriod', value: 'rentPeriodStartDate', header: i18n.t('pdf.start_date') },
        { key: 'rentPeriod', value: 'rentPeriodEndDate', header: i18n.t('pdf.end_date') },
        { key: 'rentPeriod', value: 'tenureStartDate', header: i18n.t('pdf.tenure_start_date') },
        { key: 'rentPeriod', value: 'billingStartDate', header: i18n.t('pdf.payments_start_date') },
        { key: 'rentPeriod', value: 'validityAdditionalInfo', header: i18n.t('pdf.contract_validity_additional_info')},
      ]
    }
  } else {
    // Permanent contract
    return [
      { key: 'rentPeriod', value: 'validityType', header: i18n.t('pdf.contract_valid')},
      { key: 'rentPeriod', value: 'rentPeriodStartDate', header: i18n.t('pdf.start_date') },
      { key: 'rentPeriod', value: 'rentPeriodEndDate', header: i18n.t('pdf.end_date') },
      { key: 'rentPeriod', value: 'tenureStartDate', header: i18n.t('pdf.tenure_start_date') },
      { key: 'rentPeriod', value: 'billingStartDate', header: i18n.t('pdf.payments_start_date') },
      { key: 'rentPeriod', value: 'tenantExtensionPeriod', header: i18n.t('customPdf.tenantExtensionPeriod') },
      { key: 'rentPeriod', value: 'validityAdditionalInfo', header: i18n.t('pdf.contract_validity_additional_info')},
    ]
  }
}

function getNoticePeriodTemplate (noticePeriod) { 
  if (noticePeriod?.isFixedTerm) {
    // Fixed term contract
    return []
  } else {
    // Permanent contract
    return [
      { key: 'noticePeriod', value: 'noticePeriodLandlord', header: i18n.t('pdf.landlordNoticePeriod') },
      { key: 'noticePeriod', value: 'noticePeriodTenant', header: i18n.t('pdf.tenantNoticePeriod') },
      { key: 'noticePeriod', value: 'earliestNoticeDateLandlord', header: i18n.t('pdf.landlordEarliestNoticeDate') },
      { key: 'noticePeriod', value: 'earliestNoticeDateTenant', header: i18n.t('pdf.tenantEarliestNoticeDate') },
    ]
  }
}

function getHousingModificationTemplate (housingModification) {
  return housingModification?.hasHousingModification ?
    [
      { key: 'housingModification', value: 'housingModification', header: i18n.t('pdf.housingModifications') },
      { key: 'housingModification', value: 'housingModificationAgreedUpon', header: i18n.t('pdf.housingModificationsAgreedUpon') },
    ]
  :
    [
      { key: 'housingModification', value: 'housingModification', header: i18n.t('pdf.housingModifications') },
    ] 
}