
<template>
  <div>
    <v-dialog
      v-if="showConfirmationModal"
      v-model="showConfirmationModal"
      persistent
      eager
      width="60%"
    >
      <v-card>
        <ContractConfirmTerminationModal
          :contract-id="contractId"
          :termination-id="terminationId"
          @close="closeConfirmationModal"
          @cancelTerminateContract="cancelTerminateContract"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showTerminationModal"
      v-model="showTerminationModal"
      persistent
      eager
      width="80%"
    >
      <v-card>
        <TerminateContractModal
          :contract="contract"
          :contract-id="contractId"
          :contract-start-date="new Date(rentPeriodStartDate)"
          :contract-end-date="new Date(rentContractEndDate)"
          @close="(showTerminationModal = false)"
          @getUpdatedData="getUpdatedData"
          @terminationPdf=" (e) => $emit('terminationPdf', e)"
        />
      </v-card>
    </v-dialog>
    <v-tab-item
      :key="1"
    >
      <v-form
        ref="form1"
        class="pt-2"
      >
        <ValidationObserver ref="validationObserver">
          <h3 class="form-headers">
            {{ $t('create_rent_contract.rent_period_start') }}
          </h3>
          <v-row
            align="start"
          >
            <v-col>
              <v-menu
                :close-on-content-click="false"
                :readonly="contract && contract.isDraft === false"
                offset-y
                min-width="0"
              >
                <template #activator="{ on }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      v-model="rentPeriodStartDateFormatted"
                      :label="$t('create_rent_contract.rent_period_start')"
                      :aria-label="$t('create_rent_contract.rent_period_start') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                      :hint=" $t('Required') + ' — ' + $t('date_format')"
                      persistent-hint
                      :readonly="contract && contract.isDraft === false"
                      :error-messages="errors[0]"
                      class="form-fields mt-4 ml-2"
                      prepend-icon="calendar_today"
                      required
                      v-on="on"
                      @change="onRentPeriodStartDateChanged"
                      @blur="rentPeriodStartDate = parseDate(rentPeriodStartDateFormatted)"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="rentPeriodStartDate"
                  :readonly="contract && contract.isDraft === false"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  :locale="$i18n.locale"
                  @change="onDataChanged()"
                />
              </v-menu>
              <v-radio-group
                v-model="validityType"
                :label="$t('create_rent_contract.validity_type')"
                row
                class="pl-2 font-weight-bold mt-8 ml-2"
                @change="onDataChanged()"
              >
                <v-radio
                  :label="$t('create_rent_contract.validity_fixed')"
                  default
                  value="validity_fixed"
                  class="font-weight-regular px-2"
                />
                <v-radio
                  :label="$t('create_rent_contract.validity_permanent')"
                  value="validity_permanent"
                  class="font-weight-regular px-2"
                />
              </v-radio-group>
              <!-- Validity fixed (Määräaikainen) -->
              <div v-if="validityType === 'validity_fixed'">
                <h3 class="form-headers">
                  {{ $t('create_rent_contract.please_choose_validity_period') }}
                </h3>

                <v-row
                  align="start"
                >
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="rentTimeYears"
                      :items="getYearValues(15)"
                      :label="$t('create_rent_contract.rent_time_years')"
                      item-text="text"
                      item-value="id"
                      class="form-fields mt-4 ml-2"
                      @change="onRentYearsChanged()"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      :close-on-content-click="false"
                      class="mt-4 ml-2"
                      offset-y
                      min-width="0"
                    >
                      <template #activator="{ on }">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            v-model="rentContractEndDateFormatted"
                            :label="$t('create_rent_contract.rent_contract_end_day')"
                            :aria-label="$t('create_rent_contract.rent_contract_end_day') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                            :readonly="contract && contract.isDraft === false && validityType === 'validity_permanent'"
                            :error-messages="errors[0]"
                            class="form-fields mt-4 ml-2"
                            prepend-icon="calendar_today"
                            required
                            :hint="$t('Required') + ' — ' + $t('date_format')"
                            persistent-hint
                            v-on="on"
                            @change="onRentContractEndDateChanged"
                            @blur="rentContractEndDate = parseDate(rentContractEndDateFormatted)"
                          />
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="rentContractEndDate"
                        :readonly="contract && contract.isDraft === false && validityType === 'validity_permanent'"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        :locale="$i18n.locale"
                        @input="onDataChanged()"
                      />
                    </v-menu>
                  </v-col>
                  <v-col 
                    v-if="isTemplateFieldVisible('validityfixedtenantnoticeperiod', contractTemplate)"                  
                    cols="6"
                  >
                    <v-autocomplete
                      v-model="noticePeriodTenant"
                      :items="getMonthValues(15)"
                      :label="$t('create_rent_contract.notice_period_tenant')"
                      :readonly="!!extensionNoticePeriod"
                      item-text="text"
                      item-value="id"
                      class="form-fields mt-8 ml-2"
                      clearable
                      @change="onDataChanged()"
                    />
                  </v-col>
                  <v-col 
                    v-if="isTemplateFieldVisible('validityfixedlandlordnoticeperiod', contractTemplate)"
                    cols="6"
                  >
                    <v-autocomplete
                      v-model="noticePeriodLandlord"
                      :items="getMonthValues(15)"
                      :label="$t('create_rent_contract.notice_period_landlord')"
                      :readonly="!!extensionNoticePeriod"
                      item-text="text"
                      item-value="id"
                      class="form-fields mt-8 ml-2"
                      clearable
                      @change="onDataChanged()"
                    />
                  </v-col>
                  <v-col 
                    v-if="isTemplateFieldVisible('extensionnoticeperiod', contractTemplate)"
                    cols="6"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`isInteger|isIntegerTooLarge: ${extensionNoticePeriod}`"
                    >
                      <v-text-field
                        v-model="extensionNoticePeriod"
                        :label="$t('create_rent_contract.extension_notice_period')"
                        :readonly="!!noticePeriodLandlord || !!noticePeriodTenant"
                        :error-messages="errors[0]"
                        type="number"
                        class="form-fields mt-4 ml-2"
                        clearable
                        @change="onDataChanged()"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </div>
              <!-- Validity permanent (Toistaiseksi voimassa oleva) -->
              <div v-else-if="validityType === 'validity_permanent'">
                <v-row
                  align="center"
                >
                  <v-col cols="6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`${isOutRent ? 'required' : ''}`"
                    >
                      <v-autocomplete
                        v-model="noticePeriodTenant"
                        :items="getMonthValues(15)"
                        :error-messages="errors[0]"
                        :label="$t('create_rent_contract.notice_period_tenant')"
                        item-text="text"
                        item-value="id"
                        required
                        class="form-fields mt-4 ml-2"
                        @change="onDataChanged()"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`${isOutRent ? 'required' : ''}`"
                    >
                      <v-autocomplete
                        v-model="noticePeriodLandlord"
                        :items="getMonthValues(15)"
                        :error-messages="errors[0]"
                        :label="$t('create_rent_contract.notice_period_landlord')"
                        item-text="text"
                        item-value="id"
                        required
                        class="form-fields mt-4 ml-2"
                        @change="onDataChanged()"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                >
                  <v-col cols="6">
                    <v-menu
                      :close-on-content-click="false"
                      class="mt-4 ml-2"
                      offset-y
                      min-width="0"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="earliestNoticeDateTenantFormatted"
                          :label="$t('create_rent_contract.earliest_notice_date_tenant')"
                          :aria-label="$t('create_rent_contract.earliest_notice_date_tenant') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                          :hint="$t('date_format')"
                          class="form-fields mt-4 ml-2"
                          prepend-icon="calendar_today"
                          v-on="on"
                          @change="onEarliestNoticeDateTenantChanged"
                          @blur="earliestNoticeDateTenant = parseDate(earliestNoticeDateTenantFormatted)"
                        />
                      </template>
                      <v-date-picker
                        v-model="earliestNoticeDateTenant"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        :locale="$i18n.locale"
                        @change="onDataChanged()"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      :close-on-content-click="false"
                      class="mt-4 ml-2"
                      offset-y
                      min-width="0"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="earliestNoticeDateLandlordFormatted"
                          :label="$t('create_rent_contract.earliest_notice_date_landlord')"
                          :aria-label="$t('create_rent_contract.earliest_notice_date_landlord') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                          :hint="$t('date_format')"
                          class="form-fields mt-4 ml-2"
                          prepend-icon="calendar_today"
                          v-on="on"
                          @change="onEarliestNoticeDateLandlordChanged"
                          @blur="earliestNoticeDateLandlord = parseDate(earliestNoticeDateLandlordFormatted)"
                        />
                      </template>
                      <v-date-picker
                        v-model="earliestNoticeDateLandlord"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        :locale="$i18n.locale"
                        @change="onDataChanged()"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                >
                  <v-col cols="6">
                    <v-autocomplete
                      v-if="settings.hasContractPeriodOfExtension"
                      v-model="tenantExtensionPeriod"
                      :items="getMonthValues(120)"
                      :label="$t('create_rent_contract.period_of_extension')"
                      item-text="text"
                      item-value="id"
                      clearable
                      class="form-fields mt-4 ml-2"
                      @change="onDataChanged()"
                    />
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                >
                  <v-col cols="6">
                    <v-menu
                      v-if="contract !== undefined"
                      :close-on-content-click="false"
                      class="mt-6 ml-2"
                      offset-y
                      min-width="0"
                    >
                      <template #activator="{ on }">
                        <ValidationProvider
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            :value="formatDate(permanentRentContractEndDate)"
                            :label="$t('create_rent_contract.rent_contract_end_day')"
                            :readonly="contract && contract.isDraft === false"
                            :error-messages="errors[0]"
                            class="form-fields mt-4 ml-2"
                            prepend-icon="calendar_today"
                            required
                            v-on="on"
                            @change="onPermanentRentContractEndDateChanged"
                          />
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="permanentRentContractEndDate"
                        :readonly="contract && contract.isDraft === false"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        :locale="$i18n.locale"
                        @input="onDataChanged()"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <v-textarea
                v-model="validityAdditionalInfo"
                :label="$t('create_rent_contract.validity_additional_info')"
                rows="3"
                flat
                hide-details
                auto-grow
                class="form-fields mt-6 mb-2 ml-2"
                @input="onDataChanged()"
              />
              <v-row>
                <v-col cols="6">
                  <v-menu
                    :close-on-content-click="false"
                    :readonly="contract && contract.isDraft === false"
                    class="mt-4 ml-2"
                    offset-y
                    min-width="0"
                  >
                    <template #activator="{ on }">
                      <ValidationProvider
                        v-slot="{ errors }"
                        :rules="`required|validBillingStartDate: ${billingStartDate}, ${rentPeriodStartDate}`"
                      >
                        <v-text-field
                          v-model="billingStartDateFormatted"
                          :label="$t('create_rent_contract.billing_start_date')"
                          :aria-label="$t('create_rent_contract.billing_start_date') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                          :hint=" $t('Required') + ' — ' + $t('date_format')"
                          persistent-hint
                          :error-messages="errors[0]"
                          :readonly="contract && contract.isDraft === false"
                          class="form-fields mt-4 ml-2"
                          prepend-icon="calendar_today"
                          required
                          v-on="on"
                          @change="onBillingStartDateChanged"
                          @blur="billingStartDate = parseDate(billingStartDateFormatted)"
                        />
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="billingStartDate"
                      :readonly="contract && contract.isDraft === false"
                      no-title
                      scrollable
                      first-day-of-week="1"
                      :locale="$i18n.locale"
                      @change="onDataChanged()"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    :close-on-content-click="false"
                    :readonly="contract && contract.isDraft === false"
                    class="mt-4 ml-2"
                    offset-y
                    min-width="0"
                  >
                    <template #activator="{ on }">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          v-model="tenureStartDateFormatted"
                          :label="$t('create_rent_contract.tenure_start_date')"
                          :aria-label="$t('create_rent_contract.tenure_start_date') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                          :hint=" $t('Required') + ' — ' + $t('date_format')"
                          persistent-hint
                          :error-messages="errors[0]"
                          :readonly="contract && contract.isDraft === false"
                          class="form-fields mt-4 ml-2"
                          prepend-icon="calendar_today"
                          required
                          v-on="on"
                          @change="onTenureStartDateChanged"
                          @blur="tenureStartDate = parseDate(tenureStartDateFormatted)"
                        />
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="tenureStartDate"
                      :readonly="contract && contract.isDraft === false"
                      no-title
                      scrollable
                      first-day-of-week="1"
                      :locale="$i18n.locale"
                      @change="onDataChanged()"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-textarea
                v-if="validityType === 'validity_fixed'"
                v-model="additionalExtensionTerms"
                :label="$t('create_rent_contract.additional_extension_terms')"
                rows="3"
                flat
                hide-details
                auto-grow
                class="form-fields mt-8 ml-2"
                @input="onDataChanged()"
              />
            </v-col>
            <v-col
              v-if="(canBeTerminated || canCancelTermination) && isUpdatingAllowed"
              lg="3"
              xl="2"
              class="pl-4"
            >
              <v-btn
                v-if="canBeTerminated"
                rounded
                depressed
                color="error"
                class="mt-4"
                @click="openTerminationModal"
              >
                {{ $t('contractTermination.terminateContract') }}
              </v-btn>
              <div v-if="canCancelTermination">
                <v-btn
                  rounded
                  depressed
                  color="error"
                  class="mt-4"
                  @click="openConfirmationModal"
                >
                  {{ $t('contractTermination.cancelTermination') }}
                </v-btn>
                <div class="mt-2 mb-4 ml-4">
                  <div>
                    <strong>{{ $t('contractTermination.Contract') }}:</strong>
                    {{ $t(termination.defTerminationType) }}
                  </div>
                  <div>
                    <strong>{{ $t('contractTermination.Executor') }}:</strong> 
                    {{ termination.terminatingParty }}
                  </div>
                  <div>
                    <strong>{{ $t('contractTermination.Reasoning') }}:</strong>
                    {{ termination.description }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-form>
    </v-tab-item>
  </div>
</template>

<script>

import { mapGetters, } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import moment from 'moment'
import { i18n } from '../../../../plugins/i18n'
import TerminateContractModal from './ContractTerminationModal.vue'
import ContractConfirmTerminationModal from './ContractConfirmTerminationModal'

const getValidDate = (val) => {
  const date = moment(val, "DD.MM.YYYY");
  return date.isValid() ? date.format("YYYY-MM-DD") : undefined
}

extend('validBillingStartDate', {
  message: i18n.t("create_rent_contract.invalid_start_date"),
  params: ['billingStartDate', 'rentPeriodStartDate'],
  validate: (_, { billingStartDate, rentPeriodStartDate }) => {

    const date = Date.parse(billingStartDate?.trim())
    if (isNaN(date)) {
      return true
    }

    const startDate = Date.parse(rentPeriodStartDate.trim())
    return date >= startDate
  }
});

extend('isInteger', {
  message: i18n.t("create_rent_contract.is_integer"),
  params: ['extensionNoticePeriod'],
  validate: extensionNoticePeriod => {
    return Number.isInteger(Number(extensionNoticePeriod))
  }
})

extend('isIntegerTooLarge', {
  message: i18n.t("create_rent_contract.is_integer_too_large"),
  params: ['extensionNoticePeriod'],
  validate: extensionNoticePeriod => {
    return Number(extensionNoticePeriod) <= 1000
  }
})

export default {
  name: "RentContractRentPeriodTab",
  components: {
    ValidationProvider,
    ValidationObserver,
    TerminateContractModal,
    ContractConfirmTerminationModal,
  },
  props: {
    isActive: {
      type: Boolean,
      default: () => false
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    contractId: {
      type: Number,
      default: undefined
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    isUpdatingAllowed: {
      type: Boolean,
      default: false
    },
    contractTemplate: {
      type: Object,
      default: undefined
    },
  },
  data () {
    return (
      {
        rentContractEndDate: undefined,
        permanentRentContractEndDate: undefined,
        rentPeriodStartDate: undefined,
        validityType: 'validity_fixed',
        rentTimeYears: 0,
        noticePeriodTenant: null,
        noticePeriodLandlord: null,
        earliestNoticeDateTenant: null,
        earliestNoticeDateLandlord: null,
        isPageValid: false,
        validityAdditionalInfo: '',
        billingStartDate: undefined,
        tenureStartDate: undefined,
        additionalExtensionTerms: undefined,
        showTerminationModal: false,
        showConfirmationModal: false,
        termination: undefined,
        landlordId: undefined,
        rentPeriodStartDateFormatted: undefined,
        rentContractEndDateFormatted: undefined,
        earliestNoticeDateTenantFormatted: undefined,
        earliestNoticeDateLandlordFormatted: undefined,
        billingStartDateFormatted: undefined,
        tenureStartDateFormatted: undefined,
        historyEvents : {
          terminationCanceled: 8,
        },
        tenantExtensionPeriod: null,
        landlordExtensionPeriod: null,
        extensionNoticePeriod: null,
      }
    )
  },
  computed: {
    ...mapGetters('app', [ 'definitionById', 'settings' ]),
    ...mapGetters('rentContracts', ['isTemplateFieldVisible']),
    canBeTerminated () {
      return (
        !this.termination
        && this.contract
        && this.contract.isDraft === false
        && (!this.contract.rentContractEndDate || new Date(this.contract.rentContractEndDate) > new Date())
      )
    },
    canCancelTermination () {
      return (
        this.termination
        && this.contract
        && this.contract.isDraft === false
      )
    },
    terminationId () {
      return this.termination?.id
    }
  },
  watch: {
    validityType: {
      handler (newValidity) {
        if (newValidity === 'validity_permanent' && this.validityAdditionalInfo === '') {
          this.validityAdditionalInfo = this.$t('create_rent_contract.validity_additional_info_default')
        } else if (newValidity === 'validity_fixed' && this.validityAdditionalInfo === this.$t('create_rent_contract.validity_additional_info_default')) {
          this.validityAdditionalInfo = ''
        }
      },
      immediate: true
    },
    rentPeriodStartDate () {
      this.rentPeriodStartDateFormatted = this.formatDate(this.rentPeriodStartDate)
      let toOnDataChanged = false
      if (!this.billingStartDate) {
        this.billingStartDate = this.rentPeriodStartDate
        toOnDataChanged = true
      }
      if (!this.tenureStartDate) {
        this.tenureStartDate = this.rentPeriodStartDate
        toOnDataChanged = true
      }
      if (this.onRentPeriodStartDateChanged && !this.billingStartDate && !this.tenureStartDate) {
        this.billingStartDate = this.rentPeriodStartDate
        this.tenureStartDate = this.rentPeriodStartDate
        toOnDataChanged = true
      }
      if (this.onRentPeriodStartDateChanged && this.rentPeriodStartDate && this.rentTimeYears) {
        const now = new Date(this.rentPeriodStartDate)
        now.setDate(now.getDate() - 1)
        this.rentContractEndDate = new Date(now.setFullYear(now.getFullYear() + this.rentTimeYears))
        .toISOString().split('T')[0]
        toOnDataChanged = true
      }
      if (toOnDataChanged)
        this.onDataChanged()
    },
    async isActive () {
      const observer = this.$refs.validationObserver;
      this.isPageValid = await observer?.validate() ?? false
      this.$emit("isPageValidChanged", this.isPageValid)
    },
    contract () {
      if (this.contract) {
        this.updateDataFromExistingContract()
      }
      this.getTermination()
    },
    rentContractEndDate () {
      this.rentContractEndDateFormatted = this.formatDate(this.rentContractEndDate)
      if (this.rentContractEndDate < this.rentPeriodStartDate) {
        this.rentContractEndDate = this.rentPeriodStartDate
        this.onRentContractEndDateChanged(this.rentContractEndDate)
      }
    },
    earliestNoticeDateTenant () {
      this.earliestNoticeDateTenantFormatted = this.formatDate(this.earliestNoticeDateTenant)
    },
    earliestNoticeDateLandlord () {
      this.earliestNoticeDateLandlordFormatted = this.formatDate(this.earliestNoticeDateLandlord)
    },
    billingStartDate () {
      this.billingStartDateFormatted = this.formatDate(this.billingStartDate)
    },
    tenureStartDate () {
      this.tenureStartDateFormatted = this.formatDate(this.tenureStartDate)
    },
  },
  methods: {
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    updateDataFromExistingContract () {
      this.landlordId = this.contract.landlordId
      this.rentPeriodStartDate = this.contract.rentPeriodStartDate
      this.validityType = this.contract.validityType
      this.rentContractEndDate = this.contract.rentContractEndDate
      this.permanentRentContractEndDate = this.contract.validityType === 'validity_permanent' ? this.contract.rentContractEndDate : undefined
      this.earliestNoticeDateTenant = this.contract.earliestNoticeDateTenant
      this.earliestNoticeDateLandlord = this.contract.earliestNoticeDateLandlord
      this.noticePeriodLandlord = this.contract.noticePeriodLandlord
      this.noticePeriodTenant = this.contract.noticePeriodTenant
      this.validityAdditionalInfo = this.contract.validityAdditionalInfo
      this.billingStartDate = this.contract.billingStartDate
      this.tenureStartDate = this.contract.tenureStartDate
      this.additionalExtensionTerms = this.contract.additionalExtensionTerms
      this.tenantExtensionPeriod = this.contract.tenantExtensionPeriod
      this.landlordExtensionPeriod = this.contract.landlordExtensionPeriod
      this.extensionNoticePeriod = this.contract.extensionNoticePeriod
    },
    onRentYearsChanged () {
      if (this.rentTimeYears > 0) {
        const now = !this.rentPeriodStartDate ? new Date() : new Date(this.rentPeriodStartDate)
        now.setDate(now.getDate() - 1)
        this.rentContractEndDate = new Date(now.setFullYear(now.getFullYear() + this.rentTimeYears))
        .toISOString().split('T')[0]
      }
      this.onDataChanged()
    },
    onRentPeriodStartDateChanged (val) {
      this.rentPeriodStartDate = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    onBillingStartDateChanged (val) {
      this.billingStartDate = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    onTenureStartDateChanged (val) {
      this.tenureStartDate = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    onEarliestNoticeDateTenantChanged (val) {
      this.earliestNoticeDateTenant = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    onEarliestNoticeDateLandlordChanged (val) {
      this.earliestNoticeDateLandlord = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    onRentContractEndDateChanged (val) {
      this.rentContractEndDate = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    onPermanentRentContractEndDateChanged (val) {
      this.permanentRentContractEndDate = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    async onDataChanged () {

      if (this.validityType === 'validity_permanent') {
        this.rentContractEndDate = this.permanentRentContractEndDate
        this.rentTimeYears = undefined
        this.additionalExtensionTerms = undefined
      } else {
        this.permanentRentContractEndDate = undefined
      }

      const data = {
        rentPeriodStartDate: this.rentPeriodStartDate,
        validityType: this.validityType,
        rentTimeYears: this.rentTimeYears,
        rentContractEndDate: this.rentContractEndDate,
        noticePeriodTenant: this.noticePeriodTenant,
        noticePeriodLandlord: this.noticePeriodLandlord,
        earliestNoticeDateTenant: this.earliestNoticeDateTenant,
        earliestNoticeDateLandlord: this.earliestNoticeDateLandlord,
        validityAdditionalInfo: this.validityAdditionalInfo,
        billingStartDate: this.billingStartDate,
        tenureStartDate: this.tenureStartDate,
        additionalExtensionTerms: this.additionalExtensionTerms,
        tenantExtensionPeriod: this.tenantExtensionPeriod,
        landlordExtensionPeriod: this.landlordExtensionPeriod,
        extensionNoticePeriod: this.extensionNoticePeriod,
        }
      this.$emit('updated', data)
    },
    getMonthValues (count) {

      return [...Array(count).keys()].map(value => {
        value++
        const text = `${value} ${this.$t('Mth')}`
        return { text, id: value }
      })
    },
    getYearValues (count) {
      return [...Array(count).keys()].map(value => {

        const text = value > 0 ? `${value} ${this.$t('create_rent_contract.year_long')}` : ""
        return { text, id: value }
      })
    },
    openTerminationModal () {
      this.showTerminationModal = true
    },
    async getUpdatedData () {
      await this.getTermination()
      this.$emit('getUpdatedData')
    },
    getTerminatingPartyNameById (id) {
      return this.landlordId === id ? this.$t('Landlord') : this.$t('Tenant')
    },
    async openConfirmationModal () {
      this.showConfirmationModal = true
    },
    async closeConfirmationModal () {
      await this.getTermination()
      this.showConfirmationModal = false
    },
    async getTermination () {
      const termination = await this.$rambollfmapi.contracts.contractTermination.getTermination(this.contractId)

      if (termination?.id) {
        this.termination = {
          id: termination.id,
          defTerminationType: this.definitionById(termination.defTerminationType).label,
          terminatingParty: this.getTerminatingPartyNameById(termination.terminatingParty),
          description: termination.description,
          endDate: termination.endDate,
          idContract: termination.idContract,
        }
      } else {
        this.termination = undefined
      }
    },
    async cancelTerminateContract () {
      await this.$rambollfmapi.contracts.contractTermination.cancelTermination(this.contractId)
      await this.$rambollfmapi.contracts.contract.AddContractEvent(this.historyEvents.terminationCanceled, [this.contractId], null)
      await this.getUpdatedData()
      this.closeConfirmationModal()
    }
  }
}
</script>

<style scoped>
.form-headers {
  margin: 0px 10px 10px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
</style>