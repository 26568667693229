<template>
  <v-tab-item
    :key="0"
  >
    <v-form
      ref="form1"
    >
      <div
        class="firstQuestionBox"
      >
        <MetadataForm
          :metadata="buildingRatingFields"
          :data="buildingRating"
          :neighborhoods="neighborhoods"
          class="bordered mt-4"
        />
      </div>
    </v-form>
  </v-tab-item>
</template>

<script>
import MetadataForm from '../../components/MetadataForm.vue'

export default {
  name: "BasicInfoTab",
  components: {
    MetadataForm,
  },
  props: {
    buildingRating: {
      type: Object,
      default: () => undefined
    },
    savingDisabled: {
      type: Boolean,
      default: false
    },
  },
  emits: ['updateRating'],
  data () {
    return {
      selectedNeighborhood: null,
      neighborhoods: [],
      metadata: null,
      buildingRatingFields: {
        buildingproject_number: { isShown: true, category: 'None' },
        site_name: { isShown: true, category: 'None' },
        landlord: { isShown: true, category: 'None', label: 'site_owner' },
        address: { isShown: true, category: 'None' },
        ownership_relation: { isShown: true, category: 'None', label: 'Ownership' },
        neighborhood: { isShown: true, category: 'None', editType: 'DropDown' },
        unit_area: { isShown: true, category: 'None', format: 'Area', label: 'Unit area from koki' },
        allocated_unit_area: { isShown: true, category: 'None', format: 'Area', label: 'Check measured area' },
        official_gross_area: { isShown: true, category: 'None', format: 'Area' },
        state_area_koki: { isShown: true, category: 'None', format: 'Area' },
        state_area: { isShown: true, category: 'None', format: 'Area' },
        average_rent: { isShown: true, category: 'None', format: 'Euro', editType: 'Number' },
        completed_year: { isShown: true, category: 'None', format: 'Year' },
        usage: { isShown: true, category: 'None' },
        rating_date: { isShown: true, category: 'None', format: 'Date' },
        approval_date: { isShown: true, category: 'None', format: 'Date' },
        publication_date: { isShown: true, category: 'None', format: 'Date' },
        is_usage: { isShown: true, category: 'None', editType: 'Checkbox', isRequired: true, format: 'YesNoUnknown' },
        ventilation_notice_done: { isShown: true, category: 'None', editType: 'Checkbox', format: 'YesNoUnknown' },
        protected_zoning_plan: { isShown: true, category: 'None', editType: 'Checkbox', format: 'YesNoUnknown' },
        unobstructed: { isShown: true, category: 'None', editType: 'Checkbox', format: 'YesNoUnknown' },
      },
    }
  },
  watch: {
    savingDisabled: {
      handler: function (savingDisabled) {
        // Remove editType if saving is disabled
        Object.keys(this.buildingRatingFields).forEach(key => {
          if (savingDisabled && this.buildingRatingFields[key].editType) {
            this.buildingRatingFields[key].editType = 'None';
          }
        });
      },
      deep: true,
      immediate: true
    }
  },
  async mounted () {
    this.metadata = await this.$rambollfmapi.buildingProjects.reports().metadata()
    this.neighborhoods = await this.$rambollfmapi.buildingRating.getNeighborhoods()
  },
}
</script>

<style scoped>
.bordered {
  border: 1px solid var(--r-color-mountain-10);
  border-radius: var(--c-radius-m);
}
</style>