<template>
  <BaseModal
    @cancel="close"
    :isLoading="loading"
  >
    <template #title>
      <h2 class="text-h5">
        {{ $t("buildingRating") + ' ' + (currentPage + 1) + '/' + pages.length }}
      </h2>
    </template>
    <template #content>
      <CdsStepper 
        :current-step="currentPage"
        :steps="pages"
        @changeStep="currentPage = $event - 1"
      />
      <v-tabs-items
        id="tab-content"
        v-model="currentPage"
      >
        <CdsAlert
          v-if="isApproved || isVersionEnd"
          class="alert"
          :title="`${$t('approvedTitle')}`"
          :description="`${$t('buildingRating.approved')} ${dateAsString(rating.approval_date)} ${rating.end_date ? ` · ${$t('buildingRating.versionEnd')} ${dateAsString(rating.end_date)}` : ''}`"
        />
        <!-- First Tab -->
        <BasicInfoTab
          :building-rating="rating"
          :saving-disabled="savingDisabled"
        />
        <!-- Second Tab -->
        <QuestionsTab
          :saving-disabled="savingDisabled"
          :building-rating="rating"
          :questions="allQuestions"
          @updated="onQuestionsTabUpdated"
        />
        <!-- Third Tab -->
        <SummaryTab
          :building-rating-classes="buildingRatingClasses"
          :building-rating="rating"
          :saving-disabled="savingDisabled"
        />
      </v-tabs-items>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-row
        justify="space-between"
        no-gutters
      >
        <v-col
          cols="4"
          class="text-left"
        >
          <v-btn
            v-if="currentPage !== 0"
            text
            rounded
            outlined
            depressed
            @click="currentPage--"
          >
            <v-icon left>
              arrow_back_ios
            </v-icon>
            {{ $t('Previous') }}
            <span class="d-sr-only">{{ ` ${$t('Page')}` }}</span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="tertiary"
            rounded
            depressed
            @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            class="accent"
            :disabled="savingDisabled"
            rounded
            depressed
            @click="buildingRatingSave"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-col>
        <v-col
          cols="4"
          class="text-right"
        >
          <v-btn
            v-if="(currentPage < pages.length - 1)"
            text
            rounded
            outlined
            depressed
            @click="currentPage++"
          >
            {{ $t('Next') }}
            <span class="d-sr-only">{{ ` ${$t('Page')}` }}</span>
            <v-icon right>
              arrow_forward_ios
            </v-icon>
          </v-btn>
          <CdsButton
            v-else-if="!isApproved"
            :disabled="approvalDisabled"
            icon-left="check"
            type="success"
            @click="approveBuildingRating"
          >
            {{ $t('buildingRating.approve') }}
          </CdsButton>
          <CdsButton
            v-else
            :disabled="approvalDisabled"
            icon-left="lock_open"
            type="tertiary"
            @click="openBuildingRating"
          >
            {{ $t('buildingRating.open') }}
          </CdsButton>
        </v-col>
      </v-row>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '../../components/general/BaseModal.vue'
import CdsAlert from '../../components/CircleDesignSystem/CdsAlert.vue'
import CdsStepper from '../../components/CircleDesignSystem/CdsStepper.vue'
import CdsButton from '../../components/CircleDesignSystem/CdsButton.vue'
import BasicInfoTab from './BasicInfoTab.vue'
import QuestionsTab from './QuestionsTab.vue'
import SummaryTab from './SummaryTab.vue'
import helpers from '../../helpers'
import { mapState, mapGetters } from 'vuex'

export default {
  name: "CreateBuildingRatingModal",
    components: {
      BaseModal,
      CdsAlert,
      CdsStepper,
      CdsButton,
      BasicInfoTab,
      QuestionsTab,
      SummaryTab,
    },
    props: {
      buildingRatingId: {
        type: Number,
        default: () => null
      },
      buildingRatingClasses: {
        type: Array,
        default: () => []
      },
      initialRating: {
        type: Object,
        default: () => {}
      },
    },
  emits: ['close', 'updateRating', 'updateWidget'],
  data () {
    return {
      currentPage: 0,
      originalRating: {},
      pages: [
        { id: 0, title: 'buildingRating.basicInfo' },
        { id: 1, title: 'buildingRating.questions', description: undefined },
        { id: 2, title: 'buildingRating.summary' }
      ],
      allQuestions: [],
      allAnswers: [],
      selectedAnswers: [],
      rating: {},
      loading: false,
      saving: false,
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    savingDisabled () {
      return this.saving || !!this.rating?.approval_date || this.isVersionEnd || !this.hasApplicationPermissionByName('SALKUTUS_OIKEUS')
    },
    approvalDisabled () {
      return this.saving || this.isVersionEnd || !this.hasApplicationPermissionByName('SALKUTUS_HYVAKSYNTA')
    },
    isApproved () {
      return this.rating.approval_date
    },
    isVersionEnd () {
      if (typeof this.rating.end_date !== 'undefined' && this.rating.end_date !== null) {
        let date = new Date(this.rating.end_date).toISOString().split('T')[0]
        console.log('date', date)
        if (date < new Date(this.currentDate).toISOString().split('T')[0]) {
          return true
        } else {
          return false
        }
      }
      else {
        return false
      }
    }
  },
  async mounted () {
    this.loading = true
    if (this.buildingRatingId) {
      this.rating = await this.$rambollfmapi.buildingRating.get(this.buildingRatingId)
      this.rating.rating_class = this.rating.rating_class !== null ? this.rating.rating_class : this.rating.rating_class_proposal
      this.originalRating = JSON.parse(JSON.stringify(this.rating))
    } else if (this.initialRating) {
      this.rating = this.initialRating
    }
    this.loading = false

    if (this.rating.id) {
      this.selectedAnswers = await this.$rambollfmapi.buildingRating.getAnswersByBuildingRating(this.rating.id)
    }
    this.allAnswers = await this.$rambollfmapi.buildingRating.getAnswers()
    this.allQuestions = await this.$rambollfmapi.buildingRating.getQuestions()
    
    this.allQuestions.map(q => {
      this.allAnswers.push({ id: -1, answer_text: this.$t('Unknown answer'), points: -1 })
      q.answers = this.allAnswers.filter(a => a.id_question === q.id || a.id === -1),
      q.selectedAnswer = this.selectedAnswers ? this.selectedAnswers.filter(a => a.id_question === q.id)[0] : null
      q.selectedAnswerId = this.selectedAnswers ? this.selectedAnswers.filter(a => a.id_question === q.id)[0]?.id_answer : null
    })
    this.validateQuestions(this.allQuestions)
    this.updateBuildingClasses()
  },
  methods: {
    dateAsString (date) {
      return helpers.humanize.date(date, 'date')
    },
    close () {
      this.$emit('close')
    },
    async onQuestionsTabUpdated (updatedQuestions) {
      this.allQuestions = updatedQuestions
      this.validateQuestions(updatedQuestions)
      this.rating.selectedAnswers = updatedQuestions.map(q => q.selectedAnswer).filter(item => item)
      const result = await this.$rambollfmapi.buildingRating.calculateRating(this.rating, this.currentDate)
      this.rating = result
      this.validateQuestions(this.allQuestions)
      this.updateBuildingClasses()
      this.rating.rating_class = this.rating.rating_class_proposal // Update rating class as proposal when it's updated
    },
    async validateQuestions (questions) {
      const unansweredQuestions = questions.filter(q => !q.selectedAnswerId || q.selectedAnswerId === -1).map(a => a.id).join(', ')
      this.pages[1].description = unansweredQuestions ? `${this.$t('Unanswered questions')}: ${unansweredQuestions}` : ''
      this.rating.unanswered_questions = unansweredQuestions
      this.rating.answered_questions_count = this.allQuestions.filter(q => q.selectedAnswerId && q.selectedAnswerId !== -1).length
    },
    async updateBuildingClasses () {
      this.rating.rating_class_proposal_name = this.buildingRatingClasses.find(c => c.id == this.rating.rating_class_proposal)?.name
      this.rating.rating_class_name = this.buildingRatingClasses.find(c => c.id == this.rating.rating_class)?.name
    },
    async buildingRatingSave () {
      this.saving = true
      if (!this.rating.id) {
        this.rating.start_date = this.currentDate
        await this.$rambollfmapi.buildingRating.post(this.rating, this.currentDate)
      } else {
        const observer = this.$jsonpatch.observe(this.originalRating)
        await this.updateChanges()
        const patch = this.$jsonpatch.generate(observer)
        await this.$rambollfmapi.buildingRating.update(this.rating.id, patch, this.currentDate)
      }
      this.$emit('updateWidget')
      this.saving = false
      this.close()
    },
    async approveBuildingRating () {
      this.saving = true
      const observer = this.$jsonpatch.observe(this.originalRating)
      await this.updateChanges()
      let patch = this.$jsonpatch.generate(observer)
      patch = patch.concat({ op: 'replace', path: '/approval_date', value: this.currentDate })
      await this.$rambollfmapi.buildingRating.approve(this.rating.id, patch, this.currentDate)
      this.$emit('updateWidget')
      this.saving = false
      this.close()
    },
    async openBuildingRating () {
      this.saving = true
      const observer = this.$jsonpatch.observe(this.originalRating)
      await this.updateChanges()
      let patch = this.$jsonpatch.generate(observer)
      patch = patch.concat({ op: 'replace', path: '/approval_date', value: undefined })
      await this.$rambollfmapi.buildingRating.approve(this.rating.id, patch, this.currentDate)
      this.$emit('updateWidget')
      this.saving = false
      this.close()
    },
    updateChanges () {
      this.originalRating.id = this.rating.id
      this.originalRating.rating_date = this.rating.rating_date
      this.originalRating.rating_class = this.rating.rating_class
      this.originalRating.rating_class_proposal = this.rating.rating_class_proposal
      this.originalRating.justification = this.rating.justification
      this.originalRating.answered_questions_count = this.rating.answered_questions_count
      this.originalRating.selectedAnswers = this.rating.selectedAnswers
      this.originalRating.end_date = this.rating.end_date
      this.originalRating.start_date = this.rating.start_date
      this.originalRating.estate_id = this.rating.estate_id
      this.originalRating.district = this.rating.district
      this.originalRating.neighborhood = this.rating.district.locationAreaNumber.toString()
      this.originalRating.municipality_identifier = this.rating.municipality_identifier
      this.originalRating.usage = this.rating.usage
      this.originalRating.address = this.rating.address
      this.originalRating.completed_year = this.rating.completed_year
      this.originalRating.official_gross_area = this.rating.official_gross_area
      this.originalRating.site_name = this.rating.site_name
      this.originalRating.landlord = this.rating.landlord
      this.originalRating.state_area_koki = this.rating.state_area_koki
      this.originalRating.state_area = this.rating.state_area
      this.originalRating.allocated_unit_area = this.rating.allocated_unit_area
      this.originalRating.unit_area = this.rating.unit_area
      this.originalRating.buildingproject_number = this.rating.buildingproject_number
      this.originalRating.average = this.rating.average
      this.originalRating.performance_level = this.rating.performance_level
      this.originalRating.characteristics = this.rating.characteristics
      this.originalRating.publication_date = this.rating.publication_date
      this.originalRating.approval_date = this.rating.approval_date
      this.originalRating.average_rent = this.rating.average_rent
      this.originalRating.is_usage = this.rating.is_usage
      this.originalRating.unobstructed = this.rating.unobstructed
      this.originalRating.ventilation_notice_done = this.rating.ventilation_notice_done
      this.originalRating.protected_zoning_plan = this.rating.protected_zoning_plan
      this.originalRating.id_buildingproject = this.rating.id_buildingproject
    },
  }
}
</script>

<style scoped>
.alert {
  position: relative;
  z-index: 1;
  margin-top: var(--r-spacing-m);
}
</style>