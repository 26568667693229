<template>
  <ViewAndSelections
    contract-level="AllContracts"
    parent-level="contract"
    :loading-as-prop="loading"
    @close-filter="showColumnsFilterDialog"
  >
    <template #buttons>
      <v-row
        class="toolbar dashboard overflow-x-auto ml-1 mr-1"
        :justify="isMobile ? 'start' : 'end'"
      >
        <v-btn
          small
          outlined
          rounded
          :disabled="loading"
          @click="showColumnsFilterDialog"
        >
          {{ $t('Choose visible columns') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'pdf'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('pdf')"
        >
          {{ $t('Export to PDF') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'csv'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('csv')"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>
      </v-row>
    </template>
    <template
      #table="props"
    >
      <Table
        v-show="!loading"
        :rows="viewData"
        :headers="viewHeaders"
        :footers="debouncedFooters"
        :hide="true"
        :show-filter="showColumnsFilter"
        :document-type="exportAsDocument"
        :pagination.sync="pagination"
        :show-save-selection="true"
        :selected-view-string="props.props.storedView ? props.props.storedView.name : null"
        :stored-views="props.props.storedViews.filter(sv => sv.level === 'AllContracts')"
        :show-confirm="props.props.confirmation"
        @exported="exporting = {}"
        @select_view="props.props.selectView"
        @save-selection="props.props.saveStoredView"
        @delete-view="props.props.deleteSelectedView"
      />
    </template>
  </ViewAndSelections>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import ViewAndSelections from './ViewAndSelections.vue'
import Table from '../Table.vue'
import { CorporationLinker } from "../../helpers/leasing/corporations"
import { getBooleanString } from "../../helpers/mappers/booleanString"

export default {
  name: 'AllContractsLevel',
  components: {
    Table,
    ViewAndSelections
  },
  props: {
    siteId: { type: Number, default: undefined },
    importing: { type: Boolean, default: false },
    showFutureContracts: { type: Boolean, default: true },
    corporations: { type: Array, default: () => []}
  },
  data () {
    return {
      showColumnsFilter: false,
      exportAsDocument: [],
      pagination: {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy: ['possessor'], 
      },
      exporting: {},
      footerTimer: null
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapState('app', ['buildingRecursionLoadingState', 'sites']),
    ...mapState('haltia', ['haltiaRentalStatuses', 'futureHaltiaRentalStatuses', 'haltiaRentalStatusesLoading', 'futureHaltiaRentalStatusesLoading']),
    ...mapState('carpark', ['carparkContracts', 'futureCarparkContracts']),
    ...mapState('sites', ['loadedBuildings']),
    corporationLinker () {
      return new CorporationLinker(this.corporations)
    },
    loading () {
      return this.haltiaRentalStatusesLoading || this.futureHaltiaRentalStatusesLoading
    },
    isMobile () {
      return visualViewport.width <= 760
    },
    headers () {
      return [
        // The spread operator (...) does nothing if the array is empty but otherwise adds the object inside array to the returning array
        // This way empty object or undefined is not added into the returning array if siteId is not undefined
        ...(this.siteId === undefined ? [
          {
            value: 'site_identifier',
            text: this.$t('Site identifier'),
            format: ''
          }
        ] : []),
        {
          value: 'siteName',
          text: this.$t('Site name'),
          format: '',
          ignoreHeader: true
        },
        {
          value: 'longUnitCodes',
          text: this.$t('Unit code long'),
          format: '',
          ignoreHeader: true
        },
        {
          value: 'buildingCode',
          text: this.$t('Building code'),
          format: ''
        },
        {
          value: 'unitName',
          text: this.$t('Unit'),
          format: ''
        },
        {
          text: this.$t('Unit Status'),
          value: 'unitStatus',
          format: '',
        },
        {
          text: this.$t('Nro'),
          value: 'carparksString',
          format: '',
        },
        {
          text: this.$t('Carpark code long'),
          value: 'carpark_code_long',
          format: '',
        },
        {
          value: 'contractNumber',
          text: this.$t('Contract number'),
          format: '',
          editable: true
        },
        {
          value: 'curPartyName',
          text: this.$t('Tenant'),
          format: '',
          editable: true
        },
        {
          value: 'business_id',
          text: this.$t('Business ID'),
          format: '',
          editable: true
        },
        {
          value: 'tenant_category',
          text: this.$t('Tenant category'),
          format: '',
          editable: false,
          ignoreHeader: true
        },
        {
          value: 'credit_rating',
          text: this.$t('Credit rating'),
          format: '',
          editable: false,
          ignoreHeader: true
        },
        {
          value: 'validity',
          text: this.$t('Validity'),
          format: '',
          editable: true
        },
        {
          value: 'signature_date',
          text: this.$t('Contract date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'curPartyStartDate',
          text: this.$t('Contract start date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'contractFirstPossibleEndDate',
          text: this.$t('Contract first possible end date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'curPartyEndDate',
          text: this.$t('Contract end date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'tenantFirstPossibleEndDate',
          text: this.$t('rental_status.tenant_first_possible_end_date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'tenantNotice',
          text: this.$t('Agreement notice period (Tenant)'),
          format: 'Number',
          editable: true
        },
        {
          value: 'landlordFirstPossibleEndDate',
          text: this.$t('rental_status.landlord_first_possible_end_date'),
          format: 'Date',
          editable: true
        },
        {
          value: 'landlordNotice',
          text: this.$t('Agreement notice period (Lanlord)'),
          format: 'Number',
          editable: true
        },
        {
          value: 'huom',
          text: this.$t('Validity notice'),
          format: '',
          editable: true
        },
        {
          value: 'contract_note',
          text: this.$t('leasing.additional_fixed_term_info'),
          format: '',
          editable: true
        },
        {
          value: 'area',
          text: this.$t('Unit area'),
          format: 'Area',
          ignoreHeader: true
        },
        {
          value: 'carpark_area',
          text: this.$t('Carparks.area'),
          format: 'Area',
          ignoreHeader: true
        },
        {
          value: 'contract_area',
          text: this.$t('Contract area'),
          format: 'Area',
          editable: true
        },
        {
          value: 'additionalInfo',
          text: this.$t('Contract details'),
          format: '',
          editable: true
        },
        {
          value: 'vat_status',
          text: this.$t('VAT-responsibility'),
          format: '',
          editable: true
        },
        {
          value: 'contractType',
          text: this.$t('leasing.contract_type'),
          format: '',
          editable: true
        },
        {
          value: 'industry',
          text: this.$t('leasing.industry'),
          format: '',
          editable: true
        },
        {
          value: 'contract_status',
          text: this.$t('Contract status'),
          format: '',
          editable: true
        },
        {
          value: 'paymentsDifferentValidityperiods',
          text: this.$t('haltia.Maksuissa poikkeavat voimassaoloajat'),
          format: '',
          editable: true
        },
        {
          value: 'capitalRentPerSquare',
          text: this.$t('contracts.eur_per_square_capital'),
          format: 'MoneyPerSquare',
          editable: false
        },
        {
          value: 'capitalRent',
          text: this.$t('Capital rent'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'maintenanceRent',
          text: this.$t('Maintenance rent'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'maintenanceRentEqualization',
          text: this.$t('rental_status.maintenance_rent_equalization'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'changeoverRent',
          text: this.$t('rental_status.changeover_rent'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'relocationworkleaseStartDate',
          text: this.$t('haltia.Muutostyövuokran alkamispvm'),
          format: 'Date',
          editable: true
        },
        {
          value: 'relocationworkleaseEndDate',
          text: this.$t('haltia.Muutostyövuokran päättymispvm'),
          format: 'Date',
          editable: true
        },
        {
          value: 'marketingFee',
          text: this.$t('haltia.Markkinointimaksu'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'separateAllowances',
          text: this.$t('rental_status.separate_allowances'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'discounts',
          text: this.$t('rental_status.discounts'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'discountsStartDate',
          text: this.$t('haltia.Alennusten alkamispvm'),
          format: 'Date',
          editable: true
        },
        {
          value: 'discountsEndDate',
          text: this.$t('haltia.Alennusten päättymispvm'),
          format: 'Date',
          editable: true
        },
        {
          value: 'licenseFees',
          text: this.$t('haltia.Lisenssimaksut'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'rentalResponsibility',
          text: this.$t('haltia.Vuokravastuu'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'contractTurnoverTieup',
          text: this.$t('haltia.Sopimuksella liikevaihtosid. vuokra'),
          format: '',
          editable: true
        },
        {
          value: 'turnoverRelatedRent',
          text: this.$t('haltia.Liikevaihtosidonnainen vuokra'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'turnoverPercentage',
          text: this.$t('haltia.Liikevaihtoprosentti'),
          format: 'Percentage',
          editable: true
        },
        {
          value: 'turnoverRelatedInfo',
          text: this.$t('Turnover related info'),
          format: '',
          editable: true,
        },
        {
          value: 'openBillsTotal',
          text: this.$t('rental_status.open_bills_total'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'basicRent',
          text: this.$t('rental_status.basic_rent'),
          format: 'Euro',
          editable: true
        },
        {
          value: 'rentReviewType',
          text: this.$t('rental_status.rent_review_type'),
          format: '',
          editable: true
        },
        {
          value: 'indexType',
          text: this.$t('rental_status.index_type'),
          format: '',
          editable: true
        },
        {
          value: 'basicIndexPointNumber',
          text: this.$t('rental_status.basic_index_point_number'),
          format: 'Number',
          editable: true
        },
        {
          value: 'reviewMonths',
          text: this.$t('rental_status.review_months'),
          format: '',
          editable: true
        },
        {
          value: 'latestIndexYear',
          text: this.$t('rental_status.latest_index_year'),
          format: 'Number',
          editable: true
        },
        {
          value: 'latestIndexMonth',
          text: this.$t('rental_status.latest_index_month'),
          format: 'Number',
          editable: true
        },
        {
          value: 'latestIndexPointNumber',
          text: this.$t('rental_status.latest_index_point_number'),
          format: 'Number',
          editable: true
        },
        {
          value: 'minimumIncrease',
          text: this.$t('rental_status.minimum_increase'),
          format: 'Percentage',
          editable: true
        },
        {
          value: 'fixedIncrease',
          text: this.$t('haltia.Kiinteä korotus (prosentti)'),
          format: 'Percentage',
          editable: true
        },
        {
          value: 'checkBased',
          text: this.$t('haltia.Tarkistus perustuu'),
          format: '',
          editable: true
        },
        {
          value: 'rentcheckStartDate',
          text: this.$t('haltia.Vuokrantarkistus alkaa'),
          format: 'Date',
          editable: true
        },
        {
          value: 'landlord',
          text: this.$t('Landlord'),
          format: '',
          editable: true
        },
      ]
    },
    footerDefinitions () { 
      return [
        { value: 'area', type: 'sum'},
        { value: 'carpark_area', type: 'sum' },
        { value: 'contract_area', type: 'sum' },
        { value: 'capitalRentPerSquare', type: 'average' },
        { value: 'capitalRent', type: 'sum' },
        { value: 'maintenanceRent', type: 'sum' },
        { value: 'maintenanceRentEqualization', type: 'sum' },
        { value: 'changeoverRent', type: 'sum' },
        { value: 'marketingFee', type: 'sum' },
        { value: 'separateAllowances', type: 'sum' },
        { value: 'discounts', type: 'sum' },
        { value: 'licenseFees', type: 'sum' },
        { value: 'turnoverRelatedRent', type: 'sum' },
        { value: 'openBillsTotal', type: 'sum' },
        { value: 'basicRent', type: 'sum' },
      ]
    },
    viewData () {
      let data = []
      if (!this.loading) {
        const units = this.getUnitContractData()
        const carparks = this.getCarparkContractData()
        data = units.concat(carparks)
      }
      return data
    },
    viewHeaders () {
      return this.headers.filter(header => header.value !== 'buildingCode')
    },
  },
  watch: {
    buildingRecursionLoadingState (newValue) {
      if (!newValue) {
        this.fetchData()
      }
    },
    loadedBuildings: {
      handler: async function () {
        await this.fetchData()
      },
      deep: true,
    },
  },
  mounted () {
    if (this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_HALTIA')) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions('haltia', ['fetchHaltiaRentalStatuses', 'fetchFutureHaltiaRentalStatuses']),
    ...mapActions('carpark', ['fetchCarparkContracts', 'fetchFutureCarparkContracts']),
    async fetchData () {
      const promises = []
      if (this.showFutureContracts) {
        promises.push(this.fetchFutureHaltiaRentalStatuses(this.siteId))
        promises.push(this.fetchFutureCarparkContracts(this.siteId))
      }

      promises.push(this.fetchHaltiaRentalStatuses(this.siteId))
      promises.push(this.fetchCarparkContracts(this.siteId))
      await Promise.allSettled(promises)
    },
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType
      
      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push('future_fairy_contract')
    },
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    capitalRentPerSquare (capitalRent, area){
      if(!capitalRent || !area){
        return 0
      }

      return capitalRent/area
      
    },
    getUnitContractData () {
        let units = this.showFutureContracts
            ? [...this.haltiaRentalStatuses, ...this.futureHaltiaRentalStatuses]
            : [...this.haltiaRentalStatuses]
    
        const rowsMap = new Map()
    
        for (const rs of units) {
            const corporation = this.corporationLinker.find({
                businessId: rs.business_id,
                contractNumber: rs.contractNumber,
                partyId: rs.curPartyId
            })
    
            const unitData = {
                ...rs,
                vat_status: getBooleanString(rs.vat),
                paymentsDifferentValidityperiods: getBooleanString(rs.paymentsDifferentValidityperiods),
                contractTurnoverTieup: getBooleanString(rs.contractTurnoverTieup),
                huom: getBooleanString(rs.huom),
                unitStatus: this.$t(rs.unitStatus),
                tenant_category: corporation?.tenant_category,
                credit_rating: corporation?.credit_rating
            }
    
            if (unitData.contractNumber) {
                if (!rowsMap.has(unitData.contractNumber)) {
                    rowsMap.set(unitData.contractNumber, {
                        ...unitData,
                        unitNames: [{ buildingCode: unitData.buildingCode, units: [unitData.unitName] }],
                        longUnitCodeList: [unitData.unit_code_long],
                        area: unitData.area
                    })
                } else {
                    const row = rowsMap.get(unitData.contractNumber)
                    const building = row.unitNames.find(b => b.buildingCode === unitData.buildingCode)
    
                    if (building) {
                        building.units.push(unitData.unitName)
                    } else {
                        row.unitNames.push({ buildingCode: unitData.buildingCode, units: [unitData.unitName] })
                    }
    
                    if (unitData.unit_code_long && unitData.unit_code_long !== "") {
                        row.longUnitCodeList.push(unitData.unit_code_long)
                    }
    
                    row.area += unitData.area
                }
            }
        }
    
        const rows = Array.from(rowsMap.values());
    
        for (const row of rows) {
            row.unitName = row.unitNames.reduce((a, b, index) => {
                let text = a
                if (index > 0) {
                    text += '<br/>'
                }
                return `${text}<b>${b.buildingCode}:</b> ${b.units.join(', ')}`
            }, '')
    
            row.capitalRentPerSquare = this.capitalRentPerSquare(row.capitalRent, row.area);
            row.longUnitCodes = row.longUnitCodeList.join(",\n");
        }
    
        return rows
    },
    getCarparkContractData () {
      let carparks = this.showFutureContracts
        ? [...this.carparkContracts, ...this.futureCarparkContracts]
        : [...this.carparkContracts]
    
      const keymap = { 
        "site_name": "siteName",
        "contract_number": "contractNumber",
        "tenant": "curPartyName",
        "contract_start_date": "curPartyStartDate",
        "first_possible_end_date": "contractFirstPossibleEndDate",
        "contract_end_date": "curPartyEndDate",
        "tenant_first_possible_end_date": "tenantFirstPossibleEndDate",
        "tenant_notice": "tenantNotice",
        "landlord_first_possible_end_date": "landlordFirstPossibleEndDate",
        "landlord_notice": "landlordNotice",
        "validity_note": "huom",
        "additional_info": "contract_note",
        "vat_responsibility": "vat_status",
        "contract_type": "contractType",
        "payments_different_validity_periods": "paymentsDifferentValidityperiods",
        "capital_rent": "capitalRent",
        "maintenance_rent": "maintenanceRent",
        "maintenance_rent_equalization": "maintenanceRentEqualization",
        "changeover_rent": "changeoverRent",
        "separate_allowances": "separateAllowances",
        "discounts_start_date": "discountsStartDate",
        "discounts_end_date": "discountsEndDate",
        "rental_responsibility": "rentalResponsibility",
        "contract_turnover_tieup": "contractTurnoverTieup",
        "turnover_related_rent": "turnoverRelatedRent",
        "turnover_percentage": "turnoverPercentage",
        "open_bills_total": "openBillsTotal",
        "basic_rent": "basicRent",
        "rent_review_type": "rentReviewType",
        "index_type": "indexType",
        "basic_index_point_number": "basicIndexPointNumber",
        "review_months": "reviewMonths",
        "latest_index_year": "latestIndexYear",
        "latest_index_month": "latestIndexMonth",
        "latest_index_point_number": "latestIndexPointNumber",
        "minimum_increase": "minimumIncrease",
        "fixed_increase": "fixedIncrease",
        "check_based": "checkBased",
        "rentcheck_start_date": "rentcheckStartDate",
        "contractInfo": "additionalInfo",
        "area": "carpark_area",

      }
    
      const data = carparks.map(cp => {
        const d = { ...cp }
    
        Object.keys(cp).forEach(key => {
          if (keymap[key]) {
            d[keymap[key]] = cp[key]
            delete d[key]
          }
        })
    
        const corporation = this.corporationLinker.find({
          businessId: cp.business_id,
          contractNumber: cp.contract_number,
          partyId: cp.id_party
        })
    
        d.vat_status = getBooleanString(d.vat_status)
        d.paymentsDifferentValidityperiods = getBooleanString(d.paymentsDifferentValidityperiods)
        d.contractTurnoverTieup = getBooleanString(d.contractTurnoverTieup)
        d.huom = getBooleanString(d.huom)
        d.tenant_category = corporation?.tenant_category
        d.credit_rating = corporation?.credit_rating
    
        return d
      });
    
      const rowsMap = new Map()
    
      for (const d of data) {
        if (d.contractNumber) {
          if (!rowsMap.has(d.contractNumber)) {
            const newRow = { ...d }
            newRow.carparkCodes = [{ buildingCode: d.building_code, carparks: [d.carpark_code] }]
            newRow.carparkCodesLong = [d.carpark_code_long]
            newRow.types = [d.type]
            rowsMap.set(d.contractNumber, newRow)
          } else {
            const row = rowsMap.get(d.contractNumber)
            const building = row.carparkCodes.find(b => b.buildingCode === d.building_code)
    
            if (building) {
              building.carparks.push(d.carpark_code)
            } else {
              row.carparkCodes.push({ buildingCode: d.building_code, carparks: [d.carpark_code] })
            }
    
            if (d.carpark_code_long && d.carpark_code_long !== "") {
              row.carparkCodesLong.push(d.carpark_code_long)
            }
    
            if (d.type && !row.types.includes(d.type)) {
              row.types.push(d.type)
            }
    
            if (d.carpark_area) {
              row.carpark_area += d.carpark_area
            }
    
            if (row.curPartyEndDate && d.curPartyEndDate) {
              row.curPartyEndDate = d.curPartyEndDate < row.curPartyEndDate ? row.curPartyEndDate : d.curPartyEndDate
            } else {
              row.curPartyEndDate = null
            }
          }
        }
      }
    
      const rows = Array.from(rowsMap.values())
    
      for (const row of rows) {
        row.carparksString = row.carparkCodes.reduce((acc, curr, index) => {
          let text = acc
          if (index > 0) {
            text += '<br/>'
          }
          if (curr.buildingCode) {
            text += `<b>${curr.buildingCode}: </b>`
          }
          return `${text}${curr.carparks.join(', ')}`
        }, '')
    
        row.carpark_code_long = row.carparkCodesLong.join(",\n")
        row.capitalRentPerSquare = this.capitalRentPerSquare(row.capitalRent, row.area)
      }
    
      return rows
    },
    debouncedFooters (headers, data) {
      clearTimeout(this.footerTimer)
      const footerSums = []
      const timeout = 500
      this.footerTimer = setTimeout(() => {      
        const footerValues = this.footerDefinitions
        const calculatedFooterValues = {}
        calculatedFooterValues['site_name'] = this.$t('Total')
        footerValues.forEach(footer => {
          let value = footer.value
          let result = null
          switch (footer.type) {
            case 'sum': {
              result = data.map(r => isNaN(r[value]) ? 0 : Number(r[value]))
                .reduce((acc, cur) => acc + cur, 0).toFixed(2)
              break
            }
            case 'weightedAverage': {
              const weight = footer.weight
              const dataWeights = data.map(r => 
                ({
                  value: isNaN(r[value]) ? 0 : Number(r[value]),
                  weight: isNaN(r[weight]) ? 0 : Number(r[weight]) 
                })
              )
              const [sum, weightSum] = dataWeights.reduce((acc, curr) => {
                if (curr.value) {
                  acc[0] += curr.value * curr.weight
                  acc[1] += curr.weight
                }
                return acc
              }, [0, 0])
              result = weightSum ? (sum/weightSum).toFixed(2) : null
              break
            }
            case 'average': {
              const sum = data.map(r => isNaN(r[value]) ? 0 : Number(r[value]))
                .reduce((acc, cur) => acc + cur, 0).toFixed(2)
              const itemCount = data.filter(item => item[value] != null).length
              result = itemCount ? sum / itemCount : null 
              break
            }
            default: {
              return null
            }
          }
          if (result) {
            calculatedFooterValues[value] = result
          }
        })
        footerSums.push(calculatedFooterValues)
      }, timeout);
      return footerSums
    }
  }
}
</script>
<style scoped>
</style>