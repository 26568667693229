import { i18n } from '../../plugins/i18n'
import { getProspectType } from './prospect'
//import app  from '../../store/app'    

/**
 * Return title text for rental process
 * @param {Object} rentalProcess - Rental process
 * @returns {String} 
 */
export function getRentalProcessTitle (rentalProcess) {
  if (rentalProcess == null) return ''
    const type = i18n.t(getProspectType(rentalProcess)?.typeText)
    const areaString = rentalProcess?.area_preference ? `${rentalProcess?.area_preference.toString()} m\u00B2` : ''
    const corporation =  rentalProcess?.nameless_search ?  rentalProcess?.tag : rentalProcess?.corporation
    return `${type}: ${[corporation, areaString].filter(Boolean).join(": ")}`
  }

export function checkRulesForMFilesIds (field) {
  //field should be numbers only
  if (!(/^\d+$/.test(field))) {
    return  i18n.t('leasing.digits_only')
  }
  //field should be 6-7 digits
  if (field.length < 6 || field.length > 7) {
    return  i18n.t('leasing.field_length')
  }
  return true
}

export function transformToProspect (process) {
  return {
    ...process,
    id_process: process.id, 
    id_prospect: process.id, 
    prospect_description: getRentalProcessTitle(process),
    unit_area_preference: process.area_preference,
    id_osapuoli: process.id_corporation,
    unit_type_preference: process.usage_types
  }
}
/**
 * Return process stage and date when stage last changed. Info includes ui text and date. Below are possible stages
 * Inactive (process status inactive)
 * Closed (process status closed)
 * Created (process active/awaiting allocation and no annex or offer made for process)
 * @param {Object} process - process object
 * @returns
 */
export function getProcessStage (process, events) {
  let stage = ''
  let stageDate = new Date()

  if (process.status === 'Inactive') {
    stage = i18n.t('Passed')
    let inactiveEvent = events.find(
      (event) => event.event_type === 'Merkitty ohi menneeksi'
    )
    if (inactiveEvent != null) {
      stageDate = inactiveEvent.modified_date
    } else {
      stageDate = process.last_modified
    }
  } else if (process.status === 'Closed') {
    stage = i18n.t('Closed')
    let closedEvent = events.find(
      (event) => event.event_type === 'Merkitty voitetuksi'
    )
    if (closedEvent != null) {
      stageDate = closedEvent.modified_date
    } else {
      stageDate = process.last_modified
    }
  } else  {
    const changedEvent = events.find(
      (event) => event.event_type === 'Muutettu tilaa'
    )
    stage = i18n.t(process.stage)
    if (changedEvent != null) {
      stageDate = changedEvent.modified_date
    } else {
      stageDate = process.last_modified
    }
  }

  return { stage, stageDate }
}