<template>
  <div
    v-if="item.id_prospect != null"
    class="linked my-2"
    @click="additionalAction(header, item)"
  >
    {{ item.prospect_description }}
  </div>
  <v-menu
    v-else
    :offset-y="true"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        outlined
        rounded
        text
        class="lowercase-button"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("Select") }}
        <v-icon right>
          expand_more
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item 
          v-for="val in getOptions()"
          :key="val.id"
          @click="updateContractState(item, val.id)"
        >
          <v-list-item-title>{{ val.label }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="hasApplicationPermissionByName('LEASING')"
          @click="additionalAction(header, item, false)"
        >
          <v-list-item-title>
            {{ $t("leasing.begin_renewal") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="additionalAction(header, item, true)">
          <v-list-item-title>{{ $t("leasing.no_renewal") }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "RenewalColumn",
  props: {
    header: { type: Object, default: null },
    item: { type: Object, default: null },
    items: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters("app", ["definitionsByGroupLabel", 'hasApplicationPermissionByName']),
  },
  methods: {
    ...mapActions("leasing", [
      "changeProspectModalState",
      "changeRenegotiationsModalState",
      "patchRentalStatus"
    ]),
    ...mapActions('rentalProcess', ['openRentalProcess']),
    getOptions () {
      return this.definitionsByGroupLabel("contract.negotiation_status")
    },
    async additionalAction (header, item, noAction = false) {
      let rawData

      if(item.id_process){
        this.openRentalProcess({ id: item.id_process })
        return
      }

      if (item.id_prospect != null) {
        this.changeProspectModalState(item.id_prospect)
      } else if (noAction) {
        rawData = this.items.find(
          (i) => i.contractNumber === item.contractNumber
        )
        rawData.no_renegotiations_modal = true
        rawData.type_renewal = true
        this.changeRenegotiationsModalState(rawData)
      } else {
        rawData = this.items.find(
          (i) => i.contractNumber === item.contractNumber
        )
        rawData.no_renegotiations_modal = false
        rawData.type_renewal = true
        this.changeRenegotiationsModalState(rawData)
      }
    },
    async updateContractState (item, value) {

      const patch = [
        {
          op: "replace",
          path: "defNegotiationStatus",
          value,
        },
      ]

      this.patchRentalStatus({
        patch,
        unitId: item.unitId,
        curUnitPartyId: item.curUnitPartyId,
        contractNumber: item.contractNumber,
      })
    },
  },
}
</script>

<style scoped>

.lowercase-button {
  text-transform: unset;
}

.no-background-hover::before {
  background-color: transparent !important;
}
</style>
