<template>
  <div>
    <v-tab-item
      :key="3"
    >
      <v-form
        ref="form1"
        class="pt-2"
      >
        <ValidationObserver ref="observer">
          <v-expansion-panels
            v-model="panels"
            class="my-2"
            multiple
          >
            <v-expansion-panel v-if="canConnectToPortfolio == false || (canConnectToPortfolio == true && isConnectedToPortfolio == false)">
              <v-expansion-panel-header>
                {{ $t('create_rent_contract.filters') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  v-model="siteFilter"
                  class="form-fields"
                  :label="$t('create_rent_contract.site')"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="canConnectToPortfolio == false || (canConnectToPortfolio == true && isConnectedToPortfolio == false)">
              <v-expansion-panel-header>
                {{ $t('create_rent_contract.group_update') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <table
                  class="v-datatable v-data-table v-table them--light table-overflow"
                  style="margin-top: 1em; margin-bottom: 1em"
                >
                  <thead>
                    <th class="column">
                      {{ $t('Group update') }}
                    </th>
                    <th class="column">
                      {{ $t('create_rent_contract.rent_contract_table_startdate') }}
                    </th>
                    <th
                      v-if="!isNewContract || isNewContract && validityType === 'validity_fixed'"
                      class="column"
                    >
                      {{ $t('create_rent_contract.rent_contract_table_enddate') }}
                    </th>
                    <th class="column">
                      {{ $t('create_rent_contract.rent_contract_table_area') }}
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="column">
                        {{ $t('New values') }}
                      </th>
                      <td>
                        <v-menu
                          offset-y
                          min-width="0"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :rules="`validUnitStartDate: ${rentPeriodStartDate}, ${rentContractEndDate}, ${groupEndDate}`"
                            >
                              <v-text-field
                                v-model="groupStartDateFormatted"
                                :label="$t('create_rent_contract.rent_contract_table_startdate')"
                                :aria-label="$t('create_rent_contract.rent_contract_table_startdate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                                :hint="$t('date_format')"
                                prepend-icon="calendar_today"
                                :error-messages="errors[0]"
                                @blur="groupStartDate = parseDate(groupStartDateFormatted)"
                                v-on="on"
                              />
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="groupStartDate"
                            no-title
                            scrollable
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </td>
                      <td
                        v-if="!isNewContract || isNewContract && validityType === 'validity_fixed'"
                      >
                        <v-menu
                          offset-y
                          min-width="0"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :rules="`validUnitEndDate: ${rentPeriodStartDate}, ${rentContractEndDate}, ${groupStartDate}`"
                            >
                              <v-text-field
                                v-model="groupEndDateFormatted"
                                prepend-icon="calendar_today"
                                :label="$t('create_rent_contract.rent_contract_table_enddate')"
                                :aria-label="$t('create_rent_contract.rent_contract_table_enddate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                                :hint="$t('date_format')"
                                :error-messages="errors[0]"
                                @blur="groupEndDate = parseDate(groupEndDateFormatted)"
                                v-on="on"
                              />
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="groupEndDate"
                            no-title
                            scrollable
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </td>
                      <td>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="`validAgreedArea: ${groupUnitArea}`"
                        >
                          <v-text-field
                            v-model="groupUnitArea"
                            type="number"
                            step="0.50"
                            min="0.50"
                            :error-messages="errors[0]"
                            :suffix="$t('create_rent_contract.area_suffix')"
                            :label="$t('create_rent_contract.rent_contract_table_area')"
                            class="ma-2"
                          />
                        </ValidationProvider>  
                      </td>
                    </tr>
                    <tr>
                      <th><span class="d-sr-only">{{ $t('create_rent_contract.update_for_all_units') }}</span></th>
                      <td>
                        <v-btn
                          outlined
                          rounded
                          block
                          @click="updateStartDates()"
                        >
                          {{ $t('create_rent_contract.update_for_all_units') }}
                        </v-btn>
                      </td>
                      <td
                        v-if="!isNewContract || isNewContract && validityType === 'validity_fixed'"
                      >
                        <v-btn
                          outlined
                          rounded
                          block
                          @click="updateEndDates()"
                        >
                          {{ $t('create_rent_contract.update_for_all_units') }}
                        </v-btn>
                      </td>
                      <td>
                        <v-btn
                          outlined
                          rounded
                          block
                          @click="updateUnitAreas()"
                        >
                          {{ $t('create_rent_contract.update_for_all_units') }}
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--Units and structures-->
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('create_rent_contract.infoUnits') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <table
                  class="v-data-table theme--light"
                  :style="{ width: '100%'}"
                >
                  <thead>
                    <th
                      v-if="canConnectToPortfolio == false || (canConnectToPortfolio == true && isConnectedToPortfolio == false)"
                      class="column"
                    >
                      {{ $t('create_rent_contract.rent_contract_table_site') }}
                    </th>
                    <th
                      v-if="canConnectToPortfolio == false || (canConnectToPortfolio == true && isConnectedToPortfolio == false)"
                      class="column"
                    >
                      {{ $t('create_rent_contract.rent_contract_table_target') }}
                    </th>
                    <th
                      v-if="(canConnectToPortfolio == true && isConnectedToPortfolio == true)"
                      class="column"
                    >
                      {{ $t('Status portfolio') }}
                    </th>
                    <th class="column">
                      {{ $t('create_rent_contract.rent_contract_table_startdate') }}
                    </th>
                    <th
                      v-if="!isNewContract || isNewContract && validityType === 'validity_fixed'"
                      class="column"
                    >
                      {{ $t('create_rent_contract.rent_contract_table_enddate') }}
                    </th>
                    <th
                      v-if="canConnectToPortfolio == false || (canConnectToPortfolio == true && isConnectedToPortfolio == false)"
                      class="column"
                    >
                      {{ $t('Area') }}
                    </th>
                    <th
                      v-if="(canConnectToPortfolio == true && isConnectedToPortfolio == true)"
                      class="column"
                    >
                      {{ $t('Space area') }}
                    </th>
                    <th class="column">
                      {{ $t('create_rent_contract.rent_contract_table_area') }}
                    </th>
                    <th
                      v-if="costCenters && costCenters.length > 0"
                      class="column"
                    >
                      {{ $t("Cost center") }}
                    </th>
                  </thead>
                  <tbody v-if="canConnectToPortfolio == false || (canConnectToPortfolio == true && isConnectedToPortfolio == false)">
                    <tr
                      v-for="(item, index) in filteredUnitsAndStructures"
                      :key="item.unitCode"
                      ref="units"
                    >
                      <td>
                        {{ item.site }}
                      </td>
                      <td>
                        {{ item.name }}
                      </td>
                      <td>
                        <v-menu
                          offset-y
                          min-width="0"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :rules="`validUnitStartDate: ${rentPeriodStartDate}, ${rentContractEndDate}, ${item.endDate}`"
                            >
                              <v-text-field
                                prepend-icon="calendar_today"
                                :value="formatDate(item.startDate)"
                                :label="$t('create_rent_contract.rent_contract_table_startdate')"
                                :error-messages="errors[0]"
                                v-on="on"
                              />
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="item.startDate"
                            no-title
                            scrollable
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </td>
                      <td v-if="!isNewContract || isNewContract && validityType === 'validity_fixed'">
                        <v-menu
                          offset-y
                          min-width="0"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :rules="`validUnitEndDate: ${rentPeriodStartDate}, ${rentContractEndDate}, ${item.startDate}`"
                            >
                              <v-text-field
                                prepend-icon="calendar_today"
                                :value="formatEndDate(item.endDate)"
                                :label="$t('create_rent_contract.rent_contract_table_enddate')"
                                :aria-label="$t('create_rent_contract.rent_contract_table_enddate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                                :hint="$t('date_format')"
                                clearable
                                :error-messages="errors[index]"
                                @change="handleEndDateChange(item)"
                                @blur="handleInput(item, $event)"
                                @click:clear="item.endDate = undefined"
                                v-on="on"
                              />
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="item.endDate"
                            no-title
                            scrollable
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </td>
                      <td class="col-align-right">
                        {{ getFormattedArea(Math.round(item.area * 2) / 2) }}
                      </td>
                      <td>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="`validAgreedArea: ${item.agreedArea}`"
                        >
                          <v-text-field
                            v-model="item.agreedArea"
                            type="number"
                            min="0.50"
                            :error-messages="errors[0]"
                            :suffix="$t('create_rent_contract.area_suffix')"
                            :label="$t('create_rent_contract.rent_contract_table_area')"
                            class="ma-2"
                            @change="onDataChanged()"
                          />
                        </ValidationProvider>  
                      </td>
                      <td v-if="costCenters && costCenters.length > 0">
                        <v-autocomplete
                          v-model="item.idCostcenter"
                          :items="costCenters"
                          item-text="label"
                          item-value="id"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="(canConnectToPortfolio == true && isConnectedToPortfolio == true )">
                    <tr
                      v-for="(item, index) in portfolioItems"
                      :key="index"
                    >
                      <td>
                        {{ item.Name }}
                      </td>
                      <td>
                        <v-menu
                          offset-y
                          min-width="0"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <v-text-field
                              prepend-icon="calendar_today"
                              :value="formatDate(item.Startdate)"
                              :label="$t('create_rent_contract.rent_contract_table_startdate')"
                              v-on="on"
                              @change="portfolioDataChanged()"
                            />
                          </template>
                          <v-date-picker
                            v-model="item.Startdate"
                            no-title
                            scrollable
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                            @change="portfolioDataChanged()"
                          />
                        </v-menu>
                      </td>
                      <td v-if="!isNewContract || isNewContract && validityType === 'validity_fixed'">
                        <v-menu
                          offset-y
                          min-width="0"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <v-text-field
                              prepend-icon="calendar_today"
                              :value="formatDate(item.Enddate)"
                              :label="$t('create_rent_contract.rent_contract_table_enddate')"
                              v-on="on"
                              @change="portfolioDataChanged()"
                            />
                          </template>
                          <v-date-picker
                            v-model="item.Enddate"
                            no-title
                            scrollable
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                            @change="portfolioDataChanged()"
                          />
                        </v-menu>
                      </td>
                      <td>
                        {{ Math.round(item.Area * 2) / 2 }}
                      </td>
                      <td>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="`validAgreedArea: ${item.AgreedArea}`"
                        >
                          <v-text-field
                            v-model="item.AgreedArea"
                            type="number"
                            min="0.50"
                            :error-messages="errors[0]"
                            :suffix="$t('create_rent_contract.area_suffix')"
                            :label="$t('create_rent_contract.rent_contract_table_area')"
                            class="ma-2"
                            @change="portfolioDataChanged()"
                          />
                        </ValidationProvider>  
                      </td>
                      <td v-if="costCenters && costCenters.length > 0">
                        <v-autocomplete
                          v-model="item.idCostcenter"
                          :items="costCenters"
                          item-text="label"
                          item-value="id"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--UNITS ADDITIONAL INFORMATION-->
            <v-expansion-panel
              v-if="isTemplateFieldVisible('additionalinfounits', contractTemplate)"
            >
              <v-expansion-panel-header>
                {{ $t('create_rent_contract.additionalInfoUnits') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea
                  v-model="additionalInfoUnits"
                  maxlength="1000"
                  counter="1000"
                  @change="onAdditionalInfoUnitsUpdated"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--Parking spaces-->
            <v-expansion-panel
              v-if="filteredParkingSpaces && filteredParkingSpaces.length > 0"
            >
              <v-expansion-panel-header>
                {{ $t('create_rent_contract.infoCarParks') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <table
                  class="v-data-table theme--light"
                  :style="{ width: '100%'}"
                >
                  <thead>
                    <th
                      class="column"
                    >
                      {{ $t('create_rent_contract.rent_contract_table_site') }}
                    </th>
                    <th
                      class="column"
                    >
                      {{ $t('create_rent_contract.rent_contract_table_target') }}
                    </th>
                    <th class="column">
                      {{ $t('create_rent_contract.rent_contract_table_startdate') }}
                    </th>
                    <th
                      v-if="!isNewContract || isNewContract && validityType === 'validity_fixed'"
                      class="column"
                    >
                      {{ $t('create_rent_contract.rent_contract_table_enddate') }}
                    </th>
                    <th
                      class="column"
                    >
                      {{ $t('create_rent_contract.rent_contract_amount') }}
                    </th>
                    <th class="column">
                      {{ $t('create_rent_contract.rent_contract_agreed_amount') }}
                    </th>
                    <th
                      v-if="costCenters && costCenters.length > 0"
                      class="column"
                    >
                      {{ $t("Cost center") }}
                    </th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filteredParkingSpaces"
                      :key="item.unitCode"
                      ref="parking"
                    >
                      <td>
                        {{ item.site }}
                      </td>
                      <td>
                        {{ item.name }}
                      </td>
                      <td>
                        <v-menu
                          offset-y
                          min-width="0"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :rules="`validUnitStartDate: ${rentPeriodStartDate}, ${rentContractEndDate}, ${item.endDate}`"
                            >
                              <v-text-field
                                prepend-icon="calendar_today"
                                :value="formatDate(item.startDate)"
                                :label="$t('create_rent_contract.rent_contract_table_startdate')"
                                :error-messages="errors[0]"
                                v-on="on"
                              />
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="item.startDate"
                            no-title
                            scrollable
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </td>
                      <td v-if="!isNewContract || isNewContract && validityType === 'validity_fixed'">
                        <v-menu
                          offset-y
                          min-width="0"
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on }">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :rules="`validUnitEndDate: ${rentPeriodStartDate}, ${rentContractEndDate}, ${item.startDate}`"
                            >
                              <v-text-field
                                prepend-icon="calendar_today"
                                :value="formatEndDate(item.endDate)"
                                :label="$t('create_rent_contract.rent_contract_table_enddate')"
                                :aria-label="$t('create_rent_contract.rent_contract_table_enddate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                                :hint="$t('date_format')"
                                clearable
                                :error-messages="errors[index]"
                                @change="handleEndDateChange(item)"
                                @blur="handleInput(item, $event)"
                                @click:clear="item.endDate = undefined"
                                v-on="on"
                              />
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="item.endDate"
                            no-title
                            scrollable
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </td>
                      <td class="col-align-right">
                        {{ item.amount }}
                      </td>
                      <td>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="`validAgreedAmount: ${item.agreedAmount}`"
                        >
                          <v-text-field
                            v-model.number="item.agreedAmount"
                            type="number"
                            min="1"
                            :error-messages="errors[0]"
                            :suffix="$t('pcs')"
                            :label="$t('create_rent_contract.rent_contract_agreed_amount')"
                            class="ma-2"
                            @change="onDataChanged()"
                          />
                        </ValidationProvider>  
                      </td>
                      <td v-if="costCenters && costCenters.length > 0">
                        <v-autocomplete
                          v-model="item.idCostcenter"
                          :items="costCenters"
                          item-text="label"
                          item-value="id"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--PARKING SPACES ADDITIONAL INFORMATION-->
            <v-expansion-panel
              v-if="filteredParkingSpaces && filteredParkingSpaces.length > 0 && isTemplateFieldVisible('additionalinfoparkingspaces', contractTemplate)"
            >
              <v-expansion-panel-header>
                {{ $t('create_rent_contract.additionalInfoCarParks') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea
                  v-model="additionalInfoParking"
                  maxlength="1000"
                  counter="1000"
                  @change="onAdditionalInfoParkingUpdated"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </ValidationObserver>
      </v-form>
    </v-tab-item>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { i18n } from '../../../../plugins/i18n'
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import helpers from './../../../../helpers'

extend('validUnitStartDate', {
  message: i18n.t("create_rent_contract.invalid_start_date"),
  params: ['rentPeriodStartDate', 'rentContractEndDate', 'selectedEndDate'],
  validate: (value, { rentPeriodStartDate, rentContractEndDate, selectedEndDate }) => {

    // Permanent contracts do not have an end date
    const endDateCheck = Date.parse(rentContractEndDate.trim())
    if (isNaN(endDateCheck)) {
      rentContractEndDate = "2999-12-31"
    }
    const selectedEndDateCheck = Date.parse(selectedEndDate.trim())
    if (isNaN(selectedEndDateCheck)) {
      selectedEndDate = "2999-12-31"
    }

    let date = moment(value, "DD.MM.YYYY")
    date = date.isValid() ? date.format("YYYY-MM-DD") : undefined
    let startDate = moment(rentPeriodStartDate, "YYYY-MM-DD")
    startDate = startDate.isValid() ? startDate.format("YYYY-MM-DD") : undefined
    let endDate = moment(rentContractEndDate, "YYYY-MM-DD")
    endDate = endDate.isValid() ? endDate.format("YYYY-MM-DD") : undefined
    let selEndDate = moment(selectedEndDate, "YYYY-MM-DD")
    selEndDate = selEndDate.isValid() ? selEndDate.format("YYYY-MM-DD") : undefined

    return date >= startDate && date <= endDate && date <= selEndDate
  }
});

extend('validUnitEndDate', {
  message: i18n.t("create_rent_contract.invalid_start_date"),
  params: ['rentPeriodStartDate', 'rentContractEndDate', 'selectedStartDate'],
  validate: (value, { rentPeriodStartDate, rentContractEndDate, selectedStartDate }) => {

    // Permanent contracts do not have an end date
    const endDateCheck = Date.parse(rentContractEndDate.trim())
    if (isNaN(endDateCheck)) {
      rentContractEndDate = "2999-12-31"
    }

    let date = moment(value, "DD.MM.YYYY")
    date = date.isValid() ? date.format("YYYY-MM-DD") : undefined
    let startDate = moment(rentPeriodStartDate, "YYYY-MM-DD")
    startDate = startDate.isValid() ? startDate.format("YYYY-MM-DD") : undefined
    let endDate = moment(rentContractEndDate, "YYYY-MM-DD")
    endDate = endDate.isValid() ? endDate.format("YYYY-MM-DD") : undefined
    let selStartDate = moment(selectedStartDate, "YYYY-MM-DD")
    selStartDate = selStartDate.isValid() ? selStartDate.format("YYYY-MM-DD") : undefined

    return date >= startDate && date <= endDate && date >= selStartDate
  }
});

extend('validAgreedArea', {
  message: i18n.t("create_rent_contract.invalid_agreedArea"),
  params: ['agreedArea'],
  validate: (_, { agreedArea }) => {
    return agreedArea > 0
  }
});
extend('validAgreedAmount', {
  message: i18n.t("create_rent_contract.invalid_agreedAmount"),
  params: ['agreedAmount'],
  validate: (_, { agreedAmount }) => {
    return agreedAmount > 0
  }
});
export default {
  name: "RentContractUnitDetailsTab",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    selectedTargets: {
      type: Array,
      default: null
    },
    rentPeriodStartDate: {
      type: String,
      default: null
    },
    rentContractEndDate: {
      type: String,
      default: null
    },
    isActive: {
      type: Boolean,
      default: () => false
    },
    isNewContract: {
      type: Boolean,
      default: () => false
    },
    validityType: {
      type: String,
      default: null
    },
    portfolio: {
      type: Object,
      default: null
    },
    settings: {
      type: Object,
      default: () => undefined
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    isConnectedToPortfolio: {
      type: Boolean,
      default: () => false
    },
    tenant: {
      type: Object,
      default: null
    },
    additionalInfo: {
      type: Object,
      default: () => {}
    },
    contractTemplate: {
      type: Object,
      default: undefined
    },
  },
  data () {
    return (
      {
        panels: [0, 2, 3, 4, 5],
        siteFilter: "",
        isPageValid: false,
        groupStartDate: undefined,
        groupEndDate: undefined,
        groupUnitArea: undefined,
        portfolioItems: [],
        canConnectToPortfolio: undefined,
        groupStartDateFormatted: undefined,
        groupEndDateFormatted: undefined,
        additionalInfoUnits: '',
        additionalInfoParking: '',
      }
    )
  },
  computed: {
    ...mapState('app', ['sites', 'userInfo']),
    ...mapGetters('costCenters', ['getCostCentersByOrganizationCustomer']),
    ...mapGetters('rentContracts', ['isTemplateFieldVisible']),
    filteredUnitsAndStructures () {
      const selectedUnitsAndStructures = this.selectedTargets?.filter(u => u.unitId || u.structureId)
      if (this.selectedTargets) {
        selectedUnitsAndStructures.map(unit => {
          if (unit.area == unit.agreedArea && this.isNewContract == true) {
            unit.agreedArea = Math.round(unit.agreedArea * 2) / 2;
          }
        });
      }
      
      if (!this.siteFilter || this.siteFilter.length === 0) {
        return selectedUnitsAndStructures
      }

      return selectedUnitsAndStructures.filter(
        u => !!u.site && u.site.toLowerCase().includes(this.siteFilter.toLowerCase())
      )
    },
    filteredParkingSpaces () {
      const selectedParkingSpaces = this.selectedTargets?.filter(u => u.parkingSpaceId)
      selectedParkingSpaces?.map(p => {
        p.name = `${p.unitCode}, ${p.rentalTypeName}`
      })

      if (!this.siteFilter || this.siteFilter.length === 0) {
        return selectedParkingSpaces
      }

      return selectedParkingSpaces.filter(
        u => !!u.site && u.site.toLowerCase().includes(this.siteFilter.toLowerCase())
      )
    },
    filteredTargets () {
      return [...this.filteredUnitsAndStructures, ...this.filteredParkingSpaces]
    },
    costCenters () {

      if(!this.tenant){
        return []
      }

      const { id_costcenter } = this.tenant

      const costCenters = this.getCostCentersByOrganizationCustomer(id_costcenter)

      return costCenters.map(center => {
        
        const { code, name } = center
        
        return {
          ...center,
          label: `${code} - ${name}`
        }
      })
    }
  },
  watch: {
    async selectedTargets () {
      this.$emit('updated', this.selectedTargets)
    },
    async additionalInfo () {
      this.additionalInfoUnits = this.additionalInfo.units
      this.additionalInfoParking = this.additionalInfo.parking
    },
    async isActive () {
      if (this.$refs.observer) {
        this.isPageValid = await this.$refs.observer.validate()
        this.$emit("isPageValidChanged", this.isPageValid)
      }
    },
    groupStartDate () {
      this.groupStartDateFormatted = this.formatDate(this.groupStartDate)
    },
    groupEndDate () {
      this.groupEndDateFormatted = this.formatDate(this.groupEndDate)
    },
    portfolio: function (portfolio) {
      this.portfolioItems.splice(0, this.portfolioItems.length);
      if (Object.keys(portfolio).length > 0) {
        this.portfolioItems.push(portfolio);
      }
    },
    portfolioItems () {
      this.$emit('portfolioDataChanged', this.portfolioItems)
    },
    settings: function (newVal) {
      if (this.settings.length === 0) {
        this.canConnectToPortfolio = false;
      } else {
        this.canConnectToPortfolio = this.isOutRent == true
        ? this.settings.outrentCanConnectContractToPortfolio 
        : this.settings.inrentCanConnectContractToPortfolio;
      }
    }
  },
  methods: {
    updateStartDates () {
      const updatedUnits = this.selectedTargets.map(unit => {
        unit.startDate = this.groupStartDate
        return unit
      })
      this.$emit('updated', updatedUnits)
    },
    updateEndDates () {
      const updatedUnits = this.selectedTargets.map(unit => {
        unit.endDate = this.groupEndDate
        return unit
      })
      this.$emit('updated', updatedUnits)
    },
    updateUnitAreas () {
      const updatedUnits = this.selectedTargets.map(unit => {
        unit.agreedArea = this.groupUnitArea
        return unit
      })
      this.$emit('updated', updatedUnits)
    },
    async getUnitAreaByDate (unitId, time) {
      const dateTime = new Date(time)
      const unit = await this.$rambollfmapi.units.get(unitId, dateTime)
      return unit.area
    },
    onDataChanged () {
      this.$emit('updated', this.selectedTargets)
    },
    async onStartDateChanged (item) {
      item.area = await this.getUnitAreaByDate(item.id, item.startDate)
      this.$emit('updated', this.selectedTargets)
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    formatEndDate (date) {
      if(date === null || date === undefined){
        return ''
      }if(date !== null || date !== undefined){
        const formatted = moment(date, "YYYY-MM-DD")
        return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
      }
    },
    portfolioDataChanged () {
      this.$emit('portfolioDataChanged', this.portfolioItems)
    },
    getFormattedArea (area) {
      return helpers.format.formatData(area, 'Area')
    },
    handleEndDateChange (item) {
      const foundIndex = this.filteredTargets.map(t => t.unitCode).indexOf(item.unitCode)
      const newEndDate = this.filteredTargets[foundIndex].endDate
      if (newEndDate === null || newEndDate === '') {
        this.filteredTargets[foundIndex].endDate = ''
      }
      this.onDataChanged()
    },
    handleInput (item, event) {
      const foundIndex = this.filteredTargets.map(t => t.unitCode).indexOf(item.unitCode)
      const newValue = this.parseDate(event.target.value);
      this.filteredTargets[foundIndex].endDate = newValue;
     },
    onAdditionalInfoUnitsUpdated (value) {
      this.additionalInfoUnits = value
      this.$emit('additionalInfoUpdated', this.additionalInfoUnits, this.additionalInfoParking)
    },
    onAdditionalInfoParkingUpdated (value) {
      this.additionalInfoParking = value
      this.$emit('additionalInfoUpdated', this.additionalInfoUnits, this.additionalInfoParking)
    },
  }
}
</script>

<style scoped>
</style>