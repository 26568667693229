<template>
  <div>
    <!-- Proper page -->
    <v-toolbar
      flat
      tile
    >
      <v-toolbar-title>
        <h1>{{ $t('Cost centers') }}</h1>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items v-if="hasApplicationPermissionByName('SIS_KUSTPAIK_MUOKKAUS')">
        <v-btn
          icon
          @click="assignNew"
        >
          <v-icon>add</v-icon>
          <span class="d-sr-only">{{ $t('AddCostCenter') }}</span>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-container fluid>
      <v-row
        v-if="!loadedHierarchy"
        justify="center"
        no-gutters
      >
        <v-progress-circular
          size="64"
          indeterminate
          color="primary"
        />
      </v-row>
      <v-row
        v-else
        no-gutters
        class="px-4"
      >
        <v-col
          lg="4"
          cols="12"
        >
          <v-navigation-drawer
            permanent
            class="navigation-drawer"
            floating
          >
            <v-toolbar
              flat
              tile
              height="100"
            >
              <v-row>
                <v-col>
                  <v-select
                    v-model="visibleLevel"
                    :label="$t('Top level shown')"
                    :items="levelItems"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="filter"
                    :label="$t('Search...')"
                  />
                </v-col>
              </v-row>
              <v-spacer />
            </v-toolbar>
            <v-treeview
              :items="getItemsForLevel(visibleLevel)"
              :search="filter"
            >
              <template
                slot="label"
                slot-scope="item"
              >
                <v-btn
                  text
                  @click="itemSelected(item.item)"
                >
                  {{ item.item.name }}
                </v-btn>
              </template>
            </v-treeview>
          </v-navigation-drawer>
        </v-col>
        <v-col
          lg="8"
          cols="12"
        >
          <v-row
            v-if="itemCopy && !creatingNew && hasApplicationPermissionByName('SIS_KUSTPAIK_MUOKKAUS')"
            justify="end"
            no-gutters
          >
            <v-btn
              outlined
              icon
              :class="{selected: editing}"
              @click="toggleEditing()"
            >
              <v-icon>edit</v-icon>
              <span class="d-sr-only">{{ $t('Modify') }}</span>
            </v-btn>
          </v-row>
          <v-expansion-panels
            v-if="itemCopy"
            :value="[0]"
            multiple
          >
            <v-expansion-panel
              v-if="itemCopy"
            >
              <v-expansion-panel-header>
                <v-row
                  align="center"
                  justify="start"
                  no-gutters
                >
                  <v-icon class="mr-1">
                    business
                  </v-icon>
                  <template v-if="creatingNew">
                    {{ $t("Create new") }}
                  </template>
                  <template
                    v-else
                  >
                    {{ getHierarchyLabel(itemCopy.level) }}: {{ itemCopy.code }} - {{ itemCopy.name }}
                  </template>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list
                  dense
                  class="info-box"
                  :disabled="creatingNew && disableList()"
                >
                  <v-list-item
                    v-for="header in currentHeaders"
                    :key="header.value"
                    class="tile"
                  >
                    <v-list-item-content class="tile-content">
                      {{ getHeader(header) }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <span v-if="!header.edit && header.value !== 'vacant'">{{ getValue(header.value, itemCopy[header.value]) }}</span>
                      <v-select
                        v-else-if="header.value === 'level'"
                        v-model="itemCopy[header.value]"
                        :label="getHeader(header)"
                        :items="levelItems"
                        class="borderless"
                        append-outer-icon="edit"
                        item-text="name"
                        item-value="id"
                      />
                      <v-select
                        v-else-if="header.value === 'id_parent'"
                        v-model="itemCopy[header.value]"
                        :items="getItemsForLevel(itemCopy.level - 1)"
                        :label="getHeader(header)"
                        class="borderless"
                        append-outer-icon="edit"
                        item-text="name"
                        item-value="id"
                      />
                      <v-select
                        v-else-if="header.value === 'allocation_rule'"
                        v-model="itemCopy[header.value]"
                        :items="allocationRules"
                        :label="getHeader(header)"
                        class="borderless"
                        append-outer-icon="edit"
                      />
                      <v-checkbox
                        v-else-if="header.value === 'vacant'" 
                        v-model="itemCopy[header.value]"
                        :label="getHeader(header)"
                        class="shrink mr-0 mt-0"
                        hide-details
                        :disabled="!editing"
                      />
                      <v-text-field
                        v-else
                        v-model="itemCopy[header.value]"
                        :label="getHeader(header)"
                        small
                        class="borderless"
                        append-outer-icon="edit"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row
            justify="end"
            style="right: 0; bottom: 0; position: sticky"
          >
            <v-btn
              v-if="editing"
              rounded
              :disabled="patch.length === 0 || isSaving"
              class="primary"
              @click="saveChanges"
            >
              {{ $t('Save changes') }}
              <v-progress-circular
                v-if="isSaving"
                size="16"
                indeterminate
                color="primary"
              />
            </v-btn>
            <template v-else-if="creatingNew">
              <v-chip
                v-if="message"
                color="error"
              >
                {{ $t(message) }}
              </v-chip>
              <v-btn
                :disabled="!valid"
                rounded
                class="primary"
                @click="createNew"
              >
                {{ $t('Create new') }}
                <v-progress-circular
                  v-if="isSaving"
                  size="16"
                  indeterminate
                  color="primary"
                />
              </v-btn>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CostCenters',
  metaInfo () {
    return {
      title: `${this.$t('Cost centers')} · ${this.$t('Settings')} ·`,
    }
  },
  data () {
    return {
      levelItems: [
        { id: 1, name: this.$t('costcenters.organization'), code: 'customer_code' },
        { id: 2, name: this.$t('costcenters.company'), code: 'organization_code' },
        { id: 3, name: this.$t('costcenters.business_unit'), code: 'business_unit_code' },
        { id: 4, name: this.$t('costcenters.domain'), code: 'domain_code' },
        { id: 5, name: this.$t('costcenters.cost_center'), code: 'cost_center_identifier' }
      ],
      levels: [
        'undefined',
        'organizationCustomers',
        'organizations',
        'businessUnits',
        'domains',
        'costCenters'
      ],
      organizationCustomers: [],
      organizations: [],
      businessUnits: [],
      domains: [],
      headers: [
        {
          label: 'Type',
          value: 'level',
          edit: false,
          ccOnly: false
        },
        {
          label: 'Parent',
          value: 'id_parent',
          edit: true,
          ccOnly: false
        },
        {
          label: 'Identifier',
          value: 'code',
          edit: false,
          ccOnly: false
        },
        {
          label: 'Name',
          value: 'name',
          edit: true,
          ccOnly: false
        },
        {
          label: 'Comments',
          value: 'comments',
          edit: true,
          ccOnly: false
        },
        {
          label: 'Special handling',
          value: 'allocation_rule',
          edit: true,
          ccOnly: true
        },
        {
          label: 'Charge account',
          value: 'charge_account',
          edit: true,
          ccOnly: true
        },
        {
          label: 'Charge project',
          value: 'charge_project',
          edit: true,
          ccOnly: true
        },
        {
          label: 'Charge costcenter',
          value: 'charge_costcenter',
          edit: true,
          ccOnly: true
        },
        {
          label: 'Revenue account',
          value: 'revenue_account',
          edit: true,
          ccOnly: true
        },
        {
          label: 'Revenue project',
          value: 'revenue_project',
          edit: true,
          ccOnly: true
        },
        {
          label: 'Revenue costcenter',
          value: 'revenue_costcenter',
          edit: true,
          ccOnly: true
        },
        {
          label: 'VAT percentage',
          value: 'vat_percentage',
          edit: true,
          ccOnly: true
        },
        {
          label: 'VAT charge account',
          value: 'vat_charge_account',
          edit: true,
          ccOnly: true
        },
        {
          label: 'VAT charge code',
          value: 'vat_charge_code',
          edit: true,
          ccOnly: true
        },
        {
          label: 'VAT refund account',
          value: 'vat_refund_account',
          edit: true,
          ccOnly: true
        },
        {
          label: 'VAT refund code',
          value: 'vat_refund_code',
          edit: true,
          ccOnly: true
        },
        {
          label: 'Differentation code',
          value: 'differentation_code',
          edit: true,
          ccOnly: true
        },
        {
          label: 'Vacant cost center',
          value: 'vacant',
          edit: true,
          ccOnly: true
        }
      ],
      allocationRules: [
        {
          text: this.$t('No special handling'),
          value: 'None'
        },
        {
          text: this.$t('internalrent.allocated_for_spaces'),
          value: 'AllocateToSpaces'
        },
        {
          text: this.$t('internalrent.zero_allocation'),
          value: 'ZeroAllocation'
        },
        {
          text: this.$t('internalrent.ignore'),
          value: 'ZeroExpense'
        }
      ],
      costCenters: [],
      items: [],
      visibleLevel: 3,
      loadedHierarchy: false,
      selectedItem: null,
      valid: false,
      message: '',
      editing: false,
      creatingNew: false,
      itemCopy: null,
      itemObserver: null,
      isSaving: false,
      patch: [],
      filter: '',
      userOrgs: []
    }
  },
  computed: {
    ...mapGetters('app',['hasApplicationPermissionByName']),
    currentHeaders () {
      let headers = JSON.parse(JSON.stringify(this.headers))
      if (this.itemCopy.level === 1) {
        headers = headers.filter(x => x.value !== 'id_parent')
      }
      if (this.itemCopy.level !== 5) {
        headers = headers.filter(x => !x.ccOnly)
      }
      if (this.creatingNew) {
        headers = headers.map(x => {
          x.edit = true
          return x
        })
      } else if (!this.editing) {
        headers = headers.map(x => {
          x.edit = false
          return x
        })
      }
      return headers
    },
    isSuperAdmin () {
      return this.$store.state.app.superAdmin
    }
  },
  watch: {
    itemCopy: {
      deep: true,
      handler: function () {
        this.valid = false
        if (this.editing) {
          this.patch = this.$jsonpatch.generate(this.itemObserver)
        } else if (this.itemCopy && this.creatingNew) {
          this.valid = true

          // Check if required fields are filled
          if (this.itemCopy.type === null) {
            this.valid = false
          } else if (this.itemCopy.type > 1 && this.itemCopy.parent === null) {
            this.valid = false
          }
          if (this.itemCopy.code === null || this.itemCopy.code === '') {
            this.valid = false
          }
          if (this.itemCopy.name === null || this.itemCopy.name === '') {
            this.valid = false
          }

          // Test if the code given is a duplicate
          if (this.itemCopy.level) {
            const duplicate = this[this.levels[this.itemCopy.level]].find(o => {
              return o.code === this.itemCopy.code
            })
            if (duplicate) {
              this.valid = false
              this.message = 'Same code is already in use'
            } else {
              this.message = ''
            }
          }
        }
      }
    },
    selectedItem: {
      handler (va) {
        this.itemCopy = JSON.parse(JSON.stringify(this.selectedItem))
        this.itemObserver = this.$jsonpatch.observe(this.itemCopy)
      }
    }
  },
  async mounted () {
    this.loadedHierarchy = false

    // Fetch all hierarchy levels. Start from 1 since 0 is undefined
    const asyncRequests = []
    for (let i = 1; i < this.levels.length; ++i) {
      const levelName = this.levels[i]
      asyncRequests.push(
        this.fetchHierarchyLevel(levelName).then(res => {
          this[levelName] = res
        })
      )
    }
    asyncRequests.push(
      this.$rambollfmapi.userOrganizations.getExternal().then(res => {
        this.userOrgs = res
      })
    )
    await Promise.all(asyncRequests)
    this.loadedHierarchy = true
    this.createTree()
  },
  methods: {
    async saveChanges () {
      this.isSaving = true
      const properPatch = this.$jsonpatch.compare(
        this.selectedItem,
        this.itemCopy
      )
      try {
        await this.$rambollfmapi.organizations.patch(
          this.selectedItem.id,
          properPatch
        )
        this.$jsonpatch.applyPatch(this.selectedItem, properPatch)
        this.editing = false
        this.createTree()
      } catch (exception) {
        this.$log.error(exception)
      }
      this.isSaving = false
    },
    toggleEditing () {
      this.editing = !this.editing
      this.itemCopy = JSON.parse(JSON.stringify(this.selectedItem))
      this.itemObserver = this.$jsonpatch.observe(this.itemCopy)
    },
    assignNew () {
      this.editing = false
      this.creatingNew = true
      this.itemCopy = {
        id: 0,
        level: this.visibleLevel,
        id_parent: 0,
        code: '',
        name: '',
        allocation_rule: 'None'
      }
    },
    disableList () {
      // Edited conditon - now, if user has external orgs...
      if (this.userOrgs.length > 0) {
        // ... and user isn't superAdmin and level is the top one (1)
        // return true and disable list
        if (!this.isSuperAdmin && this.visibleLevel === 1) {
          return true
        }
      }
      return false
    },
    getHeader (header) {
      if (header.value === 'id_parent') {
        return this.getHierarchyLabel(this.itemCopy.level - 1)
      }
      return this.$t(header.label)
    },
    getValue (header, exampleValue) {
      if (header === 'allocation_rule') {
        const found = this.allocationRules.find(x => x.value === exampleValue)
        return found ? found.text : ''
      } else if (header === 'level') {
        return this.getHierarchyLabel(exampleValue)
      } else if (header === 'id_parent') {
        return this.getItemLabel(this.itemCopy.level - 1, exampleValue)
      }
      return exampleValue
    },
    async createNew () {
      this.isSaving = true
      const levelName = this.levels[this.itemCopy.level]
      try {
        await this.$rambollfmapi.organizations.post(this.itemCopy)
        await this.fetchHierarchyLevel(levelName).then(res => {
          this[levelName] = res
        })
        this.itemCopy = null
        this.creatingNew = false
        this.createTree()
      } catch (exception) {
        this.$log.error(exception)
      }
      this.isSaving = false
    },
    itemSelected (item) {
      this.editing = false
      this.creatingNew = false
      this.selectedItem = this[this.levels[item.level]].find(obj => {
        return obj.id === item.id
      })
    },
    getItemLabel (itemLevel, itemId) {
      const found = this[this.levels[itemLevel]].find(obj => {
        return obj.id === itemId
      })
      if (found) {
        return found.code + ' - ' + found.name
      }
      return ''
    },
    getItemsForLevel (n) {
      return this.getRecursiveItems(this.items, n, 1)
        .slice()
        .sort((a, b) => (a.name < b.name ? -1 : 1))
    },
    getRecursiveItems (items, level, n) {
      if (n === level) {
        return items
      } else {
        let itemArray = []
        items.forEach(item => {
          itemArray = itemArray.concat(item.children)
        })
        return this.getRecursiveItems(itemArray, level, n + 1)
      }
    },
    createRecursiveItems (items, parent, limit, n) {
      this[this.levels[n]]
        .filter(child => {
          return parent === null || child.id_parent === parent
        })
        .forEach(obj => {
          const children = []
          if (n < limit) {
            this.createRecursiveItems(children, obj.id, limit, n + 1)
          }
          items.push({
            id: obj.id,
            level: n,
            name: obj.name + '  (' + obj.code + ')',
            children: children
          })
          return items
        })
    },
    getHierarchyLabel (n) {
      return this.levelItems.find(item => {
        return item.id === n
      }).name
    },
    createTree () {
      this.items = []
      this.createRecursiveItems(this.items, null, 5, 1)
    },
    async fetchHierarchyLevel (levelName) {
      return this.$rambollfmapi.organizations[levelName]().list()
    }
  }
}
</script>
<style scoped>
.padding {
  padding: 1em;
}
.navigation-drawer {
  width: auto !important;
}
</style>
