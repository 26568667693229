<template>
  <v-container fluid>
    <v-row
      class="dashboard"
    >
      <v-col
        v-show="smallWidgets.length > 0"
        xl="5"
        lg="5"
        md="5"
        sm="12"
        cols="12"
      >
        <!-- widgets -->
        <v-row no-gutters>
          <v-col
            v-for="widget in smallWidgets"
            :key="widget.id"
            cols="12"
          >
            <DynamicWidget
              :type="widget.type"
              :data="widget.data"
              :title="$t(widget.id)"
              :loading="widget.loading"
            />
          </v-col>
        </v-row>
      </v-col>
      <!-- right -->
      <v-col
        v-show="largeWidgets.length > 0"
        xl="7"
        lg="7"
        md="7"
        sm="12"
        cols="12"
      >
        <v-row no-gutters>
          <v-col
            v-for="widget in largeWidgets"
            :key="widget.id"
            cols="12"
          >
            <DynamicWidget
              :type="widget.type"
              :data="widget.data"
              :title="$t(widget.id)"
              :loading="widget.loading"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-show="timeLineChart"
        cols="12"
      >
        <DynamicWidget
          :type="timeLineChart.type"
          :data="timeLineChart.data"
          :title="$t(timeLineChart.id)"
          :loading="timeLineChart.loading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DynamicWidget from '../DynamicWidget'
import { mapGetters, mapState } from 'vuex'
import helpers from '../../helpers'

export default {
  name: 'Dashboard',
  components: {
    DynamicWidget
  },
  data () {
    return {
      draggableColor: null,
      smallWidgets: [{
        id: 'longtermplanning.yearly_tasks',
        type: 'VerticalBarDataGraph',
        data: {
          items: [],
          source: 'yearlyTasks',
          field: 'use',
          label: this.$t('Budgets total'),
          xLabel: this.$t('Year').toUpperCase(),
          yLabel: this.$t('€')
        },
        small: true,
        loading: true
      },
      {
        id: 'longtermplanning.completion_status',
        type: 'PieDataGraph',
        data: {
          field: 'status',
          items: [],
          source: 'completionStatus',
          label: 'Completion Status'
        },
        small: true,
        loading: true
      }],
      largeWidgets: [{
        id: 'longtermplanning.chosen_buildings',
        type: 'DataTable',
        loading: true,
        data: {
          headers: [
            { text: this.$t('Site name'), value: 'site_name' },
            { text: this.$t('Building name'), value: 'building_name' },
            { text: this.$t('Net floor area'), value: 'net_floor_area', format: 'Area' },
            { text: this.$t('City'), value: 'city' },
            { text: this.$t('Total budget'), value: 'budget', format: 'Number' },
            { text: this.$t('Currency'), value: 'currency'},
          ],
          footers: [
            { text: this.$t('Total') },
            {},
            { value: 'net_floor_area', format: 'Area' },
            {},
            { value: 'budget', format: 'Number' },
            {},
          ],
          items: [],
          source: 'chosenBuildings',
          sortBy: 'name',
          sortDesc: true,
          rowsPerPage: 9,
          browseRowsPerPage: 100
        }
      },
      {
        id: 'longtermplanning.current_year_tasks',
        type: 'DataTable',
        loading: true,
        data: {
          headers: [
            { text: this.$t('Building code'), value: 'building_code' },
            { text: this.$t('Building name'), value: 'building_name' },
            { text: this.$t('Code'), value: 'taskCode' },
            { text: this.$t('Task name'), value: 'taskName' },
            { text: this.$t('Time'), value: 'time', format: 'Time' },
            { text: this.$t('Task guide'), value: 'task_description' }
          ],
          items: [],
          source: 'yearTasks',
          sortDesc: false,
          rowsPerPage: 9,
          browseRowsPerPage: 100
        }
      }],
      allWidgets: [],
      timeLineChart: {
        id: 'longtermplanning.task_schedule',
        type: 'TimeLineGraph',
        data: {
          items: [],
          source: 'schedule',
          field: 'classification_string',
          label: this.$t('Months'),
          xLabel: this.$t('Month').toUpperCase(),
          yLabel: this.$t('Task').toUpperCase(),
          tasks: []
        },
        small: false,
        loading: true
      }
    }
  },
  computed: {
    ...mapState('app', ['buildings']),
    ...mapState('ltp', ['yearRange', 'selectedYear', 'searchedTasks', 'loadingStatus', 'timelineTasks', 'statusFilter']),
    ...mapGetters('ltp', ['getSearchedTasks', 'getCurrentYearTasks'])
  },
  watch: {
    loadingStatus: function () {
      if (!this.loadingStatus) {
        this.setData()
      }
    }
  },
  async mounted () {
    if (!this.loadingStatus) {
      this.setData()
    }
  },
  methods: {
    dataForCompletionStatus () {
      // calculate completion status for searched tasks
      const pieData = []
      if (!this.loadingStatus) {
        this.getCurrentYearTasks.forEach(task => {
          pieData.push({ status: this.$t(helpers.ltpConstants.statusMappings()[task.implementationStatus]) })
        })
      }
      this.smallWidgets[1].data.items = pieData
      this.smallWidgets[1].loading = this.loadingStatus
    },
    dataForChosenBuildings () {
      // calculate data for Chosen Buildings
      const buildingTasks = []

      if (!this.loadingStatus) {
        this.getSearchedTasks.forEach(task => {
          const taskIndex = buildingTasks.findIndex(item => item.building_code === task.buildingId)
          if (taskIndex > -1) {
          // update earlier data
            buildingTasks[taskIndex].budget += task.budget

            const year = new Date(task.implementationStart).getFullYear() - this.selectedYear
            buildingTasks[taskIndex][`year${year}_budget`] += task.budget
          } else {
          // build new data
            const data = {}
            data.building_code = task.buildingId
            const building = this.buildings.find(building => building.building_code === task.buildingId)
            if (building) {
              data.id_site = building.id_site
              data.site_name = this.$store.state.app.sites.find(s => s.id_site === building.id_site).name
              data.building_name = building.building_name
              data.net_floor_area = building.building_net_floor_area
              data.address = building.address
              data.city = building.post_office
            }
            data.budget = task.budget
            data.currency = helpers.ltpConstants.taskCurrencies().find(c => c.value === task.currencyId).text

            // calculate year fields
            for (let i = 0; i < this.yearRange; i++) {
              data[`year${i}_budget`] = ((this.selectedYear + i) === new Date(task.implementationStart).getFullYear()) ? task.budget : 0
            }
            // add new data
            buildingTasks.push(data)
          }
        })

        const headerData = []
        const footerData = []
        for (let i = 0; i < this.yearRange; i++) {
          headerData.push({ text: (this.selectedYear + i), value: `year${i}_budget` })
          footerData.push({ value: `year${i}_budget`, unit: 'budget' })
        }
        // reset headers and add new headers
        this.largeWidgets[0].data.headers = [...this.largeWidgets[0].data.headers.slice(0, 6), ...headerData]
        this.largeWidgets[0].data.footers = [...this.largeWidgets[0].data.footers.slice(0, 6), ...footerData]
      }
      this.largeWidgets[0].data.items = buildingTasks
      this.largeWidgets[0].loading = this.loadingStatus
    },
    dataForSelectedYear () {
      // calculate data for Yearly Tasks
      const yearlyTasks = []
      if (!this.loadingStatus) {
        const tasks = this.getSortedTasks()
        tasks.forEach(task => {
          const data = {}
          data.building_code = task.buildingId
          const building = this.buildings.find(building => building.building_code === task.buildingId)
          if (building) {
            data.building_name = building.building_name
          }
          data.taskCode = task.taskCode
          data.taskName = this.$t(task.taskName)

          const plannedStartMonth = new Date(task.implementationStart).getMonth() + 1
          const plannedEndMonth = new Date(task.implementationEnd).getMonth() + 1
          const startMonth = `${plannedStartMonth >= 10 ? plannedStartMonth : '0' + plannedStartMonth.toString()}`
          const endMonth = `${plannedEndMonth >= 10 ? plannedEndMonth : '0' + plannedEndMonth.toString()}`
          const startYear = new Date(task.implementationStart).getFullYear().toString().slice(2)
          const endYear = new Date(task.implementationEnd).getFullYear().toString().slice(2)
          let time = `${startMonth}-${endMonth}/${startYear}`
          if (startYear !== endYear) {
            time = `${startMonth}/${startYear}-${endMonth}/${endYear}`
          }
          data.time = time
          data.task_description = task.taskDescription
          yearlyTasks.push(data)
        })
      }
      this.largeWidgets[1].data.items = yearlyTasks
      this.largeWidgets[1].loading = this.loadingStatus
    },
    dataForTimeLine () {
      const timeLine = []
      // choose relevant tasks from earlier years and from selected year
      let tasks = []
      if (!this.loadingStatus) {
        tasks = this.getSortedTasks()
        tasks.forEach(task => {
          const plannedStart = new Date(task.implementationStart)
          const plannedEnd = new Date(task.implementationEnd)
          const startMonth = plannedStart.getFullYear() < this.selectedYear ? 0 : plannedStart.getMonth()
          const endMonth = plannedEnd.getFullYear() > this.selectedYear ? 12 : plannedEnd.getMonth() + 1
          const newItem = { label: this.$t(task.taskName), months: [startMonth, endMonth] }
          timeLine.push(newItem)
        })
      }
      this.timeLineChart.data.items = timeLine
      this.timeLineChart.data.tasks = tasks
      this.timeLineChart.loading = this.loadingStatus
    },
    dataForYearlyTasks () {
      const budget = {}
      // build budget object with selected year range
      for (let i = 0; i < this.yearRange; i++) {
        budget[`${this.selectedYear + i}`] = 0
      }
      // add budgets from selected tasks
      if (!this.loadingStatus) {
        this.getSearchedTasks.forEach(task => {
          const year = new Date(task.implementationStart).getFullYear()
          budget[year] += task.budget
        })
      }
      this.smallWidgets[0].data.items = budget

      this.smallWidgets[0].loading = this.loadingStatus
    },
    setData () {
      this.dataForYearlyTasks()
      this.dataForCompletionStatus()
      this.dataForChosenBuildings()
      this.dataForSelectedYear()
      this.dataForTimeLine()
    },
    getSortedTasks () {
      // sort tasks by starting time
      // if starting time is equal, then sort by ending time
      const tasks = [...this.timelineTasks, ...this.getCurrentYearTasks]
      return tasks.sort((a, b) => {
        const aStart = new Date(a.implementationStart).getTime()
        const aEnd = new Date(a.implementationEnd).getTime()
        const bStart = new Date(b.implementationStart).getTime()
        const bEnd = new Date(b.implementationEnd).getTime()

        if (aStart !== bStart) {
          return aStart - bStart
        } else {
          return aEnd - bEnd
        }
      })
    }
  }

}
</script>

<style>
.dashboard .col {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
}
.rounded {
  border-radius: 2em !important;
}
</style>
