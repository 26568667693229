/* 
[group]: {
  [col]: {
    [set]: {
      [row]: {

      }
    }
  }
}
*/

export const hiddenCells = {
  rentalType: {
    confirmed: {
      allRenegotiations: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      renegotiations: {
        ending_fixed: true,
      },
      renewals: {
        terminated_permanent: true,
      },
    },
    target: {
      all: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      allRenegotiations: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      renegotiations: {
        ending_fixed: true,
      },
      renewals: {
        terminated_permanent: true,
      },
    },
    prediction: {
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
    },
    currentFree: {
      all: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
        renegotiations: true,
        renewals: true,
      },
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
    },
  },
  area: {
    confirmed: {
      allRenegotiations: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      renegotiations: {
        ending_fixed: true,
      },
      renewals: {
        terminated_permanent: true,
      },
    },
    target: {
      all: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      allRenegotiations: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      renegotiations: {
        ending_fixed: true,
      },
      renewals: {
        terminated_permanent: true,
      },
    },
    prediction: {
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
    },
    currentFree: {
      all: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
    },
  },
  marketRent: {
    confirmed: {
      allRenegotiations: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      renegotiations: {
        ending_fixed: true,
      },
      renewals: {
        terminated_permanent: true,
      },
    },
    target: {
      all: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
        
      },
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      allRenegotiations: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      renegotiations: {
        ending_fixed: true,
      },
      renewals: {
        terminated_permanent: true,
      },
    },
    prediction: {
      all: {
        others: true,
        total: true,
      },
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      allRenegotiations: {
        others: true,
        total: true,
      },
      renegotiations: {
        others: true,
        total: true,
      },
      renewals: {
        others: true,
        total: true,
      },
    },
    currentFree: {
      all: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
      new: {
        ending_fixed: true,
        terminated_permanent: true,
        ending_total: true,
      },
    },
  },
}

export const areaLinkedCells = {
  all: {
    confirmed: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      6: true,
      7: true,
    },
    prediction: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      6: true,
      7: true,
    },
    currentFree: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    },
    target: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    }
  },
  new: {
    confirmed: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      6: true,
      7: true,
    },
    prediction: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    },
    currentFree: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    },
  },
  allRenegotiations: {
    confirmed: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    },
    prediction: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      6: true,
      7: true,
    },
  },
  renegotiations: {
    confirmed: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    },
    prediction: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      6: true,
    },
  },
  renewals: {
    confirmed: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    },
    prediction: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      6: true,
    },
  },
}
export const rentalTypeLinkedCells = {
  all: {
    confirmed: {
      0: true,
      1: true,
      2: true,
      4: true,
      5: true,
    },
    prediction: {
      0: true,
      1: true,
      2: true,
      4: true,
      5: true,
    },
    currentFree: {
      0: true,
    },
  },
  new: {
    confirmed: {
      0: true,
      1: true,
      2: true,
    },
    prediction: {
      0: true,
    },
    currentFree: {
      0: true,
    },
  },
  allRenegotiations: {
    confirmed: {
      0: true,
      1: true,
    },
    prediction: {
      0: true,
      1: true,
      3: true,
      4: true,
    },
  },
  renegotiations: {
    confirmed: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    },
    prediction: {
      0: true,
      1: true,
      2: true,
      4: true,
    },
  },
  renewals: {
    confirmed: {
      0: true,
      1: true,
      2: true,
      4: true,
    },
    prediction: {
      0: true,
      1: true,
      2: true,
      4: true,
    },
  },
}

export const defaultGoals = {
  new_negotiation: null,
  renegotiation: null,
  renewal: null,
  usage_rate: null
}