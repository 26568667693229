import { i18n } from '../../plugins/i18n'
import humanize from '../humanize'

/**
 * Returns type info from prospect. Type info includes ui text and icon. Below are the possible types.
 * new_negotiation
 * renegotiations
 * renewal
 * real_estate_development
 * @param {Object} prospect - Prospect object
 * @returns
 */
export function getProspectType (prospect) {
  // Jatkoneuvottelu
  if (
    prospect.renegotiations &&
    prospect.rental_contract_validity === 'määräaikainen'
  ) {
    return { typeIcon: 'autorenew', typeText: 'leasing.renegotiations' }
    // Uusimisneuvottelu
  } else if (
    prospect.renegotiations &&
    prospect.rental_contract_validity === 'toistaiseksi voimassaoleva'
  ) {
    return { typeIcon: 'icecream', typeText: 'leasing.renewal' }
    // Kiinteistökehitysaihio
  } else if (prospect.real_estate_development) {
    return {
      typeIcon: 'construction',
      typeText: 'prospect.real_estate_development',
    }
  }

  // Uudisneuvottelu
  return { typeIcon: 'spa', typeText: 'leasing.new_negotiation' }
}

/**
 * Return prospect stage and date when stage last changed. Info includes ui text and date. Below are possible stages
 * Inactive (prospect status inactive)
 * Closed (prospect status closed)
 * Contract negotiations (Annex printed for prospect)
 * Offer draft (Rental offer created for prospect)
 * Created (Prospect active/awaiting allocation and no annex or offer made for prospect)
 * @param {Object} prospect - Prospect object
 * @returns
 */
export function getProspectStage (prospect, events) {
  let stage = ''
  let stageDate = new Date()

  // Find out if annex printed of offer drafted for prospect
  let printDate = new Date()
  const annexPrinted = prospect.sites.some((site) => {
    return site.units.some((unit) => {
      if (unit.annex != null) {
        printDate = unit.printCreated
        return true
      }
      return false
    })
  })
  const offerDraft = prospect.sites.some((site) => {
    return site.units.some((unit) => {
      if (unit.offer != null) {
        printDate = unit.printCreated
        return true
      }
      return false
    })
  })

  if (prospect.status === 'Inactive') {
    stage = 'Passed'
    let inactiveEvent = events.find(
      (event) => event.event_type === 'Prospekti merkitty ohi menneeksi'
    )
    if (inactiveEvent != null) {
      stageDate = inactiveEvent.modified_date
    } else {
      stageDate = prospect.last_modified
    }
  } else if (prospect.status === 'Closed') {
    stage = 'Closed'
    let closedEvent = events.find(
      (event) => event.event_type === 'Prospekti merkitty voitetuksi'
    )
    if (closedEvent != null) {
      stageDate = closedEvent.modified_date
    } else {
      stageDate = prospect.last_modified
    }
  } else if (annexPrinted) {
    stage = 'Contract negotiations'
    stageDate = printDate
  } else if (offerDraft) {
    stage = 'Offer draft'
    stageDate = printDate
  } else {
    stage = 'Created'
    stageDate = prospect.created
  }

  return { stage, stageDate }
}
/**
 * Returns likelihood column values from prospect.
 * High
 * Intermediate
 * Low
 * Odottaa kohdistusta: tähtisilmät, samalla värillä kun toinenkin vihreä
 * Aktiivinen, johon ei ole asetettu todennäköisyyttä: Harmaa pallo jossa kysymysmerkki
 * Todennäköisyys ei ole relevantti (kaikki muut status-arvot paitsi aktiivinen ja odottaa kohdistusta): Harmaa pallo
 * Voitettu: Vihreä tähti
 * @param {Object} prospect
 */
export function getProspectLikelihood (prospect) {
  if (!prospect) {
    return 'NotRelevant'
  }

  if (prospect.status === 'AwaitingAllocation') {
    return 'AwaitingAllocation'
  } else if (prospect.status === 'Active' && !prospect.success_likelihood) {
    return 'ActiveWithoutLikelihood'
  } else if (prospect.status === 'Closed') {
    return 'Won'
  } else if (
    prospect.status !== 'Active' &&
    prospect.status !== 'AwaitingAllocation'
  ) {
    return 'NotRelevant'
  }

  return prospect.success_likelihood
}
/**
 * Transforms the prospect object to object which can be shown on tables. This way we can use this functionality also in other
 * views than in prospects view.
 * @param {Object} prospect - Selected prospect
 * @param {Object[]} parties
 * @param {Object[]} municipalities 
 * @param {Object[]} sites 
 * @param {Object[]} leasingUsers 
 * @param {Object[]} events 
 * @param {Function} getDefinitionLabelById - Function which returns a definition. I know this is bit of ugly solution...
 * @returns 
 */
export function computeProspect (
  prospect,
  parties,
  municipalities,
  sites,
  leasingUsers,
  events,
  getDefinitionLabelById
) {
  // Party name
  const foundParty = parties.find((party) => party.id == prospect.id_osapuoli)

  //  Sites need to be added as subItems
  let subItems = []
  prospect.sites.forEach((item) => {
    const foundSite = sites.find(
      (sitesItem) => sitesItem.id_site == item.id_site
    )
    if (foundSite)
      subItems.push({
        site: foundSite.name,
        unit_area_preference: 0,
        id_site: item.id_site,
      })
  })
  // String of sites
  const reducedSites = subItems.reduce((acc, value, index) => {
    let next = acc
    if (index !== 0) next += ', '
    next += value.site
    return next
  }, '')

  let singleSite = ''
  let idSite = null
  if (subItems.length === 1) {
    singleSite = reducedSites
    idSite = subItems[0].id_site
  } else if (subItems.length > 1) {
    singleSite = i18n.t('prospect.several')
  }

  // String of municipalities
  const cityString = prospect.cities.reduce((acc, curr, index) => {
    const city = municipalities.find((item) => item.id === curr).municipality
    if (index !== 0) return acc + ', ' + city
    else return acc + city
  }, '')

  // Unit usage string
  let unitUsageString

  if(prospect.unit_type_preference){
    unitUsageString = prospect.unit_type_preference.reduce(
      (acc, curr, index) => {
        const usage = getDefinitionLabelById(curr)
        if (index !== 0) return acc + ', ' + usage
        else return acc + usage
      },
      ''
    )
  }
  const leadOrigin = getDefinitionLabelById(prospect.lead_origin)
  const contractType = getDefinitionLabelById(prospect.def_contract_type)
  const contractModel = getDefinitionLabelById(prospect.def_contract_model)

  // Prospect type (renegotiations/real estate development/new contract)
  const typeInfo = getProspectType(prospect)
  
  const modifiedUser = events.find(event => event.event_type === 'Päivitetty')?.user
  const createdUser = events.find(event => event.event_type === 'Luotu')?.user
  const markedToNegotiations = events.find(event => event.event_type === 'Merkitty sopimusneuvotteluihin')?.modified_date
  
  // Prospect stage and stage date
  const stageObject = getProspectStage(prospect, events)
  // If state is passed, add reason
  let stage = i18n.t(stageObject.stage)
  let stageDate = humanize.date(stageObject.stageDate)
  if (stageObject.stage === 'Passed') {
    stage =
      stage + ': ' + i18n.t(getDefinitionLabelById(prospect.status_description))
  }
  let statusDescription = ''
  if (prospect.status === 'Inactive') {
    statusDescription = i18n.t(getDefinitionLabelById(prospect.status_description))
  }
  // Prospect responsibles
  let firstResponsibleName = ''
  let secondaryResponsibleName = ''
  const firstResponsible = leasingUsers.find(
    (item) => item.id_user === prospect.owner
  )
  if (firstResponsible) {
    firstResponsibleName = firstResponsible.name
  }
  const secondaryResponsible = leasingUsers.find(
    (item) => item.id_user === prospect.secondaryOwner
  )
  if (secondaryResponsible) {
    secondaryResponsibleName = secondaryResponsible.name
  }
  const returnObject = {
    id_prospect: prospect.id_prospect,
    prospect_description: prospect.prospect_description,
    tenant: foundParty ? foundParty.name : '',
    site: singleSite,
    sitesString: '',
    unit_area_preference: prospect.unit_area_preference,
    city_preference: cityString,
    real_estate_development: prospect.real_estate_development
      ? i18n.t('Yes')
      : i18n.t('No'),
    unit_type: unitUsageString,
    prospect_status: prospect.status === 'Inactive' ? prospect.status : prospect.stage,
    prospect_fullscreen_status: i18n.t(status),
    showToggle: subItems.length > 1,
    created: prospect.created,
    last_modified: prospect.last_modified,
    prospect_type: typeInfo.typeIcon,
    prospect_type_text: i18n.t(typeInfo.typeText),
    id_site: idSite,
    stage: stage,
    stageChanged: stageDate,
    status_description: statusDescription,
    success_likelihood: getProspectLikelihood(prospect),
    rental_contract_end_date: prospect.rental_contract_end_date,
    first_responsible: firstResponsibleName,
    secondary_responsible: secondaryResponsibleName,
    estimated_completion: prospect.estimated_completion,
    estimated_value: prospect.estimated_value,
    estimated_signature_date: prospect.estimated_signature_date,
    estimated_value_impact: prospect.estimated_value_impact,
    estimated_modification_cost: prospect.estimated_modification_cost,
    maturity: prospect.maturity,
    lead_origin: leadOrigin,
    contract_type: contractType,
    contract_model: contractModel,
    // Addittional information. Required for tenant links to work correctly.
    id_tenant: foundParty ? foundParty.id : null,
    business_id: foundParty ? foundParty.business_id : null,
    tenant_corporation: foundParty ? foundParty.tenant_corporation : null,
    id_process: prospect.id_process,
    profit_analysis_done: prospect.profit_analysis_done,
    written_proposal_done: prospect.written_proposal_done,
    customer_need: prospect.customer_need,
    modified_user: modifiedUser,
    created_user: createdUser,
    marked_to_negotiations: markedToNegotiations
  }

  if (subItems.length > 1) {
    returnObject['subItems'] = subItems
    returnObject['sitesString'] = reducedSites
  } else {
    returnObject['sitesString'] = reducedSites
  }
  return returnObject
}
/**
 * Return last date the prospect was active (based on events)
 * @param {Object} prospect - Selected prospect
 * @param {Array} prospectEvents - Events for selected prospect
 * @returns {Date?} 
 */
export function getProspectLastActiveDate (prospect, events) {
  let lastActiveDate = null
  if (prospect.status !== 'Active') {
    const prospectMarkedPassedEvent = events.find(event => event.event_type === 'Prospekti merkitty ohi menneeksi')
    const prospectClosedEvent = events.find(event => event.event_type === 'Prospekti merkitty voitetuksi')

    // If prospect creation date is same as the date it's set inactive, prospect was never active
    if (prospectMarkedPassedEvent && prospect.created == prospectMarkedPassedEvent.modified_date) {
      return null
    }
    else {
      // In case prospect has both closed and passed event, we choose the more recent of the two
      if (prospectClosedEvent && prospect.passed_date != null) {
        lastActiveDate = new Date(prospectClosedEvent.modified_date) > new Date(prospect.passed_date) ? prospectClosedEvent.modified_date : prospect.passed_date
      }
      else if (prospectClosedEvent) {
        lastActiveDate = new Date(prospectClosedEvent.modified_date)
      }
      else if (prospect.passed_date) {
        lastActiveDate = new Date(prospect.passed_date)
      }
      // Not all prospects have these events (event log was implemented after prospects)
      // In these cases we use the last modified date
      else {
        lastActiveDate = new Date(prospect.last_modified)
      }
    }
  }
  return lastActiveDate
}
