<template>
  <v-row
    v-if="editParty"
    no-gutters
  >
    <v-col
      v-if="isLoading"
      style="text-align: center; padding: 2em"
    >
      <v-progress-circular
        :width="7"
        :size="80"
        indeterminate
        color="primary"
      />
      <h4 class="text--secondary">
        {{ $t('Loading. Please wait...') }}
      </h4>
    </v-col>
    <v-col
      v-else
    >
      <BaseModal
        @cancel="close"
      >
        <template #title>
          <v-container v-if="!editing">
            {{ $t('Create a new party') }}
          </v-container>
          <v-container v-else>
            {{ $t('Edit party') }}
          </v-container>
        </template>
        <template #content>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-select
              v-if="hasExternalOrgs && editing"
              v-model="selectedOrg"
              :items="userOrganizations"
              :label="$t('Choose organization')"
              item-value="id"
              item-text="name"
            />
            <v-select
              v-if="!editing"
              v-model="editedType"
              :items="itemsObject"
              :label="$t('SelectPartyType')"
              item-text="state"
              item-value="id"
              persistent-hint
            />
            <v-text-field
              v-if="showField('Organization') && editParty.organization !== null"
              v-model="editParty.organization"
              :label="$t('Organization')"
              :readonly="true"
              class="form-field"
            />
            <v-autocomplete
              v-if="showField('Company')"
              v-model="editParty.company_id"
              :items="companies"
              :label="$t('Company')"
              clearable
              item-text="company_name"
              item-value="company_id"
              class="form-field"
            />
            <v-text-field
              v-if="showField('Surname, forename')"
              v-model="editParty.name"
              :label="$t('Surname, forename')"
              :hint="$t('Required')"
              persistent-hint
              :rules="[rules.required]"
              :readonly="viewOnly"
              required
              class="form-field"
              @change="findExisting"
            />
            <v-row
              :class="{searchfieldparent: YTJQueriesOn}"
              no-gutters
            >
              <v-col>
                <v-text-field
                  v-if="showField('Name')"
                  v-model="editParty.name"
                  :hint="$t('Required')"
                  persistent-hint
                  :rules="[rules.required]"
                  :label="$t('Name')"
                  :readonly="viewOnly"
                  required
                  class="form-field"
                  :class="{searchField:YTJQueriesOn, verticalMarginForValidationField: !YTJQueriesOn}"
                />
              </v-col>
              <v-col
                v-if="showField('Name') && YTJQueriesOn"
                cols="2"
              >
                <v-btn
                  depressed
                  rounded
                  color="secondary"
                  class="ml-1 mt-2 mr-4"
                  @click="SeekFromYTJ(true)"
                >
                  <v-icon left>
                    search
                  </v-icon>
                  YTJ
                </v-btn>
              </v-col>
            </v-row>
            <v-alert
              v-model="nameQueryResultsAlertOn"
              dense
              type="info"
              dismissible
              class="mt-5"
            >
              <div>{{ nameQueryMessage }}</div>
              <div
                v-for="result in ytjNameQueryResults" 
                :key="result.businessIdentifier"
                class="result-row"
              >
                <span class="resultinfo">{{ result.companyName }}, {{ result.businessIdentifier }}</span>
                <v-btn 
                  text
                  rounded
                  outlined
                  @click="populateWith(result)"
                >
                  {{ $t('Choose') }}
                </v-btn>
              </div>
            </v-alert>
            <v-row
              :class="{searchfieldparent: YTJQueriesOn}"
              no-gutters
            >
              <v-col>
                <v-text-field
                  v-if="showField('Business ID')"
                  v-model="editParty.business_id"
                  :required="editedType === 0"
                  :rules="businessIdRules"
                  :label="$t('Business ID')"
                  :hint="editedType === 0 ? $t('Required'): ''"
                  :persistent-hint="editedType === 0"
                  :readonly="viewOnly"
                  validate-on-blur
                  class="form-field"
                  :class="{searchField:YTJQueriesOn, verticalMarginForValidationField: !YTJQueriesOn}"
                />
              </v-col>
              <v-col
                v-if="showField('Business ID') && YTJQueriesOn"
                cols="2"
              >
                <v-btn
                  depressed
                  rounded
                  color="secondary"
                  class="ml-1 mt-2 mr-2"
                  @click="SeekFromYTJ(false)"
                >
                  <v-icon left>
                    search
                  </v-icon>
                  YTJ
                </v-btn>
              </v-col>
            </v-row>
            <v-alert
              v-model="businessIdentifierQueryResultsAlertOn"
              dense
              type="info"
              dismissible
              class="mt-5"
            >
              <div>{{ businessIdentifierQueryMessage }}</div>
              <div
                v-for="result in ytjBusinessIdentifierQueryResults"
                :key="result.businessIdentifier"
                class="result-row"
              >
                <span class="resultinfo">{{ result.companyName }}, {{ result.businessIdentifier }}</span>
                <v-btn 
                  text
                  rounded
                  outlined
                  @click="populateWith(result)"
                >
                  {{ $t('Choose') }}
                </v-btn>
              </div>
            </v-alert>
            <v-text-field
              v-if="showField('Address')"
              v-model="editParty.address"
              :label="$t('Address')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('Postal code')"
              v-model="editParty.postal_code"
              :label="$t('Postal code')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('City')"
              v-model="editParty.city"
              :label="$t('Post office')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-select
              v-if="showField('Country')"
              v-model="editParty.id_country"
              :items="countries"
              :label="$t('Country')"
              item-value="id"
              item-text="name"
              class="form-field"
            />
            <v-text-field
              v-if="showField('Liaison')"
              v-model="editParty.liaison"
              :label="$t('Liaison')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('Phone number')"
              v-model="editParty.phone_number"
              :label="$t('Phone number')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('Email')"
              v-model="editParty.email"
              :label="$t('Email')"
              :hint="$t('Required')"
              persistent-hint
              required
              :readonly="viewOnly"
              :rules="[rules.email]"
              class="form-field"
            />
            <v-select
              v-if="showField('Industry classification')"
              v-model="editParty.industry_classification"
              :items="industryClassificationList"
              :label="$t('Industry classification')"
              :readonly="viewOnly"
              item-value="value"
              item-text="text"
              class="form-field"
              clearable
            />
            <v-select
              v-if="showField('Clientele')"
              v-model="editParty.clientele"
              :items="clienteleList"
              :label="$t('Clientele')"
              :readonly="viewOnly"
              item-value="id"
              item-text="name"
              class="form-field"
              clearable
            />
            <v-text-field
              v-if="showField('VAT number')"
              v-model="editParty.vat_number"
              :label="$t('VAT number')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('OVT id')"
              v-model="editParty.ovt_id"
              :label="$t('OVT id')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('Web invoice operator id')"
              v-model="editParty.web_invoice_operator_id"
              :label="$t('Web invoice operator id')"
              :readonly="viewOnly"
              class="form-field"
            />
            <!--<v-text-field
              v-if="showField('Account number')"
              v-model="editParty.account_number"
              :maxlength="35"
              :label="$t('Account number')"
              class="form-field"
              :readonly="viewOnly"
            />-->
            <v-text-field
              v-if="showField('CO name')"
              v-model="editParty.co_name"
              :label="$t('CO name')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('CO address')"
              v-model="editParty.co_address"
              :label="$t('CO address')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('CO postal code')"
              v-model="editParty.co_postal_code"
              :label="$t('CO postal code')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('CO post office')"
              v-model="editParty.co_post_office"
              :label="$t('CO post office')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-text-field
              v-if="showField('Person count')"
              v-model="editParty.person_count"
              :label="$t('Person count')"
              :readonly="viewOnly"
              type="number"
              class="form-field"
            />
            <v-autocomplete
              v-if="showField('Job description')"
              v-model="editParty.job_description"
              :items="jobDescriptions"
              :label="$t('Job description')"
              :readonly="viewOnly"
              item-text="label"
              item-value="id"
              class="form-field"
            />
            <v-autocomplete
              v-if="showField('Cost center')"
              v-model="editParty.cost_center"
              :items="costCenters"
              :label="$t('Cost center')"
              :readonly="viewOnly"
              clearable
              item-text="cost_center_name"
              item-value="cost_center_identifier"
              class="form-field"
            />
            <v-text-field
              v-if="showField('Business unit')"
              v-model="editParty.unit"
              :label="$t('Business unit')"
              :readonly="viewOnly"
              class="form-field"
            />
            <v-textarea
              v-if="showField('Comments')"
              v-model="editParty.comment"
              :label="$t('Comments')"
              :readonly="viewOnly"
              class="form-field"
            />
            <!-- checkbox to relay and set if party is tenant corporation. -->
            <v-checkbox
              v-if="showField('Tenant Corporation')"
              v-model="editParty.tenant_corporation"
              :label="$t('Tenant corporation')"
              :disabled="viewOnly"
            />
            <v-checkbox
              v-if="showField('Mediator company')"
              v-model="editParty.mediator_company"
              :label="$t('Mediator company')"
              :disabled="viewOnly"
            />
            <v-checkbox
              v-if="showField('User organization')"
              v-model="editParty.is_company"
              :label="$t('User organization')"
              :disabled="viewOnly"
            />
            <div
              v-if="editing && editParty.personnel"
              class="personnel-list"
            >
              <br>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('Personnel') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-list dense>
                        <v-list-item
                          v-for="p in editParty.personnel"
                          :key="p"
                          class="tile-divider"
                        >
                          <v-list-item-content>
                            {{ p }}
                          </v-list-item-content>
                          <v-spacer />
                        </v-list-item>
                      </v-list>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <br>
            <div
              v-if="editing && Object.keys(assignmentsByType).length > 0"
              class="assignment-list"
            >
              <v-col class="section-textfield">
                <strong class="text--secondary">
                  {{ $t('Party links') }}
                </strong>
              </v-col>
              <br>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="type in Object.keys(assignmentsByType)"
                  :key="type"
                >
                  <v-expansion-panel-header>
                    {{ getNameForDefinition(type) }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-list dense>
                        <v-list-item
                          v-for="assign in assignmentsByType[type]"
                          :key="assign.id"
                          class="tile-divider"
                        >
                          <v-list-item-content 
                            style="flex:none; width:75%"
                          >
                            {{ assign.name }}
                          </v-list-item-content>
                          <v-spacer />
                          <v-list-item-content 
                            v-if="contractPartyDefId != type && assign.source !== 'carpark'" 
                            style="flex:none" 
                          >
                            <v-checkbox
                              v-model.lazy="assign.delete"
                              :label="$t('Delete')"
                              :disabled="viewOnly"
                            />
                          </v-list-item-content>
                        </v-list-item>
                        <v-row
                          align="end"
                          justify="end"
                        >
                          <v-btn
                            v-if="contractPartyDefId != type"
                            :disabled="viewOnly"
                            class="assignment-select-btn"
                            text
                            outlined
                            rounded
                            @click="selectAssignmentsOfType(type)"
                          >
                            {{ $t('Check/uncheck selections') }}
                          </v-btn>
                        </v-row>
                      </v-list>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-form>
        </template>
        <template #footer>
          <v-btn
            :disabled="!valid || viewOnly"
            rounded
            depressed
            color="primary"
            @click="validate"
          >
            {{ $t('Save') }}
          </v-btn>
          <label v-if="nameExists">
            {{ $t('Party with same name exists') }}
          </label>
        </template>
      </BaseModal>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import moment from 'moment'
import BaseModal from './general/BaseModal'
import helpers from '../helpers'
export default {
  name: 'PartyRegisterForm',
  components: { BaseModal },
  props: {
    editParty: {
      type: Object,
      default: null 
    },
    editing: {
      type: Boolean,
      default: false 
    },
    costCenters: {
      type: Array,
      default: () => []
    },
    companies: {
      type: Array,
      default: () => []
    },
    itemsObject:{
      type: Array,
      default: () => []
    },
    userOrganizations: {
      type: Array,
      default: () => []
    },
    definitions: {
      type: Array,
      default: () => []
    },
    viewOnly: {
      type: Boolean,
      default: false 
    },
    hasExternalOrgs: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      nameExists: false,
      editedType: null,
      selectedOrg: null,
      links: [],
      settingss: {},
      assignmentsByType: {},
      businessFields: [
        'Organization',
        'Name',
        'Business ID',
        'Address',
        'Postal code',
        'City',
        'Country',
        'Liaison',
        'Phone number',
        'Email',
        'Industry classification',
        'Clientele',
        'VAT number',
        'OVT id',
        'Web invoice operator id',
        'Account number',
        'CO name',
        'CO address',
        'CO postal code',
        'CO post office',
        'Tenant Corporation',
        'Mediator company',
        'User organization',
        'Comments'
        ],
      personFields: [
        'Company',
        'Surname, forename',
        'Address',
        'Postal code',
        'City',
        'Country',
        'Phone number',
        'Email',
        'Job description',
        'Account number',
        'Cost center',
        'Business unit',
        'Comments'
      ],
      resourceFields: ['Name', 'Person count', 'Cost center', 'Business unit', 'Comments'],
      businessIdRules: [(v)=> {
        let data = this.itemsObject.find(item => item.id === this.editedType)
        data = data ? data.data : []
        const input = v ? v.toLowerCase().replace(/\s/g,'') : v
        const existing = data.find(party => {
          if (this.editing && this.editParty.id === party.id) {
            return false
          }
          if (party.business_id) {
            return party.business_id.toLowerCase().replace(/\s/g,'') === input
          }
          return false
        })
        if (this.settings.validateBusinessIdFi === true && v !== null && v !== undefined) {
          const error = helpers.parties.getBusinessIdentifierError(input, this.businessIdentifierQueryResultsAlertOn)
          if (error !== "") {
            return error
          }
        }
        if (existing) {
          return this.$t('Party with same business id exists')
        }
        return !!input || this.$t('Required')
       }
      ],
      isLoading: false,
      // Sähköpostiosoitteen oikean muodon tarkistus (tehdään vain jos kentässä sisältöä).
      rules: {
        required: value => !!value || this.$t('Required'),
        email: value => {
          if (value === undefined || value === null) {
          return true
          }
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('Invalid email')
        }
      },
      contractPartyDefId: undefined,
      ytjNameQueryResults: undefined,
      ytjBusinessIdentifierQueryResults: undefined,
      businessIdentifierQueryResultsAlertOn: false,
      nameQueryResultsAlertOn: false,
    }
  },
  computed: {
    ...mapGetters('app', ['definitionById', 'definitionByLabel', 'definitionsByGroupLabel', 'hasApplicationPermissionByName', 'countries']),
    ...mapState('app', ['currentDate', 'settings']),
    jobDescriptions () {
      const jobDescriptions = this.definitionsByGroupLabel('role.jobdescription')
      jobDescriptions.forEach(j => {
          j.label = this.$t(j.label)
        })
      jobDescriptions.sort((a, b) => {
          if (a.label < b.label) {
            return -1
          }
          if (a.label > b.label) {
            return 1
          }
            return 0
        })
      return jobDescriptions
    },
    businessIdentifierQueryMessage () {
      return this.businessIdentifierQueryResultsAlertOn ? 
        helpers.parties.getBusinessIdentifierError(this.editParty.business_id, true) + " " +
        this.getResultsInfo(this.ytjBusinessIdentifierQueryResults) : ""
    },
    nameQueryMessage () {
      return this.nameQueryResultsAlertOn ? this.getResultsInfo(this.ytjNameQueryResults) : ""
    },
    clienteleList () {
      return this.definitionsByGroupLabel('party.clientele').map(client => client.label)
    },
    industryClassificationList () {
      const industryClassificationDefs = this.definitionsByGroupLabel('party.industryclassification')
      const result = []

      industryClassificationDefs.forEach(def => {
        const code = def.label.split(' ')[0]
        
        if (code.length === 1) {
          result.push({
            text: def.label,
            value: def.id,
            disabled: true
          })
        } else {
          result.push({
            text: def.label,
            value: def.id,
          })
        }
      })
      return result
    },
    YTJQueriesOn () {
      return !this.editing && this.editedType === 0 && this.hasApplicationPermissionByName('YTJ_INTEGRAATIO')
    },
  },
  async mounted () {
    await this.getSettings()
    this.isLoading = true
    this.editedType = this.editParty.type
    this.contractPartyDefId = this.definitionByLabel('contract party def').id
    if (this.editParty.id !== undefined) {
      this.$rambollfmapi.parties.links(this.editParty.id).then(res => {
      this.links = res
      this.createAssignments()
      this.isLoading = false
      }) 
    }
    else {
      this.isLoading = false
    }
  },
  methods: {
  ...mapActions('app', ['getSettings'] ),
    populateWith (result) {
      this.editParty.address = result.address
      this.editParty.postal_code = result.zipCode
      this.editParty.city = result.city
      this.editParty.phone_number = result.phoneNumber
      this.editParty.email = result.email
      this.editParty.business_id = result.businessIdentifier
      this.editParty.name = result.companyName
      this.editParty.ytj_control = true
      this.businessIdentifierQueryResultsAlertOn = false
      this.nameQueryResultsAlertOn = false
      this.ytjNameQueryResults = undefined,
      this.ytjBusinessIdentifierQueryResults = undefined
    },
    getResultsInfo (results) {
      if (results === undefined || results === null || results.length === 1) {
        if (results !== undefined && results !== null && results.length === 1) {
          this.businessIdentifierQueryResultsAlertOn = false
          this.nameQueryResultsAlertOn = false
        }
        return ""
      }
      if (results.length > 1) {
        return helpers.parties.twoOrMoreOptionsMessage(results)
      } else if (results.length === 0) {
        return this.$t('With your query no results, too many results was found or error occured') + "."
      }
    },
    async SeekFromYTJ (wordQuery) {
      if (wordQuery) {
        this.ytjNameQueryResults = 
          await this.$rambollfmapi.parties.ytjnamequery(this.editParty.name)
        if (this.ytjNameQueryResults.length === 0 || this.ytjNameQueryResults.length > 1) {
          this.nameQueryResultsAlertOn = true
          return
        }
        if (this.ytjNameQueryResults.length === 1) {
          this.populateWith(this.ytjNameQueryResults[0])
        }
      } else {
        const errors = helpers.parties.getBusinessIdentifierError(this.editParty.business_id, true)
        if (errors !== "") {
          this.businessIdentifierQueryResultsAlertOn = true
          return
        }
        this.ytjBusinessIdentifierQueryResults = 
          await this.$rambollfmapi.parties.ytjbusinessidentifierquery(this.editParty.business_id)
        if (this.ytjBusinessIdentifierQueryResults.length === 0 || this.ytjBusinessIdentifierQueryResults.length > 1) {
          this.businessIdentifierQueryResultsAlertOn = true
          return
        }
        if (this.ytjBusinessIdentifierQueryResults.length === 1) {
          this.populateWith(this.ytjBusinessIdentifierQueryResults[0])
        }
      }
    },
    findExisting () {
      if (this.editing) {
        return false
      } else {
        this.nameExists = this.itemsObject[this.editedType].data.some(party => {
          return party.name.toLowerCase() === this.editParty.name.toLowerCase()
        })
      }
    },
    getNameForDefinition (id) {
      const intId = parseInt(id)
      return this.$t(
        this.definitions.find(def => {
          return def.id === intId
        })?.label
      )
    },
    close () {
      this.links = []
      this.assignmentsByType = {}
      this.editedType = null
      this.clearFields()
      this.$emit('close')
    },
    showField (field) {
      if (this.editedType === 0) {
        // business
        return this.businessFields.includes(field)
      } else if (this.editedType === 1) {
        // person
        return this.personFields.includes(field)
      } else if (this.editedType === 2) {
        // resource
        return this.resourceFields.includes(field)
      }
      return false
    },
    createAssignments () {
      // Get all different definitions and create properties out of them
      this.assignmentsByType = {}
      if (this.editParty.assignments === undefined) {
        return
      }
      this.editParty.assignments.forEach(assign => {
        const label = assign.idDefinition
        if (this.assignmentsByType[label] === undefined) {
          this.$set(this.assignmentsByType, label, [])
        }
        const assignmentObject = {
          name: this.createAssignmentString(assign),
          id: assign.idLink,
          source: assign.linkType,
          delete: false
        }
        this.assignmentsByType[label].push(assignmentObject)
      })
      for (const key in this.assignmentsByType) {
        this.assignmentsByType[key].sort((a, b) => {
          return a.name < b.name ? -1 : 1
        })
      }
    },
    createAssignmentString (assign) {
      let assignment = ''
      assignment += assign.siteName ? assign.siteName : assign.buildingName
      if (assign.unitName !== null) {
        assignment += ' [' + assign.unitName + ']'
      } else if (assign.spaceName !== null) {
        assignment += ' [' + assign.spaceName + ']'
      } else if (assign.structureName !== null) {
        assignment += ' [' + assign.structureName + ']'
      } else if (assign.linkType == "contract") {
        let typesOfReferences = [];
        if (assign.id == assign.contractIdLandlord) {
          typesOfReferences.push(this.$t("Landlord"));
        } 
        if (assign.id == assign.contractIdLandlordContactPerson) {
          typesOfReferences.push(this.$t("create_rent_contract.contact_person"));
        } 
        if (assign.id == assign.contractIdTenant) {
          typesOfReferences.push(this.$t("Tenant"));
        } 
        if (assign.id == assign.contractIdTenantContactPerson) {
          typesOfReferences.push(this.$t("create_rent_contract.tenant_contact_person"));
        } 
        if (assign.id == assign.contractOtherIdTenant) {
          typesOfReferences.push(this.$t("OtherTenant"));
        }
        assignment = assign.contractNumber + ' [' + typesOfReferences.join(',') + ']';
      }
      return assignment
    },
    findLink (type, id) {
      return this.links[type].find(link => {
        return link.id === id
      })
    },
    selectAssignmentsOfType (type) {
      const hasSelected =
        undefined !==
        this.assignmentsByType[type].find(obj => {
          return obj.delete
        })
      this.assignmentsByType[type].forEach((assign, index) => {
        if (assign.source === 'carpark') return
        this.assignmentsByType[type][index].delete = !hasSelected
      })
    },
    async manageOrganizationLinks () {
      var today = new Date(this.currentDate).toISOString()
      // pick the current active link
      // NOTE: We have to pick the active link based on start_date without NULL end_date check so that this picks possible middlelinks
      var activeLink = this.links['organization'].find(function (link, index) {
        if (link.start_date <= today) {
          return true
        }
      });
      // pick possible future link
      var futureLink = this.links['organization'].find(function (link, index) {
        if (link.start_date > today) {
          return true
        }
      });

      const newLink = {
        id: 0,
        id_party: this.editParty.id,
        id_annotation: this.definitionByLabel('Tenant').id,
        group: 3,
        order: 0,
        start_date: this.currentDate,
        end_date: null
      }

      const linkPatch = []

      // Organization linking is performed for business and person parties
      if (this.editedType === 0 || this.editedType === 1) {
        if (this.selectedOrg > 0) {
          // if we have no active link...
          if (activeLink === undefined) {
            // If we have a link set in future, create a middlelink
            if (futureLink !== undefined) {
              const middleLink = {
                id: 0,
                id_party: this.editParty.id,
                id_annotation: this.definitionByLabel('Tenant').id,
                group: 3,
                order: 0,
                start_date: this.currentDate,
                end_date: futureLink.start_date
              }

              await this.$rambollfmapi.userOrganizations.partyLinks(this.selectedOrg).post(middleLink) 
            } else {
              // otherwise, if no futurelink is present and no active link exist, perform link post
              await this.$rambollfmapi.userOrganizations.partyLinks(this.selectedOrg).post(newLink)
            }
          } else {
            // if we have an active link but link has no end-date...
            if (activeLink.end_date === null) {
              // if the selected organization differs from the one set in active link...
              if (this.selectedOrg !== activeLink.id_parent) {
                linkPatch.push({
                  op: 'replace',
                  path: '/end_date',
                  value: moment(this.currentDate).add(-1, 'days')
                })
                // First pure patch the end_date to current link...
                await this.$rambollfmapi.userOrganizations.partyLinks(activeLink.id_parent).purePatch(activeLink.id_parent, activeLink.id, linkPatch)
                // ...then create a new link.
                await this.$rambollfmapi.userOrganizations.partyLinks(this.selectedOrg).post(newLink)
              }
            } else if (activeLink.end_date > today) {
              // if active link has an enddate  that's laterthen today 
              // and the selected organization differs from the one set in active link...
              if (this.selectedOrg !== activeLink.id_parent) {
                linkPatch.push({
                  op: 'replace',
                  path: '/id_parent',
                  value: this.selectedOrg
                })
                // pure patch the organization id\parent id
                await this.$rambollfmapi.userOrganizations.partyLinks(activeLink.id_parent).purePatch(activeLink.id_parent, activeLink.id, linkPatch)
              }
            } else  if (activeLink.end_date < today) {
              // if activelinks end date is earlier then today, perform link post
              await this.$rambollfmapi.userOrganizations.partyLinks(this.selectedOrg).post(newLink)
            }             
          }
        } else if (this.selectedOrg === 0) {
          // If we have selected the organization that's meant for removing links, perform link deletion
          await this.$rambollfmapi.userOrganizations.partyLinks(activeLink.id_parent).delete(activeLink.id_parent, activeLink.id, this.currentDate)
        }
      }
    },
    async validate () {
      if (this.$refs.form.validate()) {
        const newParty = {
          type: this.editedType,
          name: this.editParty.name,
          company_id: this.editParty.company_id,
          business_id: this.editParty.business_id,
          address: this.editParty.address,
          postal_code: this.editParty.postal_code,
          city: this.editParty.city,
          id_country: this.editParty.id_country,
          liaison: this.editParty.liaison,
          phone_number: this.editParty.phone_number,
          email: this.editParty.email,
          job_description: this.editParty.job_description,
          person_count: this.editParty.person_count,
          cost_center: this.editParty.cost_center,
          unit: this.editParty.unit,
          comment: this.editParty.comment,
          tenant_corporation: this.editParty.tenant_corporation ? this.editParty.tenant_corporation : false,
          mediator_company: this.editParty.mediator_company ? this.editParty.mediator_company : false,
          is_company: this.editParty.is_company ? this.editParty.is_company : false,
          visibility_status: 1,
          clientele: this.editedType === 1 ? 'Muu' : this.editParty.clientele,
          industry_classification: this.editParty.industry_classification,
          vat_number: this.editParty.vat_number,
          ovt_id: this.editParty.ovt_id,
          web_invoice_operator_id: this.editParty.web_invoice_operator_id,
          co_name: this.editParty.co_name,
          co_address: this.editParty.co_address,
          co_postal_code: this.editParty.co_postal_code,
          co_post_office: this.editParty.co_post_office,
          account_number: this.editParty.account_number,
          ytj_control: this.editParty.ytj_control
        }
        
        if (!this.editing) {
          let retVal = await this.$rambollfmapi.parties.post(newParty)
          if (retVal.id !== undefined) {
            newParty.id = retVal.id
          }
        } else {
          const partyPatch = []

          Object.entries(this.editParty).forEach(function (key, index, value) {
            if (Object.keys(newParty).includes(key['0'])) {
              partyPatch.push({
                op: 'replace',
                path: '/' + key['0'],
                value: key['1']
              })
            }
          })
          await this.$rambollfmapi.parties.patch(this.editParty.id, partyPatch)

          // If external orgs exist, perfotm organization link operations
          if (this.hasExternalOrgs) {
            this.manageOrganizationLinks()
          }

          for (const type in this.assignmentsByType) {
            for (const key in this.assignmentsByType[type]) {
              const obj = this.assignmentsByType[type][key]
              if (obj.delete === true) {
                const parentId = this.links[obj.source].find(link => {
                  return link.id === obj.id
                }).id_parent
                if (parentId !== undefined) {
                  await this.$rambollfmapi[obj.source + 's']
                    .partyLinks(parentId)
                    .delete(parentId, obj.id)
                }
              }
            }
          }
        }
        this.links = []
        this.assignmentsByType = {}
        this.editedType = null
        this.$emit('childToParent', false)
		if (newParty.type === 1) {
           this.$emit('refresh')
		}
		else {
           this.$emit('refresh', newParty.type)
		}
        this.$emit('savedParty', newParty)
        this.clearFields()
      }
    },
    clearFields () {
      this.editParty.name = ''
      this.editParty.business_id = ''
      this.editParty.address = ''
      this.editParty.postal_code = ''
      this.editParty.city = ''
      this.editParty.id_country = 1
      this.editParty.phone_number = ''
      this.editParty.company_id = ''
      this.editParty.job_description = ''
      this.editParty.person_count = ''
      this.editParty.cost_center = ''
      this.editParty.unit = ''
      this.editParty.comment = ''
      this.editParty.email = ''
      this.editParty.company = ''
      this.editParty.liaison = ''
      this.editParty.tenant_corporation = false
      this.editParty.mediator_company = false
      this.editParty.is_company = false
      this.editParty.clientele = '',
      this.editParty.vat_number = '',
      this.editParty.ovt_id = '',
      this.editParty.web_invoice_operator_id = '',
      this.editParty.co_name = '',
      this.editParty.co_address = '',
      this.editParty.co_postal_code = '',
      this.editParty.co_post_office = ''
      this.editParty.ytj_control = false
      this.businessIdentifierQueryResultsAlertOn = false
      this.nameQueryResultsAlertOn = false
      this.ytjNameQueryResults = undefined,
      this.ytjBusinessIdentifierQueryResults = undefined
      //this.editParty.account_number = ''
    }
  }
}
</script>
<style scoped>
.fullscreen-settings {
  padding: 0px;
  height: 100%;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  background-color: white;
}
.searchField {
  width: 100%;
  display: flex;
  margin-top: 7px !important;
}
.verticalMarginForValidationField {
  margin-top: 22px !important;
  margin-bottom: 0px !important;
}
.searchfieldparent {
  align-items: flex-start;
  margin-top: 16px;
}
.result-row {
  display: flex;
  justify-content: space-between;
}
.resultinfo {
  margin-top: 10px;
}
</style>
