import humanize from './humanize'
import sanitizeHtml from 'sanitize-html'
import he from 'he'
import loglevel from 'loglevel'
import { i18n } from '../plugins/i18n'
import moment from 'moment'
export default {
  formatData (value, formatter, currency = null) {
    if ((typeof value === 'undefined' || value === null || (typeof value == 'string' && value.trim() == "")) && formatter !== 'YesNoUnknown') {
      return '-'
    }

    if (!formatter) {
      return value
    }

    // ------------------------------------------------------------------------------
    // Numbers and amounts
    // ------------------------------------------------------------------------------

    if (formatter === 'Number') {
      if(value === null || value === undefined) {
        return ''
      }
      return Number(value)
    }

    if (formatter === 'NumberTwoDecimals') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(Number(value).toFixed(2))
    }

    if (formatter === 'Integer') {
      if (isNaN(Number(value))) { return '-' }
      return Number(value).toFixed()
    }

    if (formatter === 'Double') {
      if (isNaN(Number(value))) { return '-' }
      return Number(value).toFixed(2)
    }
   
    if (formatter === 'Amount') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(Number(value), 0) + ' ' + i18n.t('short.amount')
    }

    if (formatter === 'StringToNumber') {
      return Number(value.replace(',','.').replace(' ',''))
    }

    if (formatter === 'BoldNumber') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(), 0).bold()
    }

    // ------------------------------------------------------------------------------
    // Area
    // ------------------------------------------------------------------------------

    if (formatter === 'Area') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)) + ' m\u00B2'
    }

    if (formatter === 'AreaOrNull') {
      if (isNaN(Number(value))) { return '-' }
      return value === null ? null : humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)) + ' m\u00B2'
    }

    if (formatter === 'AreaExact') {
      return value + ' m\u00B2'
    }

    if (formatter === 'Meter') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)) + ' m'
    }

    if (formatter === 'CubicMeter') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)) + ' m\u00B3'
    }

    if (formatter === 'AreaPerPerson') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(value) + ' m\u00B2/' + i18n.t('short.person')
    }
    
    // ------------------------------------------------------------------------------
    // Percentage
    // ------------------------------------------------------------------------------

    if (formatter === 'Percentage') {
      if (isNaN(Number(value))) { return '-' }
      return Number(value).toFixed(2).replace('.', ',') + ' %'
    }

    if (formatter === 'PercentageInteger') {
      if (isNaN(Number(value))) { return '-' }
      return Number(value).toFixed(0) + ' %'
    }

    if (formatter === 'PercentageFromDecimal') {
      if (isNaN(Number(value))) { return '-' }
      return Number(value * 100).toFixed(2).replace('.', ',') + ' %'
    }

    if (formatter === 'TrendPercentage') {
      if (isNaN(Number(value))) { return '-' }
      return (value > 0 ? '+' : '') + Number(value).toFixed() + ' %'
    }

    // ------------------------------------------------------------------------------
    // Money
    // ------------------------------------------------------------------------------

    if (formatter === 'Euro') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(Number(value).toFixed(2)) + ' €'
    }

    if (formatter === 'Currency') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(Number(value).toFixed(2)) + ' ' + currency
    }

    if (formatter === 'Money') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1), 0) + ' €'
    }

    if (formatter === 'MoneyPerSquare') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(Number(value).toFixed(2)) + ' €/m\u00B2'
    }

    if (formatter === 'MoneyPerSquareInt') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1), 0) + ' €/m\u00B2'
    }

    if (formatter === 'EuroPerMonth') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(Number(value).toFixed(2)) + ' €/kk'
    }

    if (formatter === 'CurrencyPerMonth') {
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(Number(value).toFixed(2)) + ' ' + currency + '/' + i18n.t('Mth')
    }

    if (formatter === 'AdditionalCost') {
      if (isNaN(Number(value))) { return '-' }
        return humanize.amount_long(Number(value), 3) + ' €/m\u00B2/kk'
    }

    // ------------------------------------------------------------------------------
    // Date and time
    // ------------------------------------------------------------------------------

    if (formatter === 'Date') {
      return humanize.date(value, 'date')
    }

    if (formatter === 'Time') {
      return humanize.date(value, 'time')
    }

    if (formatter === 'DateTime') {
      return humanize.date(value, 'datetime')
    }

    if (formatter === 'TimeZ') {
      return humanize.date(value + 'Z', 'time')
    }
    
    if (formatter === 'MonthAndYear') {
      const time = moment(value, 'YYYY-MM-DD')
      return time.isValid() ? `${time.get('month') + 1}/${time.get('year')}` : ''
    }

    if (formatter === 'Quarter') {
      const time = moment(value)
      return `Q${time.get('quarter')} ${time.get('year')}`
    }

    if (formatter === 'Month') {
      if (isNaN(Number(value))) { return '-' }
      return Number(value) + ' kk'
    }

    if (formatter === 'Year') {
      const time = moment(value, 'YYYY-MM-DD')
      return time.isValid() ? time.get('year') : ''
    }

    // ------------------------------------------------------------------------------
    // Others
    // ------------------------------------------------------------------------------

    if (formatter === 'Translation') {
      return i18n.t(value)
    }

    if (formatter === 'TruthValue') {
      if (value === false) {
        return ' '
      }
      return i18n.t('Yes')
    }

    if (formatter === 'Boolean') {
      if (value === undefined) {
        return ' '
      }
      if (value === false ) {
        return i18n.t('No')
      }
      return i18n.t('Yes')
    }

    if (formatter === 'YesNoUnknown') {
      if (typeof value === 'undefined' || value === null || value === "")
      {
        return i18n.t('Undefined')
      }
      else if (value.toString().toLowerCase() === 'true' ) {
        return i18n.t('Yes')
      }
      else if (value.toString().toLowerCase() === 'false' ) {
        return i18n.t('No')
      }
    }

    if (formatter === 'Array') {
      return value
    }

    // Leave this last to avoid unecessary loggins
    if (formatter !=='' && formatter !== 'None')
    {
      loglevel.error('Formatter not found: ' + formatter)
    }
    return value
  },
  alignRight (format) {
    if (!format) {
      return false
    }

    // If format is a non-numeric\left-alignable format return false
    if (format === 'Translation') {
      return false
    } else if (format === 'Array') {
      return false
    } else if (format === 'None') {
      return false
    }

    return true
  },
  valueWithUnit (value, header, encode) {
    // make sure input makes sense
    if (typeof value === 'undefined' || value === null || value === "") {
      return '-'
    }

    if (header.indexOf('date') >= 0 || header.indexOf('Date') >= 0) {
      return humanize.date(value, 'date')
    }
    // We have an check for '-' character as it's used in the headers for parties with costcenters
    // in following style: [party.name] - [costcenter.name]
    if (header.indexOf('percentage') >= 0 || header.indexOf('-') >= 0 && header !== 'VAT-responsibility') {
      if (isNaN(Number(value))) { return '-' }
      return Number(value).toFixed(2) + ' %'
    }

    if ((header.indexOf('outside_areas') > -1 || header.indexOf('loading_areas') > -1) && typeof value === 'number') {
      return value
    }

    if ((header.indexOf('area') >= 0 && typeof value === 'number') || header.indexOf('Area') >= 0 || (header.indexOf('ktv') >= 0 && typeof value === 'number')) {
      if (value === null) {
        value = 0
      }
      if (encode === false) {
        return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)) + ' m²'
      }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)) + ' m\u00B2'
    }

    if (header.indexOf('trend') >= 0) {
      return (value > 0 ? '+' : '') + Number(value).toFixed(0) + '%'
    }

    if (header === 'tenant_corporation') {
      if (value === false) {
        return ' '
      }
      return i18n.t('Yes')
    }

    // HACK Make this more general
    if (header.indexOf('utilization') >= 0) {
      // NaN is not ok
      if (isNaN(Number(value))) { return '-' }
      return humanize.amount_long(value) + ' m\u00B2/hlö'
    }

    if (header === 'unit_market_rent' || header.indexOf('Rent') >= 0 || header.indexOf('Costs') >= 0 || header.indexOf('Cost') >= 0 || header.indexOf('euroAmount') >= 0) {
      return humanize.amount_long(Number(value).toFixed(2)) + ' €'
    }

    if (header === 'firstSum' || header === 'secondSum' || header === 'upkeepExpense' || header === 'estateUpkeepExpense') {
      return humanize.amount_long(Number(value).toFixed(2)) + ' €'
    }

    if (header.indexOf('userGroups') >= 0) {
      return value.groupName
    }

    if (header.indexOf('budget') >= 0 && typeof value === 'number' && header.indexOf('upkeep_rent') < 0) {
      if (value === null) {
        value = 0
      }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1), 0)
    }

    if (header.indexOf('KTV') >= 0 || header.indexOf('purpose_zone') >= 0) {
      return value.toString()
    }

    if (header === 'market_rent' || header.indexOf('maintenance_charges_estimation') >= 0 || header.indexOf('upkeep_rent_budget') >= 0 || header.indexOf('upkeep_rent_actualization') >= 0) {
      return humanize.amount_long(Number(value).toFixed(2)) + ' €/m\u00B2'
    }    

    if (header.indexOf('time') >= 0) {
      return humanize.date(value, 'time')
    }

    if (header.indexOf('undefined_spaces') >= 0) {
      return Number(value) + ' kpl'
    }

    if (header.indexOf('general_rating') > -1 && typeof value === 'number') {
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(0), 0).bold()
    }

    const sanitized = sanitizeHtml(value)

    if (encode) {
      return he.escape(sanitized.toString())
    }

    return he.decode(he.escape(sanitized.toString()))
  },
  unitByFormat (formatter)
  {
    switch(formatter)
    {
      case 'Area':
        return 'm\u00B2'
      case 'EuroPerMonth':
        return '€/kk'
      default:
        return ''
    }
  },
  markValueAsRed (value, header, encode) {
    if ((header.indexOf('area') >= 0 && typeof value === 'number') || header.indexOf('Area') >= 0) {
      if (value === null) {
        value = 0
      }
      if (encode === false) {
        return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)).fontcolor('red') + ' m²'
      }
      return humanize.amount_long((Math.round(value * 2) / 2).toFixed(1)).fontcolor('red') + ' m\u00B2'
    }
  },
  escapeHtml (text) {
    if(text === undefined || text === null) {
      return text
    }
    return he.escape(text.toString())
  },
  unescapeHtml (text) {
    if(text === undefined || text === null) {
      return text
    }
    return he.unescape(text.toString())
  },
  //escapes HTML from all string attributes of an object
  escapeHtmlFromObjectStrings (obj) {
    if(typeof obj === 'object' && obj !== null) {
      Object.entries(obj).forEach(entry => {
        if(typeof entry[1] === 'string') {
          obj[entry[0]] = this.escapeHtml(entry[1])
        }
      });
    }
    return obj
  },
  //unescapes HTML from all string attributes of an object
  unescapeHtmlFromObjectStrings (obj) {
    if(typeof obj === 'object' && obj !== null) {
      Object.entries(obj).forEach(entry => {
        if(typeof entry[1] === 'string') {
          obj[entry[0]] = this.unescapeHtml(entry[1])
        }
      });
    }
    return obj
  },
  sanitize (text) {
    if(text === undefined || text === null) {
      return text
    }
    const options = {
      allowedTags: [ 'b', 'i', 'em', 'strong',  "footer", "header", "h1", "h2", "h3", "h4",
      "h5", "h6",],
      allowedAttributes: {
      },
    }
    return sanitizeHtml(text, options)
  },
  symbolForCurrencyAbbreviation (abbr) {
    switch (abbr) {
      case 'EUR':
          return '€'
      case 'DKK':
      case 'SEK':
      case 'NOK':
          return 'kr'
      case 'USD':
          return '$'
      default:
          return ''
    }    
  }
}
