<template>
  <!--<v-expansion-panel :value="[!isLoading]" expand class="no-padding">-->
  <v-expansion-panels
    :value="expand[0] ? [0] : []"
    multiple
  >
    <!-- v-model doesn't work the same anymore on v-expansion-panel inside v-expansion-panels, so workaround -->
    <v-expansion-panel
      class="no-padding"
      @click="onExpansionPanelClick"
    >
      <v-expansion-panel-header>
        {{ title }}
        <div
          v-if="data.infoText != null && data.infoText.length > 0"
          class="justify-end d-flex mr-3"
        >
          <InfoBall
            :info="$t(data.infoText)"
            top
          />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-if="loading && type !== 'LazyTable'"
          style="text-align: center; padding: 2em;"
        >
          <v-progress-circular
            size="64"
            indeterminate
            color="primary"
          />
        </div>
          
        <div v-else>
          <div v-if="type === 'LazyTable'">
            <LazyDataTable
              :title="title"
              :data="data"
              :type="type"
              :widget-id="id"
              :is-full-table="!!data.isFullTable"
              :hide="hideDefaultButtons"
              :date="date"
              :custom-pagination="data.customPagination"
              :trigger-get-all-data="data.triggerGetAllData"
              @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
              @getAllFilteredData="$emit('getAllFilteredData', $event)"
              @changePproperty="$emit('changePproperty', $event)"
            >
              <template #table-buttons="props">
                <slot
                  name="table-buttons"
                  :get-all-filtered-data="props.getAllFilteredData"
                />
              </template>
            </LazyDataTable>
          </div>
          <div
            v-else-if="type === 'HorizontalBarDataGraph'"
            class="graph"
          >
            <HorizontalBarDataGraph
              :title="title"
              :data="data"
              :type="type"
            />
          </div>
          <div v-else-if="type === 'VerticalBarDataGraph'">
            <VerticalBarDataGraph
              :data="data"
              :type="type"
            />
          </div>
          <div v-else-if="type === 'VerticalStackedGraph'">
            <VerticalStackedGraph
              :data="data"
              :widget-id="id"
            />
          </div>
          <div
            v-else-if="type === 'PieDataGraph'"
            class="graph"
          >
            <PieDataGraph
              :data="data"
              :type="type"
              :default-item-set="defaultItemSet"
              @change-pie-graph-item-set="$emit('change-item-set', $event)"
            />
          </div>
          <div v-else-if="type === 'DataTable'">
            <DataTable
              :data="data"
              :type="type"
              :title="title"
              :is-full-table="!!data.isFullTable"
              :custom-formatters="data.customFormatters"
              :custom-filter-content="data.customFilterContent"
              :hide-default-buttons="hideDefaultButtons"
              :widget-id="id"
              :hide-total-row="data.hideTotalRow"
              :average-total-row="data.averageTotalRow"
              @dataUpdated="$emit('dataUpdate', $event)"
              @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
              @updatedCurrentRows="$emit('updatedCurrentRows', $event)"
              @updateWidget="$emit('updateWidget')"
            >
              <template #table-buttons>
                <slot name="table-buttons" />
              </template>
              <template 
                #customComponent="{ item }"
              >
                <slot
                  name="customComponent"
                  :item="item"
                />
              </template>
            </DataTable>
          </div>
          <div v-else-if="type === 'GroupTable'">
            <GroupTable
              :data="data"
              :type="type"
              :title="title"
            />
          </div>
          <div v-else-if="type === 'TimeLineGraph'">
            <TimeLineGraph
              :data="data"
              :type="type"
            />
          </div>
          <div
            v-else-if="type === 'LineChart'"
            class="graph"
          >
            <LineChartWidget
              :data="data"
              :type="type"
            />
          </div>
          <div v-else-if="type === 'DataTableVertical'">
            <DataTableVertical
              :data="data"
              :type="type"
              :title="title"
              @dataUpdated="$emit('dataUpdate', $event)"
            />
          </div>
          <div v-else-if="type === 'ObjectTable'">
            <ObjectTable
              :data="data"
              :type="type"
              :title="title"
              :custom-formatters="data.customFormatters"
              :widget-id="id"
              :should-request-all-data="data.shouldRequestAllData"
              @dataUpdated="$emit('dataUpdate', $event)"
              @changePproperty="$emit('changePproperty', $event)"
              @drag="$emit('drag', $event)"
              @openDialog="$emit('openDialog', $event)"
              @getSentInvoices="$emit('getSentInvoices',$event)"
              @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
              @getAllData="$emit('getAllData', $event)"
              @editRow="$emit('editRow', $event)"
            >
              <template 
                #customComponent="{ item }"
              >
                <slot
                  name="customComponent"
                  :item="item"
                />
              </template>
            </ObjectTable>
          </div>
          <div
            v-else-if=" type === 'ExpenseBudgetGraph'"
            class="graph"
          >
            <ExpenseBudgetGraph
              :data="data"
            />
          </div>
          <div
            v-else-if="type === 'BudgetRealizationGraph'"
            class="graph"
          >
            <BudgetRealizationGraph 
              :data="data"
            />
          </div>
          <div v-else-if="type === 'MetaDataTable'">
            <MetaDataTable
              :data="data"
              :type="type"
              :title="title"
              :has-action="hasAction"
              :is-rights-to-modify-prop="isRightsToModify"
              :is-rights-to-add="isRightsToAdd"
              @edit="$emit('edit')"
              @dataUpdated="$emit('dataUpdate', $event)"
              @updateWidget="$emit('updateWidget')"
            />
          </div>
          <div
            v-else-if="type === 'HorizontalGroupedBarDataGraph'"
            class="graph"
          >
            <HorizontalGroupedBarDataGraph
              :data="data"
              :type="type"
              :plugins="plugins"
              :widget-id="id"
              @change-item-set="$emit('change-item-set', $event)"
              @click="(chartElement) => $emit('graphClick', chartElement)"
            />
          </div>
          <div
            v-else-if="type === 'HorizontalStackedGraph'"
            class="graph"
          >
            <HorizontalStackedGraph
              :data="data"
              :type="type"
            />
          </div>
          <div v-else-if="type === 'EditableMetadataForm'">
            <editable-metadata-form
              :data="data"
              :title="title"
            />
          </div>
          <div v-else-if="type === 'DynamicDataTable'">
            <DynamicDataTable
              :data="data"
              :type="type"
              :title="title"
              :widget-id="id"
              @dataUpdated="$emit('dataUpdate', $event)"
            />
          </div>
          <div v-else-if="type === 'LinkTableWithButtons'">
            <LinkTableWithButtons
              :data="data"
              :type="type"
              :title="title"
              :widget-id="id"
              @addNewLinkDialogOpen="$emit('addNewLinkDialogOpen')"
            />
          </div>
          <div v-else-if="type === 'KanbanWidget'">
            <kanban-widget
              :data="data"
              :type="type"
              :title="title"
              :widget-id="id"
              @triggerCustomEvents="$emit('triggerCustomEvents', $event)"
            />
          </div>

          <div v-else>
            <Default />
          </div>
        </div>
        <!--<component v-if="component && !isLoading" :is="component" :data="data" :type="type" />-->
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import HorizontalBarDataGraph from './widgets/HorizontalBarDataGraph.vue'
import VerticalBarDataGraph from './widgets/VerticalBarDataGraph.vue'
import VerticalStackedGraph from './widgets/VerticalStackedGraph.vue'
import ExpenseBudgetGraph from './InternalRent/ExpenseBudgetGraph.vue'
import BudgetRealizationGraph from './InternalRent/BudgetRealizationGraph.vue'
import PieDataGraph from './widgets/PieDataGraph.vue'
import TimeLineGraph from './widgets/TimeLineGraph.vue'
import Default from './widgets/Default.vue'
import DataTable from './widgets/DataTable.vue'
import GroupTable from './widgets/GroupTable.vue'
import DataTableVertical from './widgets/DataTableVertical.vue'
import ObjectTable from './widgets/ObjectTable.vue'
import LazyDataTable from './widgets/LazyDataTable.vue'
import MetaDataTable from './widgets/MetaDataTable.vue'
import EditableMetadataForm from './widgets/EditableMetadataForm.vue'
import HorizontalGroupedBarDataGraph from './widgets/HorizontalGroupedBarDataGraph.vue'
import DynamicDataTable from './widgets/DynamicDataTable.vue'
import HorizontalStackedGraph from "./widgets/HorizontalStackedGraph.vue"
import InfoBall from './InfoBall.vue'
import LinkTableWithButtons from './widgets/LinkTableWithButtons.vue'
import LineChartWidget from './widgets/LineChartWidget.vue'
import KanbanWidget from './widgets/KanbanWidget/KanbanWidget.vue'

export default {
  components: {
    HorizontalBarDataGraph: HorizontalBarDataGraph,
    VerticalBarDataGraph,
    VerticalStackedGraph,
    ExpenseBudgetGraph,
    BudgetRealizationGraph,
    PieDataGraph: PieDataGraph,
    Default: Default,
    DataTable: DataTable,
    DynamicDataTable: DynamicDataTable,
    GroupTable: GroupTable,
    TimeLineGraph: TimeLineGraph,
    DataTableVertical: DataTableVertical,
    ObjectTable: ObjectTable,
    LazyDataTable: LazyDataTable,
    MetaDataTable: MetaDataTable,
    EditableMetadataForm: EditableMetadataForm,
    HorizontalGroupedBarDataGraph: HorizontalGroupedBarDataGraph,
    HorizontalStackedGraph,
    InfoBall,
    LinkTableWithButtons,
    LineChartWidget,
    KanbanWidget
  },
  // name: "DynamicWidget",
  props: {
    id: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    maximized: {
      type: Array,
      default: function () { return [true] }
    },
    plugins: {
      type: Array,
      default: function () { return [] }
    },
    defaultItemSet: {
      type: Object,
      default: null
    },
    hideDefaultButtons: {
      type: Boolean,
      default: false
    },
    hasAction: {
      type: Boolean,
      default: false
    },
    date: {
      type: Date,
      default: undefined
    },
    isRightsToModify: {
      type: Boolean,
      default: undefined
    },
    isRightsToAdd: {
      type: Boolean,
      default: undefined
    }
  },
  data () {
    return {
      component: null,
      expand: []
    }
  },
  computed: {
  },
  watch: {
    expand: {
      handler: function (val) {
        if (this.expand !== this.maximized)
        this.$emit('open', val)
      }
    },
    maximized: {
      handler: function (val) {
        this.expand = val
      }
    }
  },
  mounted () {
    this.expand = this.maximized
  },
   methods: {
    onExpansionPanelClick (event) {
      if(event.currentTarget.classList.contains('v-expansion-panel-header--active')) {
        this.expand[0] = false
      } else {
        this.expand[0] = true
      }
    }
  }
}
</script>
<style scoped>
</style>
