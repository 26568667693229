<template>
  <v-form
    ref="taskForm"
    v-model="validForm"
  >
    <v-container>
      <BaseModal
        @cancel="cancel"
      >
        <template #title>
          {{ task.id ? $t('Edit LTP task') : $t('New LTP task') }}
        </template>
        <template #content>
          <TaskCurrency
            v-if="userCurrencies.length > 1"
            :task="tempTask"
            :currency-list="userCurrencies"
            @change="currencyChanged"
          />
          <TaskBasicInformation
            v-if="fullForm || (!fullForm && step === 1)"
            :task="tempTask"
            :rules="rules"
            @information="informationChanged"
          />
          <TaskBudgeting
            v-if="fullForm || (!fullForm && step === 2)"
            :task="tempTask"
            :rules="rules"
            :currency="selectedCurrency"
            @budgetChange="budgetChanged"
          />
          <TaskScheduling
            v-if="fullForm || (!fullForm && step === 3)"
            :task="tempTask"
            @scheduleChange="scheduleChanged"
          />
          <TaskProjectInformation
            v-if="fullForm || (!fullForm && step === 4)"
            :task="tempTask"
            :rules="rules"
            @personsChange="personsChanged"
          />
          <TaskEsg 
            v-if="hasApplicationPermissionByName('PTS_ESG_TEHTAVAT') && isEsgSelected"
            :task="tempTask"
            :rules="rules"
            :currency="selectedCurrency"
            @esgChanged="esgChanged"
          />
          <TaskComments
            v-if="fullForm || (!fullForm && step === 5)"
            :task="tempTask"
          />
          <TaskConfirmation
            v-if="fullForm || (!fullForm && step === 6)"
            :task="tempTask"
            :rules="rules"
            :currency="selectedCurrency"
            @confirmation="confirmationChanged"
          />
          <!-- <v-stepper v-model="step" vertical>
            <v-stepper-step step="1">
              {{$t('Basic information')}}
            </v-stepper-step>
            <v-stepper-step step="2">
              {{$t('Budgeting')}}
            </v-stepper-step>
            <v-stepper-step step="3">
              {{$t('Task scheduling')}}
            </v-stepper-step>
            <v-stepper-step step="4">
              {{$t('Project information')}}
            </v-stepper-step>
            <v-stepper-step step="5">
              {{$t('Comments')}}
            </v-stepper-step>
            <v-stepper-step step="6">
              {{$t('Confirmation')}}
        </v-stepper-step>-->
          <!-- <v-stepper-items>
              <v-stepper-content step="1"></v-stepper-content>
              <v-stepper-content step="2"></v-stepper-content>
              <v-stepper-content step="3"></v-stepper-content>
              <v-stepper-content step="4"></v-stepper-content>
              <v-stepper-content step="5"></v-stepper-content>
              <v-stepper-content step="6"></v-stepper-content>
            </v-stepper-items>
        </v-stepper>-->
        </template>
        <template #footer>
          <v-btn
            v-if="!fullForm && step < 6"
            class="secondary"
            :disabled="loading"
            @click="saveAndContinue"
          >
            <span v-if="!loading">{{ $t('Save and continue') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
          <v-btn
            rounded
            depressed
            color="primary"
            :disabled="loading"
            @click="saveAndClose"
          >
            <span v-if="!loading">{{ $t('Save and close') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </template>
      </BaseModal>
    </v-container>
  </v-form>
  <!-- </v-col>
  </v-col>-->
</template>
<script>
import TaskBasicInformation from './TaskBasicInformation'
import TaskBudgeting from './TaskBudgeting'
import TaskScheduling from './TaskScheduling'
import TaskProjectInformation from './TaskProjectInformation'
import TaskComments from './TaskComments'
import TaskConfirmation from './TaskConfirmation'
import TaskEsg from './TaskEsg'
import TaskCurrency from './TaskCurrency'
import BaseModal from '../general/BaseModal'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'LTPTaskDialog',
  components: {
    BaseModal,
    TaskBasicInformation,
    TaskBudgeting,
    TaskScheduling,
    TaskProjectInformation,
    TaskComments,
    TaskConfirmation,
    TaskEsg,
    TaskCurrency,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    dialogStep: Number,
    // eslint-disable-next-line vue/require-default-prop
    dialogOpen: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    task: Object
  },
  data () {
    return {
      fullForm: true,
      step: 1,
      schedule: undefined,
      tempTask: { ...this.task },
      loading: false,
      validForm: false,
      rules: {
        buildingName: bName => {
          if (!bName) {
            return this.$t('Give building name')
          }
          return true
        },
        taskName: tName => {
          if (!tName) {
            return this.$t('Give task name')
          }
          return true
        },
        taskClass: tClass => {
          if (!tClass) {
            return this.$t('Give task class')
          }
          return true
        },
        budget: fedBudget => {
          if (!fedBudget && fedBudget !== 0) {
            return this.$t('Give task budget')
          } else if (isNaN(fedBudget)) {
            return this.$t('Give value as number')
          }
          return true
        },
        spentBudget: spentBudget => {
          // Allow empty values
          if (spentBudget === undefined || spentBudget === null) {
            return true;
          }
          if (isNaN(spentBudget)) {
            return this.$t('Give value as number')
          }
          return true
        },
        projectPerson: pPerson => {
          if (pPerson && pPerson.length > 40) {
            return this.$t('Max name length')
          }
          return true
        },
        finishedTime: fTime => {
          if (this.tempTask.finished && !fTime) {
            return this.$t('Give task finishing time')
          }
          return true
        },
        feedbackUser: feedback => {
          if (this.tempTask.finished && !feedback) {
            return this.$t('Give task feedback')
          }
          return true
        },
      },
      previousValue: undefined
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName', 'currencies']),
    ...mapGetters('ltp', ['codes']),
    ...mapState('app', ['settings']),
    ...mapState('ltp', ['esgClasses']),
    selectedCurrency () {
      return this.currencies.find(c => c.id === this.tempTask.currencyId)
    },
    userCurrencies () {
      return this.settings.availableCurrencies.map(ac => { return this.currencies.find(c => c.code === ac) })
    },
    isEsgSelected () {
      // If esg is selected, we should show esg dialog options
      if (this.tempTask.taskCategory && this.tempTask.taskCategory.indexOf('ESG') > -1) {
        return true;
      }
      // Find out class id from the task
      const classId = (this.tempTask.taskCodeId !== undefined && this.tempTask.taskCodeId !== null)
        ? this.codes.find(c => c.id === this.tempTask.taskCodeId)?.classId
        : this.tempTask.taskClassId
      return this.esgClasses.map(c => c.id).includes(classId)
    }
  },
  watch: {
    dialogStep: function (newValue) {
      // new value is set to open up the dialog to correct section
      this.step = newValue
    },
    task: function () {
      this.tempTask = { ...this.task }
    }
  },
  methods: {
    ...mapActions('ltp', ['saveNewTask', 'updateTask']),
    cancel () {
      this.handleDialog(0, false)
      this.tempTask = {}
    },
    changeMode () {
      if (this.fullForm) {
        this.fullForm = false
        this.step = 1
      } else {
        this.fullForm = true
      }
    },
    currencyChanged (currency) {
      this.tempTask.currencyId = currency.currencyId
    },
    budgetChanged (budget) {
      if (!this.tempTask.id) {
        this.tempTask.budget = budget.budget
        this.tempTask.originalBudget = budget.budget
        this.tempTask.budgetSpent = budget.budgetSpent
      } 
      this.tempTask.isInOriginalBusinessPlan = budget.isInOriginalBusinessPlan    
    },
    confirmationChanged (confirmation) {
      // only existing task can be finished
      if (this.tempTask.id) {
        if (confirmation.finished) {
          // save previous value to undo changes if needed and set status finished
          this.previousValue = this.tempTask.implementationStatus
          this.tempTask.implementationStatus = 3
          this.tempTask.finishedTime = confirmation.finishedTime
            ? new Date(confirmation.finishedTime).toISOString()
            : undefined
          this.tempTask.feedback = confirmation.feedback
          this.tempTask.feedbackUser = confirmation.feedbackUser
          this.tempTask.actualCosts = confirmation.actualCosts
          this.tempTask.finished = true
        } else {
          // return previous implementation status or started status
          this.tempTask.implementationStatus = this.previousValue || 2
          this.tempTask.finishedTime = undefined
          this.tempTask.feedback = undefined
          this.tempTask.feedbackUser = undefined
          this.tempTask.actualCosts = undefined
          this.tempTask.finished = false
        }
      }
    },
    personsChanged (persons) {
      this.tempTask.designer = persons.designer
      this.tempTask.builder = persons.builder
      this.tempTask.supervisor = persons.supervisor
      this.tempTask.contractor = persons.contractor
      this.tempTask.responsibility = persons.responsibility
    },
    scheduleChanged (schedule) {
      this.tempTask.implementationStart = new Date(
        schedule.startYear,
        schedule.startMonth,
        15
      ).toISOString()
      this.tempTask.implementationEnd = new Date(
        schedule.endYear,
        schedule.endMonth,
        15
      ).toISOString()
      this.tempTask.implementationStatus = schedule.implementationStatus
      // only new task's original planned time will be set
      if (!this.tempTask.id) {
        this.tempTask.originalPlannedTime = this.tempTask.implementationStart
      }
    },
    informationChanged (info) {
      this.tempTask.buildingId = info.buildingId
      this.tempTask.taskName = info.taskName
      this.tempTask.taskSourceId = info.taskSourceId
      this.tempTask.taskCategory = info.taskCategory
      this.tempTask.taskCodeId = info.taskCodeId
      this.tempTask.taskClassId = info.taskClassId
      this.tempTask.taskCode = info.taskCode
      this.tempTask.taskClass = info.taskClass
      this.tempTask.taskDescription = info.taskDescription
      this.tempTask.projectNumber = info.projectNumber
    },
    esgChanged (esg) {
      this.tempTask.energyEfficiency = esg.energyEfficiency
      this.tempTask.euTaxonomy = esg.euTaxonomy
      this.tempTask.sbti = esg.sbti
      this.tempTask.gbCertification = esg.gbCertification
      this.tempTask.gresb = esg.gresb
      this.tempTask.esgCodeId = esg.esgCodeId
      this.tempTask.production = esg.production
      this.tempTask.energySavings = esg.energySavings
      this.tempTask.co2Reduction = esg.co2Reduction
      this.tempTask.monetarySavings = esg.monetarySavings
    },
    async saveAndContinue () {
      this.loading = true
      if (this.step < 6) {
        if (this.tempTask.id) {
          await this.updateTask(this.tempTask)
          this.loading = false
        } else {
          await this.saveNewTask(this.tempTask)
          this.loading = false
        }
        this.step = this.step + 1
      }
    },
    saveAndClose () {
      this.loading = true
      // If form is valid save new task or update existing
      if (this.$refs.taskForm.validate()) {
        if (this.tempTask.id) {
          this.updateTask(this.tempTask)
          this.tempTask = {}
          this.handleDialog(0, false)
        } else {
          this.saveNewTask(this.tempTask).then(() => {
            this.tempTask = {}
            this.handleDialog(0, false)
          })
        }
      } else {
        this.loading = false
      }
    },
    handleDialog (step, dialogOpen) {
      this.$emit('taskDialogHandling', step, dialogOpen)
    },
  },
}
</script>
<style scoped>
.dialog {
  background-color: #f5f5f5;
}
.dialog-title {
  background-color: #f5f5f5;
  padding: 1em;
  width: 100%;
}
</style>
