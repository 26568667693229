<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <default-search-dialog
      :show-dialog="showDefaultSearchDialog"
      @save="saveSelection"
      @close="toggleDefaultSearchDialog"
    />
    <v-dialog
      v-model="showVisibleColumnsDialog"
      max-width="1000"
      persistent
    >
      <BaseModal
        v-if="showVisibleColumnsDialog"
        @cancel="closeVisibleColumnsDialog"
      >
        <template #title>
          {{ $t('Chosen columns') }}
        </template>
        <template #content>
          <v-row no-gutters>
            <v-col
              v-for="header in sortedHeaders"
              :key="header.value"
              cols="6"
              sm="4"
              md="3"
              style="overflow-x:auto"
            >
              <v-checkbox
                v-model="visibleHeaders"
                :label="`${header.translatedName}`"
                :value="header.value"
              />
            </v-col>
          </v-row>
        </template>
        <template #hideCancel>
          <p />
        </template>
        <template #footer>
          <v-row
            v-if="!showSaveSelectionDialog"
            no-gutters
            class="flex-nowrap"
          >
            <v-select
              v-if="showSaveSelection"
              v-model="selectedViewName"
              :label="$t('Stored views')"
              :items="storedViews"
              :disabled="storedViews.length === 0"
              item-text="name"
              item-value="name"
              clearable
              style="margin-bottom: 5px"
            />
            <v-btn
              v-if="selectedViewName"
              text
              outlined
              rounded
              color="error"
              @click.stop="deleteView"
            >
              {{ $t('Delete view') }}
            </v-btn>
            <v-spacer />
            <ToggleAll
              :aria-label="$t('Columns')"
              :selected="visibleHeaders"
              :all="dataHeaders"
              @toggleUpdated="toggleVisibleHeaders($event)"
            />
            <v-btn
              v-if="showSaveSelection"
              class="primary"
              style="margin-bottom: 4px"
              depressed
              rounded
              @click.stop="showSaveSelectionDialog = true"
            >
              {{ $t('Save selection') }}
            </v-btn>
            <v-btn
              text
              outlined
              rounded
              @click.stop="closeVisibleColumnsDialog"
            >
              {{ $t('Show') }}
            </v-btn>
          </v-row>
          <v-row 
            v-else
            style="min-width:850px; direction: ltr;"
            no-gutters
          >
            <v-text-field
              v-if="showSaveSelection"
              v-model="nameOfSelection"
              :placeholder="$t('Give a name for selection')"
              autofocus
              clearable
              style="border: 0px; margin-bottom: 10px"
            />
            <v-spacer />
            <v-btn
              v-if="showSaveSelection"
              center
              outlined
              rounded
              text
              @click.stop="
                showSaveSelectionDialog = false
                needsConfirm = false
              "
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              v-if="!needsConfirm"
              :class="
                nameOfSelection && nameOfSelection.length > 0 ? 'primary' : null
              "
              :disabled="!nameOfSelection || nameOfSelection.length < 1"
              rounded
              depressed
              @click.stop="saveSelection(nameOfSelection)"
            >
              {{ $t('Save') }}
            </v-btn>
            <v-btn
              v-else
              class="primary"
              outlined
              rounded
              @click.stop="confirmSaveSelection"
            >
              {{ $t('Confirm') }}
            </v-btn>
            <v-btn
              outlined
              rounded
              text
              @click.stop="closeVisibleColumnsDialog"
            >
              {{ $t('Ok') }}
            </v-btn>
          </v-row>
        </template>
      </BaseModal>
    </v-dialog>
    <div
      v-if="showFilters"
    >
      <!-- If this is added, filters panel is closed when using mobile: :multiple="!$vuetify.breakpoint.mobile" -->
      <v-expansion-panels
        :value="[0]"
        multiple
      >
        <v-expansion-panel
          :class="{ 'hide-expansion-panel': noExpansionPanel }"
        >
          <v-expansion-panel-header>
            {{ $t('Filters') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row no-gutters>
              <SkipTo
                :label="$t('skip-to-table')"
                :to="'#' + tableId"
              />
              <v-col
                v-for="header in currentVisibleFilterHeaders.filter((h) => h.actionHeader !== true).map((h) => {
                  return {
                    text: $t(h.text),
                    value: h.value,
                    isDefinition: h.isDefinition,
                    type: h.format,
                    isMultiSelect: h.isMultiSelect
                  }
                })"
                :key="header.value"
                class="widthAdjust"
              >
                <!-- This is the multiselect field, quite similar to billing acrhive searches applied to one field -->
                <v-autocomplete
                  v-if="header.isMultiSelect"
                  :id="headerId(header.value)"
                  small-chips
                  clearable
                  deletable-chips
                  multiple
                  hide-details
                  :items="multiSelectKeys[header.value]"
                  item-text="text"
                  item-value="value"
                  :label="header.text"
                  :menu-props="{ maxHeight: 400 }"
                  class="multiselect_autocomplete"
                  :value="multiSelectValue(header.value)"
                  @change="(e) => onFilterValueChange(header.value, e)"
                >
                  <template #prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="multiSelectAll(header.value)"
                    >
                      <v-list-item-action>
                        <v-icon>
                          {{ icon(header.value) }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ multiSelectAllSelected.get(header.value) ? $t('site_filters.deselect') : $t('site_filters.select') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2" />
                  </template>
                </v-autocomplete>

                <!-- This select gets its content from the parent component -->
                <v-select 
                  v-else-if="header.type == 'Array' && hasFilterContent(header.value)"
                  id="hasFilterContent"
                  :item-text="item.text"
                  :item-value="item.value"
                  :label="header.text"
                  :items="getFilterContent(header.value)"
                  clearable
                  @change="(e) => onFilterValueChange(header.value, e)"
                />
                <v-select
                  v-else-if="header.type == 'Array'"
                  :id="headerId(header.value)"
                  :item-text="item.text"
                  :item-value="item.value"
                  :label="header.text"
                  :items="selectArrayChoices(header.value)"
                  clearable
                  @change="(e) => onFilterValueChange(header.value, e)"
                />
                <!-- Tän voisi joskus muuttaa niin että aukeaa kalenteri ja siitä valitaan päivä -->
                <v-text-field
                  v-else-if="header.type == 'Date'"
                  :id="headerId(header.value)"
                  :value="filterValues[header.value]"
                  :label="header.text"
                  clearable
                  @change="(e) => onFilterValueChange(header.value, e)"
                  @input="(e) => onFilterValueChange(header.value, e)"
                />
                <v-text-field
                  v-else-if="header.type == 'Time'"
                  :id="headerId(header.value)"
                  :value="filterValues[header.value]"
                  :label="header.text"
                  clearable
                  @change="(e) => onFilterValueChange(header.value, e)"
                  @input="(e) => onFilterValueChange(header.value, e)"
                />
                <v-text-field
                  v-else
                  :id="headerId(header.value)"
                  :value="filterValues[header.value]"
                  :label="header.text"
                  :type="header.type"
                  clearable
                  @input="(e) => onFilterValueChange(header.value, e)"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div>
      <v-expansion-panels
        :value="[0]"
        multiple
      >
        <v-expansion-panel
          :class="{ 'hide-expansion-panel': noExpansionPanel }"
        >
          <v-expansion-panel-header>
            {{ $t('Information') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :id="tableId"
              :header-props="dataTableHeaderDefinitions"
              :headers="
                currentVisibleHeaders.filter((h) => h.actionHeader !== true).map((h) => {
                  return {
                    text: $t(h.text),
                    value: h.value,
                    isDefinition: h.isDefinition,
                    width: h.customWidth,
                    sortable: h.sortable != null ? h.sortable : true,
                    sort: h.sort
                  }
                })
              "
              :items="currentVisibleRows"
              :options.sync="options"
              :server-items-length="getDataRowCount"
              :footer-props="{
                itemsPerPageOptions: [],
              }"
              :no-data-text="$t('No data available')"
              :loading-text="$t('Loading. Please wait...')"
              :loading="loadingStatus"
              mobile-breakpoint="0"
              class="elevation-0 viewsize-scroll"
              locale="fi-FI"
            >     
              <template
                slot="item"
                slot-scope="props"
              >
                <tr>
                  <template v-for="header in currentVisibleHeaders.filter((h) => h.value !== '_actions' && h.actionHeader !== true)">
                    <!-- Site link -->
                    <td
                      v-if="header.link"
                      :key="header.value"
                    >
                      <button
                        type="button"
                        class="linked"
                        @click="linkTo(props.item[header.link.target], header.link.path)"
                      > 
                        {{ formatData(props.item, header.value, header.format, true) }}
                      </button>
                    </td>
                    <td
                      v-else-if="header.isDefinition === true"
                      :key="header.value"
                    >
                      <div
                        :class="{
                          'col-align-right': isRightAligned(header.format),
                        }"
                      >
                        <span v-html="Sanitize(getDefinitionLabel(props.item[header.value]))" />
                      </div>
                    </td>
                    <td
                      v-else-if="header.value === 'alertStatus'"
                      :key="header.value"
                      class="py-1"
                    >
                      <v-chip 
                        :color="props.item.alertStatus.color"
                        small
                      >
                        {{ $t(props.item.alertStatus.label) }}
                      </v-chip>
                    </td>
                    <td
                      v-else-if="header.value === 'site_name' || [ $t('Site'), 'Site', $t('Site name') ].includes(header.text) && ((!props.item.idSite && !currentSiteId) && props.item.idSite != currentSiteId)"
                      :key="header.value"
                      :class="{ 
                        'col-align-right': isRightAligned(header.format),
                      }"
                      class="linked"
                      @click="linkTo(props.item, 'sites.facilitymanagement')"
                    >
                      <a 
                        :href="getHref(props.item)"
                        onclick="return false"
                      > 
                        <!--return false so user cant use href link, except on the case user wants to right click link and select "open in new tab"-->
                        {{ formatData(props.item, header.value, header.format, true) }}
                      </a> 
                    </td>
                    <td
                      v-else-if="header.value === 'scheme_specification'"
                      :key="header.value"
                      class="doc-tags"
                    >
                      <div
                        v-if="props.item.scheme_specification.length > 0"
                        style="display: block; max-width: 100%"
                      >
                        <v-chip
                          v-for="spec in props.item.scheme_specification"
                          :key="spec.name"
                          small
                          outlined
                        >
                          {{ spec.name }}
                        </v-chip>
                      </div>
                      <div
                        v-else
                        style="color: #000000de !important"
                      >
                        {{ formatData(props.item.scheme_specification, header.value, header.format) }}
                      </div>
                    </td>
                    <td
                      v-else-if="header.value === 'unit_names'"
                      :key="header.value"
                    >
                      <div
                        v-for="unit in getUnitNames(props.item.contractId, props.item.siteId !== undefined ? props.item.siteId : props.item.id_site)" 
                        :key="unit.unit_name"
                        :class="getLinkStyle(unit)"
                        @click="unit.unit_id ? 
                          routeTo(props.item.siteId !== undefined ? props.item.siteId : props.item.id_site, 
                                  unit.unit_id) : null"
                      >
                        <div>
                          {{ escapeHtml(unit.unit_name) }}
                        </div>
                      </div>
                    </td>
                    <td
                      v-else-if="header.unitLink"
                      :key="header.value"
                    >
                      <div
                        :key="props.item.unitId !== undefined ? props.item.unitId : props.item.unitIdentifier"
                        :class="getLinkStyle(props.item)"
                        @click="(props.item.unitId !== undefined ? props.item.unitId : 
                          (props.item.unitIdentifier !== undefined ? props.item.unitIdentifier : props.item.id_unit)) ? 
                          routeTo(props.item.siteId !== undefined ? props.item.siteId : 
                                    (props.item.siteIdentifier !== undefined ? props.item.siteIdentifier : props.item.id_site), 
                                  props.item.unitId !== undefined ? props.item.unitId : 
                                  (props.item.unitIdentifier !== undefined ? props.item.unitIdentifier : props.item.id_unit)) : null"
                      >
                        <div>
                          {{ escapeHtml(props.item[header.value]) }}
                        </div>
                      </div>
                    </td>
                    <td
                      v-else-if="header.value === 'buildingproject_number' && props.item.id_buildingproject"
                      :key="header.value"
                    >
                      <button
                        :key="header.value"
                        :class="getLinkStyle(props.item)"
                        @click="showBuildingProjectValueInformation = true; formatBuildingProject(props.item)"
                      >
                        {{ formatData(props.item, header.value, header.format, true) }}
                      </button>
                    </td>
                    <td
                      v-else-if="header.value === 'external_url' || header.format === 'externalLink'"
                      :key="header.value"
                    >
                      <a
                        :href="formatData(props.item, header.value, header.format, header)"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <v-icon>open_in_new</v-icon>
                        <span class="d-sr-only">{{ $t('building_projects.OpenInNewWindow') }}</span>
                      </a>
                    </td>
                    <td
                      v-else-if="header.value === 'file'"
                      :key="header.value"
                    >
                      <div
                        :key="header.value"
                        :class="getLinkStyle(props.item)"
                        @click="linkTo(props.item, 'sites.building_document_download')"
                      >
                        <div>
                          {{ escapeHtml(props.item.file) }}
                        </div>
                      </div>
                    </td>
                    <LeasingTableColumns
                      v-else-if="header.leasing && (
                        header.value === 'process_title' ||
                        header.value === 'prospect_type_text' ||
                        header.value === 'prospect_fullscreen_status' ||
                        header.value === 'prospect_status' ||
                        header.value === 'corporation_name' ||
                        header.value === 'success_likelihood' ||
                        header.value === 'prospect_description' ||
                        header.value === 'renewal' ||
                        header.value === 'renegotiations' ||
                        header.value === 'confirmed' ||
                        header.value === 'prediction' ||
                        header.value === 'target' ||
                        header.value === 'currentFree' ||
                        header.value === 'tenant' ||
                        header.value === 'rental_type_translated' ||
                        header.value === 'rentalSituation' ||
                        header.value === 'siteRentalSituation' || 
                        header.value === 'termination_reason' || 
                        header.value === 'unit_code_time_alert' || 
                        header.value === 'unit_end_date_alert' ||
                        header.value === 'rentingProcessTitle')
                      "
                      :key="header.value"
                      :props="props"
                      :header="header"
                      :items="rows"
                      @getProspects="$emit('getProspects', $event)"
                    />
                    <td
                      v-else-if="header.value === 'sites' && header.multiple"
                      :key="header.value"
                      class="min-width"
                    >
                      <template
                        v-for="(site, index) in props.item[header.value]"
                      >
                        <span
                          :key="index"
                          :class="{ linked: site.id_site != null }"
                          @click="linkTo(site, 'sites.info')"
                        >
                          {{ site.name }}
                          <br>
                        </span>
                      </template>
                    </td>
                    <td
                      v-else-if="header.value === 'panorama_link' || header.value === 'video_link'" 
                      :key="header.value"
                      @click="openLink(props.item[header.value])"
                    >
                      <div
                        class="linked"
                      >
                        {{ formatData(props.item, header.value, header.format) }}
                      </div>
                    </td>
                    <td
                      v-else-if="header.value === 'linked_photos'" 
                      :key="header.value"
                    >
                      <v-row
                        class="flex-column"
                      >
                        <v-col
                          v-for="item in (props.item[header.value])"
                          :key="item.url"
                          class="linked"
                          @click="openLink(item.url)"
                        >
                          {{ item.value }}
                        </v-col>
                      </v-row>
                    </td>
                    <td
                      v-else-if="header.value === 'auditDirection'"
                      :key="header.value"
                    >
                      <v-icon
                        v-if="props.item[header.value] === $t('HANDOVER')"
                        style="color: rgb(113,188,241);"
                      >
                        arrow_circle_left
                      </v-icon>
                      <v-icon
                        v-else
                      >
                        arrow_circle_right
                      </v-icon>
                      {{ $t(props.item[header.value]) }}
                    </td>
                    <td
                      v-else-if="header.value === 'auditStatus'"
                      :key="header.value"
                    >
                      {{ props.item[header.value] }}
                      <v-icon 
                        v-if="props.item[header.value] === $t('COMPLETED')"
                        style="color: rgb(76,175,80);"
                      >
                        check_circle
                      </v-icon>
                      <v-icon
                        v-else-if="props.item[header.value] === $t('INPROGRESS')"
                        style="color: rgb(255,173,119);"
                      >
                        error
                      </v-icon>
                    </td>
                    <td
                      v-else-if="header.value === 'rental_situation'"
                      :key="header.value"
                    >
                      <RentalSituationColumn 
                        :header="header"
                        :props="props"
                        :item="props.item"
                        :browse="false"
                      />
                    </td>
                    <HideOverflowColumn
                      v-else-if="header.hideOverflow"
                      :key="'hideOverflow' + header.value"
                      :header="header"
                      :props="props"
                      :item="props.item"
                      :browse="false"
                    />
                    </td>
                    <td
                      v-else-if="header.value === 'unit_version_status'"
                      :key="header.value"
                    >
                      <unit-version-status-column 
                        :header="header" 
                        :props="props"
                        :item="props.item"
                        :browse="false"
                      />
                    </td>
                    <HideOverflowColumn
                      v-else-if="header.hideOverflow"
                      :key="'hideOverflow' + header.value"
                      :header="header"
                      :item="props.item"
                    />
                    <td
                      v-else-if="header.type === 'chipField'"
                      :key="header.value"
                    >
                      <ChipField
                        :items="props.item[header.value]"
                      />
                    </td>
                    <td
                      v-else-if="header.value === 'customComponent'"
                      :key="'customComponent' + header.value"
                    >
                      <slot
                        v-if="props.item.showCustomComponent !== false"
                        :item="props.item"
                      />
                    </td><td
                      v-else-if="header.type === 'chipField'"
                      :key="header.value"
                    >
                      <ChipField
                        :items="props.item[header.value]"
                      />
                    </td>
                    <td
                      v-else-if="header.value === 'customComponent'"
                      :key="'customComponent' + header.value"
                    >
                      <slot
                        v-if="props.item.showCustomComponent !== false"
                        :item="props.item"
                        name="customComponent"
                      />
                    </td>
                    <td
                      v-else-if="header.value === 'contract_number'"
                      :key="header.value"
                    >
                      <button
                        type="button"
                        :class="{
                          'col-align-right': isRightAligned(header.format),
                          'linked': !!header.event
                        }"
                        :aria-label="$t('ViewTheContract') + ' ' + formatData(props.item, header.value, header.format)"
                        @click="() => {
                          editRow(props.item)

                          if(header.event){
                            $emit('triggerCustomEvents', { eventName: header.event, row: props.item })
                          }
                        }"
                      >
                        <span v-html="Sanitize(formatData(props.item, header.value, header.format))" />
                      </button>
                    </td>
                    <td
                      v-else-if="(header.valueFromArray && typeof header.valueIndex !== 'undefined')"
                      :key="header.key"
                      :class="{'col-align-right': isRightAligned(header.format)}"
                    >
                      <span>
                        <span v-html="Sanitize(getFormattedValueFromArray(props.item, header))" />
                      </span>
                    </td>
                    <td
                      v-else-if="header.value !== 'general_rating' && 
                        header.value !== 'report_date' && 
                        header.value !== 'taskDate' ||
                        (header.value === 'taskDate' && props.item.alertStatus.label !== 'alert.not_completed')"
                      :key="header.value"
                      :class="{'col-align-right': isRightAligned(header.format)}"
                    >
                      <span
                        v-if="!!header.event && Sanitize(formatData(props.item, header.value, header.format)) !== '-' && 
                          (header.value !== 'alertIdentifier' || (header.value === 'alertIdentifier' && props.item.idContract))"
                      >
                        <button
                          :class="{
                            'linked': !!header.event
                          }"
                          @click="$emit('triggerCustomEvents', { eventName: header.event, row: props.item })"
                        >
                          <span v-html="Sanitize(formatData(props.item, header.value, header.format))" />
                        </button>
                      </span>
                      <span v-else>
                        <span v-html="Sanitize(formatData(props.item, header.value, header.format))" />
                      </span>
                    </td>
                    <td
                      v-else
                      :key="header.value"
                    >
                      <v-row
                        no-gutters
                        class="flex-nowrap"
                      >
                        <v-col>
                          <StatusIcon
                            :field="
                              getObjectWithUnit(props.item[header.value], header.value)
                            "
                            :data="
                              formatData(props.item, header.value, header.format)
                            "
                          />
                        </v-col>
                        <v-col
                          :key="header.value"
                          class="d-flex align-center justify-end text-no-wrap"
                        >
                          {{ formatData(props.item, header.value, header.format) }}
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                  <td
                    v-if="showControls || showEditControl || props.item.removeAble || props.item.editableRow"
                  >
                    <v-row
                      justify="center"
                      class="flex-nowrap"
                    >
                      <slot
                        :row="props.item"
                        name="row-actions"
                      >
                        <v-btn
                          v-if="showControls || (showEditControl && !props.item.editableRow)"
                          icon
                          @click="editRow(props.item)"
                        >
                          <v-icon
                            small
                          >
                            edit
                          </v-icon>
                          <span class="d-sr-only">{{ $t('Modify') }}</span>
                        </v-btn>

                        <v-btn
                          v-if="showControls || props.item.removeAble"
                          icon
                          @click="removeRow(props.item)"
                        >
                          <v-icon
                            small
                          >
                            delete
                          </v-icon>
                          <span class="d-sr-only">{{ $t('Delete') }}</span>
                        </v-btn>
                        <v-btn
                          v-if="showEditControl && props.item.editableRow"
                          icon
                          @click="editRow(props.item)"
                        >
                          <v-icon
                            small
                          >
                            edit
                          </v-icon>
                          <span class="d-sr-only">{{ $t('Modify') }}</span>
                        </v-btn>
                      </slot>
                    </v-row>
                  </td>
                  <td
                    v-if="isReadOnly"
                  >
                    <v-row
                      justify="center" 
                      class="flex-nowrap"
                    >
                      <slot
                        :row="props.item"
                        name="row-actions"
                      >
                        <v-icon
                          medium
                          @click="editRow(props.item)"
                        >
                          remove_red_eye
                        </v-icon>
                      </slot>
                    </v-row>
                  </td>
                </tr>
              </template>
              <template slot="body.append">
                <tr
                  v-for="(val, idx) in dataFooters"
                  :key="idx"
                >
                  <td
                    v-for="header in currentVisibleHeaders.filter(
                      (h) => h.value !== '_actions'
                    )"
                    :key="header.value"
                    :class="{
                      'col-align-right': isRightAligned(header.format)
                    }"
                    v-html="Sanitize(formatData(dataFooters[idx], header.value, header.format, true))"
                  />
                </tr>
              </template>
              <template
                slot="footer.page-text"
                slot-scope="item"
              >
                <div
                  v-if="!$vuetify.breakpoint.mobile"
                >
                  <slot name="table-buttons" />
                  <v-select
                    v-if="showRowsPerPageSelection"
                    v-model="options.itemsPerPage"
                    :label="$t('Choose rows per page')"
                    :items="getPaginationArray(item ? item.itemsLength : 0)"
                    class="mt-4"
                    item-text="text"
                    item-value="value"
                    @change="onRowsPerPageChange"
                  />
                  <v-btn
                    v-if="!hide"
                    small
                    outlined
                    rounded
                    @click="showVisibleColumnsDialog = true"
                  >
                    {{ $t('Choose visible columns') }}
                  </v-btn>
                  <v-btn
                    v-if="!hide"
                    small
                    outlined
                    rounded
                    @click="exportAs('pdf')"
                  >
                    {{ $t('Export to PDF') }}
                  </v-btn>
                  <v-btn
                    v-if="!hide"
                    small
                    outlined
                    rounded
                    @click="exportAs('csv')"
                  >
                    {{ $t('Export to spreadsheet') }}
                  </v-btn>
                  <span>{{ formatThousands(item.pageStart) }}-{{ formatThousands(item.pageStop) }} {{ $t('of') }}</span>
                  <span id="table_items_length">{{ item.itemsLength.toLocaleString('fi-FI') }}</span>
                </div>
                <div 
                  v-if="$vuetify.breakpoint.mobile"
                  class="buttons-container2"
                >
                  <span>{{ formatThousands(item.pageStart) }}-{{ formatThousands(item.pageStop) }} {{ $t('of') }}</span>
                  <span id="table_items_length">{{ item.itemsLength.toLocaleString('fi-FI') }}</span>
                </div>
              </template>
            </v-data-table>
            <div
              v-if="$vuetify.breakpoint.mobile"
              class="buttons-container"
            >
              <slot name="table-buttons" />
              <v-select
                v-if="showRowsPerPageSelection"
                v-model="options.itemsPerPage"
                :label="$t('Choose rows per page')"
                :items="getPaginationArray(item ? item.itemsLength : 0)"
                class="mt-4"
                item-text="text"
                item-value="value"
              />
              <v-btn
                v-if="!hide"
                small
                outlined
                rounded
                @click="showVisibleColumnsDialog = true"
              >
                {{ $t('Choose visible columns') }}
              </v-btn>
              <v-btn
                v-if="!hide"
                small
                outlined
                rounded
                @click="exportAs('pdf')"
              >
                {{ $t('Export to PDF') }}
              </v-btn>
              <v-btn
                v-if="!hide"
                small
                outlined
                rounded
                @click="exportAs('csv')"
              >
                {{ $t('Export to spreadsheet') }}
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-dialog
      v-model="showBuildingProjectValueInformation"
      persistent
      max-width="1000"
    >
      <BuildingProjectValueInformation
        :building-project-id="buildingProjectId"
        :building-project="buildingProject"
        @cancel="showBuildingProjectValueInformation = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import helpers from '../helpers'
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import {mapActions, mapGetters, mapState} from 'vuex'
import moment from 'moment'
import sanitizeHtml from 'sanitize-html'
import StatusIcon from '../components/StatusIcon'
import BaseModal from './general/BaseModal'
import SkipTo from './general/SkipTo'
import ToggleAll from './general/ToggleAll'
import LeasingTableColumns from '../components/Leasing/LeasingTableColumns'
import RentalSituationColumn from '../components/Leasing/LeasingColumns/RentalSituationColumn.vue'
import UnitVersionStatusColumn from '../components/Leasing/LeasingColumns/UnitVersionStatusColumn.vue'
import BuildingProjectValueInformation from './BuildingProjectValueInformation.vue'
import DefaultSearchDialog from './DefaultSearchDialog.vue'
import HideOverflowColumn from '../components/general/HideOverflowColumn.vue'
import ChipField from '../components/general/ChipField.vue'

export default {
  name: 'Table',
  components: {
    StatusIcon,
    LeasingTableColumns,
    BaseModal,
    SkipTo,
    ToggleAll,
    RentalSituationColumn,
    BuildingProjectValueInformation,
    DefaultSearchDialog,
    HideOverflowColumn,
    ChipField,
    UnitVersionStatusColumn,
  },
  props: {
    rows: {
      default: () => {
        return []
      },
      type: Array
    },
    headers: {
      default: () => {
        return []
      },
      type: Array
    },
    footers: {
      default: () => {
        return []
      },
      type: [Function, Array]
    },
    footerDefinitions: {
      default: () => {
        return []
      },
      type: Array
    },
    pagination: {
      default: () => {
        return {
          sortDesc: [true],
          itemsPerPage: -1
        }
      },
      type: Object
    },
    emitPagination: {
      default: false,
      type: Boolean
    },
    rowCount: {
      default: 0,
      type: Number
    },
    rowsLoading: {
      default: false,
      type: Boolean
    },
    showControls: { 
      default: false, 
      type: Boolean
    },
    showEditControl: { 
      default: false, 
      type: Boolean
    },
    isReadOnly: {
      default: false, 
      type: Boolean
    },
    showFilters: {
      default: () => {
        return true
      },
      type: Boolean
    },
    noExpansionPanel: { 
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Function,
      default: undefined,
    },
    hide: {
      type: Boolean,
      default: false
    },
    filterByCostcenter: {
      type: Boolean,
      default: false
    },
    showRowsPerPageSelection: {
      default: () => {
        return false
      },
      type: Boolean
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    showDefaultSearch: {
      type: Boolean,
      default: false
    },
    documentType: {
      default: () => {
        return []
      },
      type: Array
    },
    showSaveSelection: {
      default: () => {
        return false
      },
      type: Boolean
    },
    selectedViewString: {
      default: () => {
        return null
      },
      type: String
    },
    storedViews: {
      default: () => {
        return []
      },
      type: Array
    },
    showConfirm: {
      default: () => {
        return false
      },
      type: Boolean
    },
    showActiveAssigmentsOnly: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    customFormatters: {
      type: Object,
      default: () => {
        return {}
      }
    },
    outRent: {
      type: Boolean,
      default: undefined
    },
    selectedContractStates: {
      type: Array,
      default: () => {
        return []
      }
    },
    // You can provide function for getting export data if it differs from data shown in table.
    getExportData: {
      type: Function,
      default: null
    },
    getContractExportData: {
      type: Function,
      default: null
    },
    getContractPaymentsExportData: {
      type: Function,
      default: null
    },
    customFilterContent: {
      type: Object,
      default: () => {
        return {}
      }
    },
    multiSelectKeys: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      options: {},
      dataRows: [],
      dataHeaders: [],
      dataFooters: [],
      visibleHeaders: [],
      visibleRows: [],
      filterValues: {},
      showVisibleColumnsDialog: false,
      showDefaultSearchDialog: false,
      showSaveSelectionDialog: false,
      totalDataRows: 0,
      loading: true,
      nameOfSelection: null,
      needsConfirm: false,
      selectedView: null,
      selectedViewName: null,
      costcenters: [],
      awaitingSearch: false,
      filterTimeouts: [],
      showBuildingProjectValueInformation: false,
      buildingProjectId: '',
      buildingProject: null,
      tableId: null,
      item: '',
      value: '',
      multiSelectAllSelected: new Map(),
      multiSelectSomeSelected: new Map(),
      multiSelectDelimiter: String.fromCharCode(12),
      pageSizeInitialized: false,
      initialPageSize: undefined,
      dataMissing: ''
    }
  },
  computed: {
    ...mapState('sites', ['currentSiteId']),
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionLabelById', 'dataTableHeaderDefinitions', 'currencies']),
    ...mapState('app', ['purposeZones', 'purposesOfUse', 'currentDate']),
    useDataSource () {
      if (typeof this.dataSource === 'function') {
        return true
      }
      return false
    },
    sortedHeaders () {
      return [...this.dataHeaders].sort((a, b) =>
        a.translatedName.localeCompare(b.translatedName)
      )
    },
    currentVisibleRows () {
      const fValues = {...this.filterValues}
      const fHeaders = {...this.headers}
      if (this.emitPagination) {
        return this.dataRows
      }
      const currentFilters = this.getCurrentFilters()
      const currentRows = this.dataRows.filter(row => {
        // iterate over columns
        const filteredHeaders = Object.keys(currentFilters)
        //If row has any filtered column without a value that row can be filtered out 
        filteredHeaders.forEach(h => {
          if(row[h] === undefined)
          return false
        })
        for (let h = 0; h < filteredHeaders.length; h++) {
          const headerWithAllInfo = this.dataHeaders.filter(ah => ah.value === filteredHeaders[h])
          const header = filteredHeaders[h]
          let rowValue = ''
          let currentHeaderObj = this.headers.filter(h => h.value === header)
          if (currentHeaderObj.length === 0) {
            continue
          }
          if (currentHeaderObj[0].isMultiSelect) {
            const queryValues = currentFilters[header].split(this.multiSelectDelimiter).
              filter(queryValue => queryValue.trim() !== '').
              map(queryValue => queryValue !== 'NULL' ? queryValue : '0')
            if (queryValues.length > 0) {
              if (this.multiSelectKeys.multiSelectOptionTypes[header] === 3) {
                if (queryValues.some(queryValue => row['has_dwg'].indexOf(queryValue) >= 0)) {
                  continue
                } else {
                  return false
                }
              } else if (this.multiSelectKeys.multiSelectOptionTypes[header] === 2) {
                if (queryValues.some(queryValue => {
                  const optionOfHeader = this.multiSelectKeys[header].filter(
                    multiSelectKeyOfHeader => { 
                      if (multiSelectKeyOfHeader.value === 'NULL') {
                        return queryValue === '0'
                      }
                      return multiSelectKeyOfHeader.value.toString() === queryValue
                    }).map(option => option.text)[0]
                  if (row[header] === undefined || row[header] === null) {
                    return optionOfHeader === '-'
                  }
                  return row[header].trim() === optionOfHeader.trim()
                })) {
                  continue
                } else {
                  return false
                }
              } else {
                const idField = row[header + '_id']
                let rowValues = idField
                if (rowValues === null) {
                  return queryValues.includes('0')
                }
                if (typeof idField === 'number') {
                  rowValues = idField.toString()
                }
                rowValues = rowValues.split(',')
                if (queryValues.some(queryValue => rowValues.includes(queryValue))) {
                  continue
                } else {
                  return false
                }
              }
            } else {
              continue
            }
          } else if (headerWithAllInfo[0] && headerWithAllInfo[0].isDefinition === true) {
            rowValue = this.getDefinitionLabel(row[header])
          } else if (headerWithAllInfo[0] && headerWithAllInfo[0].isPurposeOfUse === true) {
            rowValue = this.getPurposeName(row[header])
          } else if (headerWithAllInfo[0] && headerWithAllInfo[0].isPurposeZone === true) {
            rowValue = this.getPurposeZone(row[header])
          } else if (headerWithAllInfo[0] && headerWithAllInfo[0].isExtraField === true) {
            rowValue = this.getCorrectCostCenter(row) 
          } else if (headerWithAllInfo[0] && headerWithAllInfo[0].multiple === true) {
            rowValue = Object.values(row[header]).map(obj => obj.name).join(', ')
          } else if (header === 'floorAreaMax' || header === 'floorAreaMin') {
            row.net_floor_area = row.net_floor_area == 0 ? row.building_net_floor_area_from_space : row.net_floor_area
          } else if (this.hasFilterContent(header)) {
            //get the untranslated value from the select filter's items
            rowValue = this.getFilterContent(header)?.filter(option => option?.text === row[header])?.[0]?.value
            rowValue ??= row[header].toString()
          } else if (headerWithAllInfo[0] && headerWithAllInfo[0].type === 'chipField' && Array.isArray(row[header])) {
            rowValue = row[header].map(chip => chip?.name ?? '').join(' ')
          } else {
            rowValue = row[header]
          }
          let queryValueDate
          if (headerWithAllInfo[0] && headerWithAllInfo[0].format === 'Date')
          {
            rowValue = moment(row[header]).format('D.M.YYYY')
            queryValueDate = currentFilters[header].split('-')
          }

          if (headerWithAllInfo[0] && headerWithAllInfo[0].format === 'Time')
          {
            rowValue = moment(row[header]).format('D.M.YYYY h.mm')
            queryValueDate = currentFilters[header].split('-')
          }

          if (typeof rowValue !== 'undefined') {
            rowValue = helpers.format.formatData(rowValue, header.format)
          }

          if (typeof rowValue === 'number') {
            // TODO: implement more generic solution; problem now: comma used both to separate search entry and as decimal point
            if (headerWithAllInfo[0].format === 'Percentage') {
              rowValue = rowValue.toFixed(2)
            }
            rowValue = '' + rowValue // tostring
          }

          if(headerWithAllInfo[0] && headerWithAllInfo[0].format === 'List') {
            rowValue = rowValue.map(s => s.name).join(', ')
          }
          if (headerWithAllInfo[0] && headerWithAllInfo[0].format === 'Translation') {
            rowValue = this.$t(rowValue)
          } 

          if (Array.isArray(rowValue) && header !== 'assignments') {
            if (rowValue.includes(x => x.groupName)) {
              rowValue = rowValue.map(x => x.groupName).join(', ')
            } else {
              rowValue = rowValue.join(', ')  
            }
          }

          const queryValue = currentFilters[header].split(',')
          const floorAreaMin = currentFilters.floorAreaMin
          const floorAreaMax = currentFilters.floorAreaMax

          if (typeof rowValue === 'string') {
            let found = false
            if (header === 'floorAreaMax' || header === 'floorAreaMin') {
              if (floorAreaMin && floorAreaMax) {
                if (row.net_floor_area >= floorAreaMin && row.net_floor_area <= floorAreaMax) {
                  found = true
                }
              }
              else if (floorAreaMin && row.net_floor_area >= floorAreaMin) {
                found = true
              }
              else if (floorAreaMax && row.net_floor_area <= floorAreaMax) {
                found = true
              }
            }
            else if (queryValueDate !== undefined)
            {
              if(queryValueDate.length === 1)
              {
                if(rowValue.toString().startsWith(queryValueDate[0].trim()))
                found = true
              }
              //This is when there are two dates separated with "-" as filter
              else if(queryValueDate.length === 2)
              {
                let date1 = moment(queryValueDate[0].trim(), ['D.M.YYYY'])
                let date2 = moment(queryValueDate[1].trim(), ['D.M.YYYY'])

                if(date1.isValid() && date2.isValid())
                {
                  let start = date1.isBefore(date2) ? date1 : date2
                  let end = date1.isAfter(date2) ? date1 : date2
                  let rowMoment = moment(row[header])

                  if(rowMoment.isValid() && rowMoment.isBetween(start.add(-1, 'days'), end.add(1, 'days')))
                  {
                    found = true
                  }
                }
              }
            }
            else {
              for (let i = 0; i < queryValue.length; i++) {
                if (
                  rowValue.toLowerCase().indexOf(queryValue[i].toLowerCase()) >= 0
                ) {
                  found = true
                }
              }
            } 
            // All option
            if (!found) {
              return false
            }
          }
          else if (header === 'assignments')
          {
            //Generate the same string as on the table row for filter comparing
            let rowData = rowValue.map(x => (`${this.$t(this.getDefinitionLabel(x.idDefinition))}: ${this.createSingleAssignmentString(x)}`).toLowerCase())
            for (const value of queryValue) {
              if(rowData.some(x => x.includes(value.toLowerCase())))
              {
                return true
              }
            }

            return false
          }
          // If row value is empty in filtered column or row value does not exist at all it is left out
          if (rowValue === null || rowValue === undefined) {
            return false
          }
        }

        return true
      })
      this.adjustPageOptionsByRowCount(currentRows.length)
      this.$emit('updatedCurrentRows', currentRows)
      return currentRows
    },
    currentVisibleHeaders () {
      const headers = this.dataHeaders.filter(
        h => this.visibleHeaders.indexOf(h.value) >= 0
      )
      if (this.showControls || this.showEditControl) {
        headers.push({ text: this.$t('Actions'), value: '_actions' })
      }
      if (this.isReadOnly) {
        headers.push({ text: this.$t('Read only'), value: '_actions'})
      }
      return headers
    },
    currentVisibleFilterHeaders () {
      const filterFields = this.dataHeaders.filter((
        h // Add header value if is not included in hideFilters
      ) => {
        if (this.hideFilters(h.value) || h.hideFilter === true) {
          return false
        } else {
          return this.visibleHeaders.indexOf(h.value) >= 0
        }
      })
      // Add floor area min and floor area max if visibleHeaders includes net_floor_area, 'building_net_floor_area' or 'building_net_floor_area_from_space'
      if (this.visibleHeaders.includes('net_floor_area') || this.visibleHeaders.includes('building_net_floor_area')) {
        filterFields.push({
          value: 'floorAreaMin',
          text: 'Net floor area min',
          format: 'Number'
        })
        filterFields.push({
          value: 'floorAreaMax',
          text: 'Net floor area max',
          format: 'Number'
        })
      } else if (this.visibleHeaders.includes('building_net_floor_area_from_space')) {
        filterFields.push({
          value: 'buildingFloorAreaMin',
          text: 'Net floor area min',
          format: 'Number'
        })
        filterFields.push({
          value: 'buildingFloorAreaMax',
          text: 'Net floor area max',
          format: 'Number'
        })
      }
      if (this.visibleHeaders.includes('completed_year')) {
        filterFields.push({
          value: 'completedYearMin',
          text: 'Completed year min',
          format: 'Number'
        })
        filterFields.push({
          value: 'completedYearMax',
          text: 'Completed year max',
          format: 'Number'
        })
      }
      // Add floor area min and floor area max if visibleHeaders includes contract_netfloorarea, also add min and max date -filters
      if (this.visibleHeaders.includes('contract_netfloorarea')) {
        filterFields.push({
          value: 'contractFloorAreaMin',
          text: 'Net floor area min',
          format: 'Number'
        })
        filterFields.push({
          value: 'contractFloorAreaMax',
          text: 'Net floor area max',
          format: 'Number'
        })
        filterFields.push({
          value: 'contractStartDateMin',
          text: 'Start date min',
          format: 'String'
        })
        filterFields.push({
          value: 'contractStartDateMax',
          text: 'Start date max',
          format: 'String'
        })
        filterFields.push({
          value: 'contractEndDateMin',
          text: 'End date min',
          format: 'String'
        })
        filterFields.push({
          value: 'contractEndDateMax',
          text: 'End date max',
          format: 'String'
        })
      }
      if(this.visibleHeaders.includes('acceptance_date')){
        filterFields.pop({
          value: 'acceptance_date',
          text: 'Acceptance date',
          format: 'Date'
        })
        filterFields.push({
          value: 'end_date',
          text: 'End date',
          format: 'Date'
        })
      }
      if(this.visibleHeaders.includes('contract_number') && this.visibleHeaders.includes('payment_domain')) {
        filterFields.push({
          value: 'startDateMin',
          text: 'Start date min',
          format: 'String'
        })
        filterFields.push({
          value: 'startDateMax',
          text: 'Start date max',
          format: 'String'
        })
        filterFields.push({
          value: 'endDateMin',
          text: 'End date min',
          format: 'String'
        })
        filterFields.push({
          value: 'endDateMax',
          text: 'End date max',
          format: 'String'
        })
      }
      return filterFields
    },
    getDataRowCount () {
      let rowCount = this.emitPagination ? Number(this.rowCount) : this.totalDataRows
      this.adjustPageOptionsByRowCount(rowCount)
      return rowCount
    },
    loadingStatus () {
      return this.loading || this.rowsLoading
    }
  },
  watch: {
    rows: {
      handler: function (value) {
        this.updateRows(value)
        this.updateFooters()
      },
      deep: true
    },
    headers: {
      handler: function (value) {
        this.updateHeaders(value)
      },
      deep: true
    },
    footers: {
      handler: function (value) {
        this.updateFooters(value)
      },
      deep: true
    },
    pagination: {
      handler: function (value) {
        this.updateOptions(value)
      },
      deep: true
    },
    options: {
      handler: function (value) {
        if (this.useDataSource) {
          this.updateRows()
        }
        if (this.emitPagination) {
          this.$emit('updatePagination', value)
        }
      },
      deep: true
    },
    filterValues: {
      handler: function () {
        if (this.filterByCostcenter) {
          if (typeof this.filterValues.shares !== 'undefined' && this.filterValues.shares !== "" && this.filterValues.shares !== null) {
            this.$emit('onCostCenterFilter', this.costcenters.find(({ name }) => name.toLowerCase().includes(this.filterValues.shares.toLowerCase())).id)
          }
          else {
            this.$emit('onCostCenterFilter', -1)
          }
        }

        if (!this.emitPagination) {
          this.updateFooters()
        }
        // with contractrows (emitpagination), set delay for input
        if (!this.awaitingSearch && this.emitPagination) {
          setTimeout(() => {
            this.updateFooters()
            const filters = this.getCurrentFilters()
            this.$emit('filterSearch', filters)
            this.awaitingSearch = false
          }, 700)
          this.awaitingSearch = true
        }
      },
      deep: true
    },
    showFilter: function () {
      if (this.hide && this.showFilter) {
        this.showVisibleColumnsDialog = true
      }
      if (this.hide && !this.showFilter) {
        this.closeVisibleColumnsDialog
      }
    },
    showVisibleColumnsDialog: function () {
      if (this.hide && !this.showVisibleColumnsDialog) {
        if (this.showSaveSelectionDialog) {
          this.showSaveSelectionDialog = false
        } else {
          this.$emit('showFilterDialog')
        }
      }
    },
    documentType: function () {
      if (this.hide && this.documentType[0] === 'pdf') {
        this.exportAs('pdf', this.documentType[1])
      }
      if (this.hide && this.documentType[0] === 'csv') {
        this.exportAs('csv', this.documentType[1])
      }
    },
    selectedView: function (selectedView) {
      if (typeof selectedView !== 'undefined' && selectedView !== null) {
        this.visibleHeaders = this.dataHeaders
          .filter(dh =>
            selectedView.stored_fields.find(sf => sf.field === dh.value)
          )
          .map(dh => {
            return dh.value
          })
        this.nameOfSelection = selectedView.name
        this.selectedViewName = selectedView.name

        Object.keys(this.filterValues).forEach((key) => {
          const found = selectedView.stored_fields.find(x => x.field === key)
          if (found) {
            this.filterValues[key] = found.value
          } else {
            this.filterValues[key] = null
          }
        })
      } else {
        this.visibleHeaders = this.dataHeaders.map(dh => {
          return dh.value
        })
        this.nameOfSelection = null

        Object.keys(this.filterValues).forEach((key) => {
          this.filterValues[key] = null  
        })
      }

      this.$emit('select_view', selectedView)
    },
    showConfirm: function (boolean) {
      if (boolean === true) {
        this.needsConfirm = boolean
      }
    },
    nameOfSelection: function () {
      this.needsConfirm = false
    },
    selectedViewString: function (string) {
      this.selectedViewName = string
    },
    selectedViewName: function (name) {
      if (name) {
        this.selectedView = this.storedViews.find(sw => sw.name === name)
      } else {
        this.selectedView = null
      }
    },
    showActiveAssigmentsOnly: function () {
      this.updateRows(this.rows)
    },
    showDefaultSearch: function (value) {
      this.showDefaultSearchDialog = value
    }
  },
  async mounted () {
    this.tableId = 'table-content-' + helpers.uuid.v4()
    this.updateOptions(this.pagination)
    this.updateHeaders(this.headers) 
    if (this.headers.some((h) => h.isExtraField === true)) {
      await this.getCostcenters()
    }
    this.updateRows(this.rows)
    this.updateFooters(this.footers)
    this.loading = false
    this.pageSizeInitialized = false

    const date = this.currentDate
    const today = new Date()

    if (!moment(date, 'day').isSame(today, 'day')) {
      this.dataMissing = this.$t('No data available or not time related')
    } else {
      this.dataMissing = this.$t('No data available')
    }
  },
  methods: {
    ...mapActions('app', ['getPurposeZones']),
    multiSelectValue (header) {
      return this.filterValues[header] !== undefined && this.filterValues[header] !== null ? 
        this.filterValues[header].split(this.multiSelectDelimiter).filter(value => value.trim() != '').map(value => {
          if (value === 'NULL' || this.multiSelectKeys.multiSelectOptionTypes[header] === 3) {
            return value
          }
          return Number(value) 
        }): []
    },
    headerId (header) {
      return header + "_filter"
    },
    adjustPageOptionsByRowCount (rowCount) {
      if (!this.pageSizeInitialized && rowCount === 0) {
        return
      }
      if (rowCount === undefined) {
        return
      }
      if (!this.pageSizeInitialized) {
        this.pageSizeInitialized = true
        this.initialPageSize = this.options.itemsPerPage
        if (rowCount < this.initialPageSize) {
          this.onRowsPerPageChange (rowCount)
        } else {
          this.onRowsPerPageChange (this.initialPageSize)
        }
      } else if (this.options.page !== undefined && rowCount !== undefined && 
        rowCount >= 0 && this.pageSizeInitialized) {
        if (rowCount === 0) {
          // Do nothing
          return
        }
        else if (this.options.itemsPerPage >= rowCount) {
          /* if row count is less than current page size, set page size to row count  */
          this.onRowsPerPageChange (rowCount)
        } else {
          /* If page size is not 10,100, etc and there exists positive number of rows
             page size is initialized to initial page size if initial page size is
             less than row count and to row count if initial page size is same or greater
             than row count. This part only hits if new row count is more than earlier
             page size. */
          if (this.options.itemsPerPage !== undefined && this.options.itemsPerPage !== 0 && 
            rowCount !== 0 &&
            this.isNotIntermediatePowerOf10pageSize(this.options.itemsPerPage)) {
            if (this.initialPageSize < rowCount) {
              this.onRowsPerPageChange(this.initialPageSize)
            } else {
              this.onRowsPerPageChange(rowCount)
            }
          } else {
            // adjust page such that current page begins in the range of rows
            // use local variable to prevent watch launching before final page is found
            let currentPage = this.options.page 
            while ((currentPage - 1) * this.options.itemsPerPage > rowCount) {
              currentPage--
            }
            if (currentPage < 1) {
              currentPage = 1
            }
            this.options.page = currentPage
          }
        }
      }
    },
    isNotIntermediatePowerOf10pageSize (value) {
      if (value < 10) {
        return true
      }
      let comparisonValue = 10
      while (value >= comparisonValue) {
        if (value === comparisonValue) {
          return false
        }
        comparisonValue = comparisonValue * 10
      }
      return true
    },
    icon (header) {
      if (this.multiSelectAllSelected.get(header)) return 'check_box'
      if (this.multiSelectSomeSelected.get(header)) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    },
    multiSelectAll (header) {
      if (this.multiSelectAllSelected.get(header)) {
        this.onFilterValueChange(header, [])
      } else {
        this.onFilterValueChange(header, 
          this.multiSelectKeys[header].map(option => option.value))
      }
    },
    closeVisibleColumnsDialog () {
      this.showVisibleColumnsDialog = false
      this.$store.dispatch('app/addAriaLive', this.$t('aria_live.show_visible_columns_window_closed'))
    },
    onFilterValueChange (header, value) {
      const nextValue = value ?? "";

      let currentTimeout = this.filterTimeouts.find(t => t.headerName === header)
      if (currentTimeout !== undefined) {
        clearTimeout(currentTimeout.timeoutId);
        currentTimeout.timeoutId = null;
        currentTimeout.value = nextValue;
      } else {
        currentTimeout = {
          headerName: header,
          timeoutId: null,
          value
        };
        this.filterTimeouts.push(currentTimeout)
      }

      const that = this;

      currentTimeout.timeoutId = setTimeout(function () {
        const currentTimeout = that.filterTimeouts.find(t => t.headerName === header)
        if (that.headers.filter(h => h.isMultiSelect === true).filter(h => h.value === header).length > 0) {
          that.filterValues[header] = currentTimeout.value.join(that.multiSelectDelimiter) + that.multiSelectDelimiter
          that.multiSelectAllSelected.set(header, 
            currentTimeout.value.length === that.multiSelectKeys[header].length)
          that.multiSelectSomeSelected.set(header, currentTimeout.value.length > 0)
        } else {
          that.filterValues[header] = currentTimeout.value;
        }
        that.filterTimeouts = that.filterTimeouts.filter(t => t.headerName === header)
      }, 1000)

      return;
    },
    updateFooters (footers) {
      if (typeof this.footers === 'function') {
        this.dataFooters = this.footers(this.headers, this.currentVisibleRows)
      } else if (!footers) {
        if (typeof this.dataFooters[0] !== 'undefined') {
          Object.keys(this.dataFooters[0]).forEach(result => {
            const definedFooter = this.footerDefinitions.find(fd => fd.value === result)
            if (definedFooter) {
              // if footer column is wanted to show amount of unique value in column
              if(definedFooter && definedFooter.unique !== 'undefined' && definedFooter.unique) {
                const values = this.currentVisibleRows.map(item => item[result])
                const uniqueValues = [...new Set(values)]
                const sum = uniqueValues.reduce((total, cur) => Number(total) + (isNaN(cur) ? 1 : Number(cur)), 0)
                this.dataFooters[0][result] = helpers.format.formatData(sum, definedFooter.format)
              } else if (definedFooter.numeratorSum !== undefined) {
                const numeratorSum = this.currentVisibleRows
                  .map(r => r[definedFooter.numeratorSum] !== 'undefined' ? 
                  isNaN(r[definedFooter.numeratorSum]) ? 1 : Number(r[definedFooter.numeratorSum]): 0)
                  .reduce((acc, cur) => acc + cur, 0)
                let denominatorSum
                if (definedFooter.denominator) {
                  denominatorSum = Number(this.currentVisibleRows[0][definedFooter.denominator])
                  if (isNaN(denominatorSum)) {
                    denominatorSum = 0
                  }
                } else {
                  denominatorSum = this.currentVisibleRows
                  .map(r => r[definedFooter.denominatorSum] !== 'undefined' ? 
                  isNaN(r[definedFooter.denominatorSum]) ? 1 : Number(r[definedFooter.denominatorSum]): 0)
                  .reduce((acc, cur) => acc + cur, 0)
                }
                let multiplier = 1
                if (definedFooter.unit === "percentage") {
                  multiplier = 100
                }
                this.dataFooters[0][result] = (numeratorSum / denominatorSum * multiplier).toFixed(2)
                if (definedFooter.hundredMinusValue) {
                  this.dataFooters[0][result] = 100 - this.dataFooters[0][result]
                }
              } else {
                let res = this.currentVisibleRows
                .map(r => r[result] !== 'undefined' ? isNaN(r[result]) ? 1 : Number(r[result]): 0)
                .reduce((acc, cur) => acc + cur, 0)
                // If average is defined in footer calculations lets get the count for average calculation 
                if(typeof definedFooter.average !== 'undefined' && definedFooter.average === true)
                {
                  const count = this.currentVisibleRows
                    .map(r => Number(r[result]))
                    .reduce((count, curVal) => {
                      if(curVal !== null) {
                        count++
                        return count
                      }
                    }, 0)
                  const average =  (parseFloat(res)/count).toFixed(2)
                  res = average
                }
                this.dataFooters[0][result] = res
              }
            }
          })
        }
      } else {
        this.dataFooters = footers
      }
    },
    updateHeaders (headers) {
      this.dataHeaders = headers.map((header) => {
        // Headers can also be numbers, so string-conversion is done as a failsafe.
        const translatedName = String(this.$t(header.text))
        return { ...header, translatedName }
      })
      this.visibleHeaders = this.dataHeaders.map(h => h.value)

      let additionalHeaders = []
      if (this.visibleHeaders.includes('contract_netfloorarea')) {
        additionalHeaders = [
        {
          value: 'contractFloorAreaMin',
          text: 'Net floor area min',
          format: "Number",
        },
        {
          value: 'contractFloorAreaMax',
          text: 'Net floor area max',
          format: "Number",
        },
        {
          value: 'contractStartDateMin',
          text: 'Start date min',
          format: "String",
        },
        {
          value: 'contractStartDateMax',
          text: 'Start date max',
          format: "String",
        },
        {
          value: 'contractEndDateMin',
          text: 'End date min',
          format: 'String',
        },
        {
          value: 'contractEndDateMax',
          text: 'End date max',
          format: 'String'
        }]
      } else if (this.visibleHeaders.includes('net_floor_area') || this.visibleHeaders.includes('building_net_floor_area')) {
        additionalHeaders = [
        {
          value: 'floorAreaMin',
          text: 'Net floor area min',
          format: "Number",
        },
        {
          value: 'floorAreaMax',
          text: 'Net floor area max',
          format: "Number",
        },
        {
          value: 'completedYearMin',
          text: 'Completed year min',
          format: "Number",
        },
        {
          value: 'completedYearMax',
          text: 'Completed year max',
          format: "Number",
        }]
      } else if (this.visibleHeaders.includes('building_net_floor_area_from_space')) {
        additionalHeaders = [
        {
          value: 'buildingFloorAreaMin',
          text: 'Net floor area min',
          format: "Number",
        },
        {
          value: 'buildingFloorAreaMax',
          text: 'Net floor area max',
          format: "Number",
        }]
      } else if (this.visibleHeaders.includes('contract_number') && this.visibleHeaders.includes('payment_domain')) {
        additionalHeaders = [
        {
          value: 'startDateMin',
          text: 'Start date min',
          format: "String",
        },
        {
          value: 'startDateMax',
          text: 'Start date max',
          format: "String",
        },
        {
          value: 'endDateMin',
          text: 'End date min',
          format: "String",
        },
        {
          value: 'endDateMax',
          text: 'End date max',
          format: "String",
        }]
      }
      this.filterValues = [...this.dataHeaders, ...additionalHeaders].reduce((acc, cur) => {
        acc[cur.value] = null
        return acc
      }, {})
    },
    async updateRows (rows) {
      if(this.showActiveAssigmentsOnly) {
        // filtering parties that either have assignments, are linked to a company or have linked personnel
        rows = rows.filter(row => row.assignments || row.company_id || row.personnel && row.personnel.length > 0)
      }
      if (this.useDataSource) {
        this.loading = true
        rows = await this.dataSource(this.options)
        this.loading = false
        this.totalDataRows = Number(rows.count)
        rows = rows.items
      } else {
        this.totalDataRows = undefined // See Vuetify documentation
      }
      this.dataRows = [...rows]
    },
    updateOptions (pag) {
      Object.assign(this.options, pag)
    },
    removeRow (row) {
      this.$emit('remove-row', row)
      // HACK to support the old functionality
      this.$emit('event_child', row, 'delete')
    },
    editRow (row) {
      this.$emit('edit-row', row)
      // HACK to support the old functionality
      this.$emit('event_child', row, 'edit')
    },
    async exportAs (type) {
      const headers = this.currentVisibleHeaders.filter(
        h => h.value !== '_actions'
      )

      let data = []
      let newData = []

      //If export data function is defined, use it instead of table data.
      if (this.getExportData && this.emitPagination) {
        data = await this.getExportData()
      } else if (this.getContractExportData && this.emitPagination) {
        // Deprecated, use getExportData instead
        data = await this.getContractExportData()
      } else if (this.getContractPaymentsExportData && this.emitPagination) {
        // Deprecated, use getExportData instead
        data = await this.getContractPaymentsExportData()
      } else {
        data = [...JSON.parse(JSON.stringify(this.currentVisibleRows))]
      }

      // use following function to sort data as it handles null values/"-" markings as well
      newData = this.handleUnsortables(data)

      data = newData

      if (this.dataFooters) {
        data = [...data, ...JSON.parse(JSON.stringify(this.dataFooters))]
      }

      const updatedData = data.map(data => ({
        ...data,
        contract_state: data.contract_state ? this.$t(data.contract_state) : this.$t('Undefined'),
        contract_status: data.contract_status ? this.$t(data.contract_status) : this.$t('Undefined'),
        validity: data.is_fixed_term !== undefined ? data.is_fixed_term ? this.$t('Fixed term') : this.$t('Permanent') : data.validity,
        is_vat: data.is_vat === null ? this.$t(' ') : data.is_vat ? this.$t('Taxable') : this.$t('Exempt from taxation'),
        payment_type: data.payment_type ? this.$t(data.payment_type) : this.$t('Undefined'),
        rent_review_type: data.rent_review_type ? this.$t(data.rent_review_type) : this.$t('Undefined'),
        is_discount_payment: data.is_discount_payment === null ? this.$t('Undefined') : data.is_discount_payment ? this.$t('Yes') : this.$t('No'),
        invoicing_basis: data.invoicing_basis  ? this.$t(data.invoicing_basis) : this.$t('Undefined'),
        payment_domain: data.payment_domain  !== null ? data.payment_domain  : this.$t('Undefined'),
        payment_basis: data.payment_basis  ? this.$t(data.payment_basis) : this.$t('Undefined'),
        is_square_based: data.is_square_based  === null ? this.$t('No') : data.is_square_based ? this.$t('Yes') : this.$t('No'),
        target: data.target === 'Whole contract payment ' ? this.$t('Whole contract payment') : data.target
      }))

      if (type === 'csv') {
        const csvHeaders = headers.map(h => this.$t(h.text))
        const csvData = updatedData.map(d => {
          const row = []
          headers.forEach(h => {
            let val = ''
            if (h.isDefinition === true) {
              val = this.getDefinitionLabel(d[h.value])
            } else if (h.isPurposeOfUse === true) {
              val = this.getPurposeName(d[h.value])
            } else if (h.isPurposeZone === true) {
              val = this.getPurposeZone(d[h.value])
            } else if (h.isExtraField === true) {
              val = this.getCorrectCostCenter(d)
            } else if (h.type === 'chipField' && Array.isArray(d[h.value])) {
              val = d[h.value].map(chip => chip.name).join(', ')
            } else if (h.multiple === true && typeof d[h.value] === 'object') {
              val = Object.values(d[h.value]).map(obj => obj.name).join(', ')
            } else {
              if (typeof d[h.value] === 'string') {
                val = d[h.value].trim()
              } else if (d[h.value] !== null && d[h.value] !== undefined && d[h.value][0] !== undefined && 
                d[h.value][0] !== null && typeof d[h.value][0] === 'string') {
                val = d[h.value][0].trim()
              } else {
                val = d[h.value]
              }
            }
            val = val === undefined ? '' : val
            val = this.getValueWithUnitForCsvExport(val, h.value, h.format, false)
            if (typeof val === 'string') {
              val = helpers.format.unescapeHtml(val)
              val = val.replaceAll('<br />', ' ')
              val = val.replaceAll('<b>', '')
              val = val.replaceAll('</b>', '')
            }
            row.push(val)
          })
          return row
        })
        helpers.csv.export(csvHeaders, csvData)
      }

      if (type === 'pdf') {
        const csvData = updatedData.map(d => {
          const row = []
          headers.forEach(h => {
            let val = ''
            if (h.isDefinition === true) {
              val = this.getDefinitionLabel(d[h.value])
            } else if (h.isPurposeOfUse === true) {
              val = this.getPurposeName(d[h.value])
            } else if (h.isPurposeZone === true) {
              val = this.getPurposeZone(d[h.value])
            } else if (h.isExtraField === true) {
              val = this.getCorrectCostCenter(d)
            } else if (h.multiple === true && typeof d[h.value] === 'object') {
              val = Object.values(d[h.value]).map(obj => obj.name).join(', ')
            } else {
              val = d[h.value]
            }
            val = val === undefined ? '' : val
            val = String(this.getValueWithUnit(val, h.value, h.format, false))
            val = val.replaceAll('<br />', ' ')
            val = val.replaceAll('<b>', '')
            val = val.replaceAll('</b>', '')
            if (typeof val === 'string') {
              val = helpers.format.unescapeHtml(val)
            }

            row.push(val)
          })
          return row
        })

        try {
          const doc = new JsPDF('landscape')
          doc.autoTable({
            head: [headers.map(h => this.$t(h.text))],
            body: csvData
          })
          doc.save('report.pdf')
        } catch (e) {
          this.$log.error(e)
          this.$store.dispatch('error/addError', 'File create error')
        }
      }
      this.$emit('exported')
    },
    // method we can use to move certain desired values that can't be sorted to the end or start of an otherwisely sorted list\data
    handleUnsortables (data) {
      let sortCondition = this.options.sortBy[0]
      let sortDesc = this.options.sortDesc[0]
      // check if data has any "-" or null marked values for sortable value
      let found = data.find(d => d[sortCondition] === "-" || d[sortCondition] === null)

      const extract = []
      //... and if "-" or null values were found, extract them and filtereddata to separate arrays
      if (found !== undefined) {
        const filteredData = []
        data.forEach(d => {
          if (d[sortCondition] === "-" || d[sortCondition] === null) {
            extract.push(d)
          } else {
            filteredData.push(d)
          }
        })
        data = filteredData
      }

      if (sortDesc) {
        // when sorting by descending...
        data.sort((a, b) => a[sortCondition] < b[sortCondition] ? 1 : -1)
        // ... and finally add the extracted items to the end of data, if there's any
        data.push(...extract)
      } else {
        // when sorting by ascend...
        data.sort((a, b) => a[sortCondition] > b[sortCondition] ? 1 : -1)
        //... and finally add the sorted data to the end of the data, if there's any
        data = extract.concat(data)
      }
      return data
    },
    getTruncatedStringFromArray (value, header, format, encode, isExport = false) {
      // No need to duplicate things
      const list = [... new Set(value) ]
      let itemStr = ''
      // if isExport is true or length <= 4 then return whole list
      if (isExport === true || list.length <= 4){
        itemStr = [...list]
          .map(v => this.getValueWithUnit(v, header, format, encode))
          .filter(v => v !== null && v !== '')
          .join(', ')
        return itemStr
      // If there is more than 4 items, just show the first 4 items and add ... as the last item
      } else {
        itemStr = [
          ...list
          .slice(0, 4)
          .map(v => this.getValueWithUnit(v, header, format, encode))].join(', ') + '...'
      }  
      return itemStr
    },
    getValueWithUnitForCsvExport (value, header, format, encode) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }

      if (Array.isArray(value)) {
        return this.getTruncatedStringFromArray(value, header, format, encode, true)
      }

      if (header === 'general_rating') {
        return helpers.format.formatData(value, format)
      }

      if (format === 'Date') {
        return helpers.format.formatData(value, format)
      }

      if (value === null) {
        return ''
      }
      
      if (format === 'AdditionalCost') {
        return this.formatDecimals(value, format)
      }

      if (value && !isNaN(value) && typeof value !== 'string') {
        return Number(value)
          .toFixed(2)
          .toString()
          .replace('.', ',')
      }

      if (header === 'unitName') {
        let val = value.replaceAll('<br/>', ' ')
        val = val.replaceAll('<b>', '')
        val = val.replaceAll('</b>', '')
        return val
      }

      return value
    },
    createAssignmentString (assign, html = true) {
      // If the party has only a single assignment, then we show that assignment fully in the table.
      // When the party has multiple assignments we count through all the different assignment types
      // and collect the strings for the locations where the party has been assigned.
      // These strings are added to the table inside a hidden span, so that they can be filtered with
      // the table component, but remain hidden, since a single party can have over a hundred assignments.
      if (assign.length === 1) {
        if (html) {
          return (
            '<b>' +
            this.$t(this.definitionLabelById(assign[0].idDefinition)) +
            ':</b> ' +
            this.createSingleAssignmentString(assign[0])
          )
        } else {
          return (
            this.$t(this.definitionLabelById(assign[0].idDefinition)) +
            ': ' +
            this.createSingleAssignmentString(assign[0])
          )
        }
      }
      const assignObject = {}
      assign.forEach(a => {
        if (assignObject[a.idDefinition] === undefined) {
          assignObject[a.idDefinition] = {
            name: html
              ? '<b>' +
                this.$t(this.definitionLabelById(a.idDefinition)) +
                '</b>'
              : this.$t(this.definitionLabelById(a.idDefinition)),
            count: 0,
            search: '',
            values: []
          }
        }
        assignObject[a.idDefinition].values.push(assign)
        assignObject[a.idDefinition].count += 1
        assignObject[a.idDefinition].search += a.siteName
      })

      const assignmentArray = []
      const searchArray = []
      Object.values(assignObject).forEach(value => {
        assignmentArray.push(sanitizeHtml(value.name) + ' (' + value.count + ')')
        searchArray.push(sanitizeHtml(value.search))
      })
      if (html) {
        return (
          assignmentArray.join('<br>') +
          '<span style="display: none">' +
          searchArray.join() +
          '</span>'
        )
      } else {
        return assignmentArray.join(', ')
      }
    },
    createSingleAssignmentString (assign) {
      let assignment = ''
      assignment += assign.siteName ? assign.siteName : assign.buildingName
      if (assign.unitName !== null) {
        assignment += ' [' + assign.unitName + ']'
      } else if (assign.spaceName !== null) {
        assignment += ' [' + assign.spaceName + ']'
      } else if (assign.structureName !== null) {
        assignment += ' [' + assign.structureName + ']'
      } else if (assign.linkType == "contract") {
        let typesOfReferences = [];
        if (assign.id == assign.contractIdLandlord) {
          typesOfReferences.push(this.$t("Landlord"));
        } 
        if (assign.id == assign.contractIdLandlordContactPerson) {
          typesOfReferences.push(this.$t("create_rent_contract.contact_person"));
        } 
        if (assign.id == assign.contractIdTenant) {
          typesOfReferences.push(this.$t("Tenant"));
        }
        if (assign.id == assign.contractIdTenantContactPerson) {
          typesOfReferences.push(this.$t("create_rent_contract.tenant_contact_person"));
        } 
        if (assign.id == assign.contractOtherIdTenant) {
          typesOfReferences.push(this.$t("OtherTenant"));
        }
        assignment = assign.contractNumber + ' [' + typesOfReferences.join(",") + ']';
      }
      return sanitizeHtml(assignment)
    },
    getValueWithUnit (value, header, format, encode) {
      if (header === 'rental_status_desc' || header === 'rentingtype') { // TODO this is a hack and should be fixed somewhere else
        return this.$t(value)
      }

      if (header === 'general_rating') {
        return helpers.format.formatData(value, format)
      }
      if (
        header === 'assignments' &&
        typeof value !== 'undefined' &&
        value !== null &&
        value !== ''
      ) {
        if (Array.isArray(value)) {
          return this.createAssignmentString(value, encode)
        } else {
          return this.createAssignmentString([value], encode)
        }
      }

      if (Array.isArray(value)) {
        return this.getTruncatedStringFromArray(value, header, format, encode, false)
      }

      // 16.03.2020 - Translate the datetime into more readable form, doesn't interfere with UserForm
      if (header === 'validUntil' || header === 'lastLogin') {
        if (value !== null) {
          return moment(value).format('DD.MM.YYYY')
        }
      }

      return helpers.format.formatData(value, format)
    },
    getObjectWithUnit (data, name) {
      var object = { name, data }
      return object
    },
    getFooterValueWithUnit (value, format) {
      return helpers.format.formatData(value, format)
    },
    saveSelection (name, usefields = false, privacy = true) {
      const currentFilters = this.getCurrentFilters()
      var fields = []
      this.visibleHeaders.map(header => {
        const value = usefields ? currentFilters[header] : null
        fields.push({item1: header, item2: value ?? ''})
      })

      this.$emit(
        'save-selection',
        name,
        fields,
        false,
        privacy
      )
    },
    confirmSaveSelection (usefields = false, privacy = true) {
      this.needsConfirm = false
      const currentFilters = this.getCurrentFilters()
      var fields = []
      this.visibleHeaders.map(header => {
        const value = usefields ? currentFilters[header] : null
        fields.push({item1: header, item2: value ?? ''})
      })

      this.$emit(
        'save-selection',
        this.nameOfSelection,
        fields,
        false,
        privacy
      )
    },
    toggleVisibleHeaders (isAllSelected) {
        this.visibleHeaders = isAllSelected ? [] : this.dataHeaders.map(h => h.value)
    },
    deleteView: function () {
      this.selectedViewName = null
      this.$emit('delete-view', this.selectedView.id)
    },
    formatData (row, header, format, isFooter=false) {
      if (this.customFormatters && this.customFormatters.hasOwnProperty(header) && !isFooter) {
        return this.customFormatters[header](row, this)
      }
      if(isFooter)
      {
        if(row[header] === undefined)
        {
          return undefined
        }
      }
      const headerWithAllInfo = this.dataHeaders.filter(ah => ah.value === header)
      if (headerWithAllInfo[0].isPurposeZone === true) {
        var val = this.getPurposeZone(row[header])
        return val
      } else if (headerWithAllInfo[0].isPurposeOfUse === true) {
        return this.getPurposeName(row[header])
      } else if (headerWithAllInfo[0].isExtraField === true) {
        var costcenterWithShares = this.getCorrectCostCenter(row)
        return costcenterWithShares
      } else if (Array.isArray(row[header])) {
        return this.getTruncatedStringFromArray(row[header], header, format, false)
      }
      else {
        return helpers.format.formatData(row[header], format, this.currencies.find(c => c.id === row.currency_id)?.symbol)
      }
    },
    formatThousands (value) {
      return helpers.humanize.thousand_separator(value)
    },
    formatDecimals (value) {
      return helpers.humanize.amount_long(Number(value), 3)
    },
    isRightAligned (format) {
      return helpers.format.alignRight(format)
    },
    getDefinitionLabel (id) {
      return this.definitionLabelById(id)
    },
    getDefinitionLabelForGroup (id, header) {
      return this.definitionsByGroupLabel(id, header.value)
    },
    getPurposeZone (id) {
      var zone = ''
      if (this.purposeZones.length > 0) { 
        var zoneObject = this.purposeZones.find(pz => pz.id === id)
        if (typeof zoneObject !== 'undefined' && typeof zoneObject.name !== 'undefined') {
          zone = zoneObject.name
        }
        return zone
      } else {
        return zone
      }
    },
    getPurposeName (id) {
      var purposeOfUse = ''
      if (id > 0) { 
        if (this.purposesOfUse.length > 0) { 
          var pouObject = this.purposesOfUse.find(pz => pz.id === id)
          if (typeof pouObject !== 'undefined' && typeof pouObject.title !== 'undefined') {
            purposeOfUse = this.purposesOfUse.find(x => x.id === id).title
          }
          return purposeOfUse
        } else {
          return purposeOfUse
        }
      }
    },
    getCorrectCostCenter (space) {
      let cc = ''
      let centers = []
      if (typeof space.shares !== 'undefined' && space.shares !== null) {
        centers = []
        const shares = space.shares

        var remainingSharePercentage = 100
        var countWithoutShare = 0
        shares.forEach(share => {
          if (share.share === null) {
            countWithoutShare += 1
          } else {
            remainingSharePercentage -= share.share
          }
        })
        const percentagePerShare = remainingSharePercentage > 0 ? (remainingSharePercentage / countWithoutShare) : 0
        shares.forEach(share => {
          const costcenter = this.costcenters.find(cc => cc.id === share.idCostcenter)
          if (typeof costcenter !== 'undefined') {
            centers.push(costcenter.name.split(' - ')[1] + ' ' + (share.share != null ? share.share : percentagePerShare).toFixed(2) + '%')
          }
        })
      }
      cc = centers.join(', ')
      
      return cc
    },
    getCostcenters () {
      this.$rambollfmapi.organizations
        .costCenters()
        .list()
        .then(res => {
          this.costcenters = res.map(cc => {
            return {
              id: cc.id,
              name: cc.code + ' - ' + cc.name,
              allocation_rule: cc.allocation_rule,
              code: cc.code
            }
          })
        })
    },
    formatBuildingProject (bp) {
      this.buildingProjectId = bp.id_buildingproject      
      const bpArray = Object.entries(bp).map((e) => ( { header: e[0], value: e[1], }));     
      this.buildingProject = this.headers.map(h => {
        let items = []
        bpArray.map(item => {
          if (h.value === item.header) {
            items.push({ ...item, format: h.format })
          }
        })
        return items
      }).flat()
    },
    Sanitize (text) {
      return helpers.format.sanitize(text)
    },
    openLink (url) {
      // add https if it's missing
      if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
      }
      window.open(url)
    },
    getPaginationArray (itemsLength) {
      let pagArray = []
      let next10basedValue = 10
      if (itemsLength === 0) {
        return [1]
      }
      while(itemsLength >= next10basedValue) {
        pagArray.push(next10basedValue)
        next10basedValue *= 10
      }
      if (itemsLength !== next10basedValue / 10 || itemsLength < 10) {
        pagArray.push(itemsLength)
      }
      return pagArray
    },
    getCurrentFilters () {
      return Object.keys(this.filterValues)
        .filter(f => {
          if (
            typeof this.filterValues[f] !== 'undefined' &&
            this.filterValues[f] !== null &&
            this.filterValues[f] !== ''
          ) {
            return true
          }
          return false
        })
        .reduce((acc, cur) => {
          acc[cur] = this.filterValues[cur]
          return acc
        }, {})
    },
    async linkTo (destination, path) {
      let target = destination

      if (destination.siteIdentifier !== undefined) {
        target = destination.siteIdentifier
      }
      else if (destination.id_site !== undefined) {
        target = destination.id_site
      }
      else if (destination.siteId !== undefined) {
        target = destination.siteId
      }
      else if (destination.instance_id !== undefined) {
        target = destination.instance_id
      }
      else if (destination.buildingproject_identifier !== undefined) {
        target = destination.buildingproject_identifier
      }

      if (target !== undefined) {
        if (path === 'sites.info') {
          this.$router.push({
            name: 'sites.info',
            params: { id_site: target }
          })
        }

        if (path === 'sites.facilitymanagement') {
          let unitparam = undefined
          // dashboard link
          if (destination.unitIdentifier !== undefined) {
            unitparam = destination.unitIdentifier
          }
          // site link
          else if (destination.unitId !== undefined) {
            unitparam = destination.unitId
          }

          let plan = this.$rambollfmapi.sites.get(target)
          if (plan.has_dwg === true) {
            this.$router.push({
              name: 'sites.facilitymanagement',
              params: { id_site: target },
              query: { suite_id: unitparam }
          })}
          else {
            this.$router.push({
              name: 'sites.info',
              params: { id_site: target }
          })}
        }

        if (path === 'sites.building_document_download')
        {
          await this.$rambollfmapi.documents
            .downloadBuildingDocument(destination.instance_id, destination.file)
            .then(res => {
              this.isRetrievingFile = false
              const blob = new Blob([res.data], { type: 'octet/stream' })
              helpers.saveAs(blob, destination.file)
            })
            .catch(() => {
              this.isRetrievingFile = false
            })
        }
      }
    },
    escapeHtml (unitName) {
    return helpers.format.escapeHtml(unitName)
    },
    // link exceptions
    getLinkStyle (value)
    {
      if(value.priority !== undefined)
        return null
      else
        return 'linked'
    },
    async routeTo (siteId, unitId) {
      let plan = await this.$rambollfmapi.sites.get(siteId)
      plan.has_dwg ?
            this.$router.push({
            name: 'sites.facilitymanagement',
            params: { id_site: siteId },
            query: { suite_id: unitId }
          }) :
          this.$router.push({
            name: 'sites.info',
            params: { id_site: siteId }
          })
    },
    getHref (item) {
      if (typeof item.siteIdentifier != 'undefined') {
          return "/sites/" + item.siteIdentifier
      }
      if (typeof item.id_site != 'undefined') {
          return "/sites/" + item.id_site
      }
      if (typeof item.siteId != 'undefined') {
          return "/sites/" + item.siteId
      }
    },
    onRowsPerPageChange (val) {
      this.options.page = 1
      this.options.itemsPerPage = val
    },
    selectArrayChoices (val) {
      if (val == 'is_vat') {
        return [{ text: this.$t('Exempt from taxation'), value: '0' },
                { text: this.$t('Taxable'), value: '1'}]
      }
      else if (val == 'validity') {
        return [{ text: this.$t('Permanent'), value: '0' },
                { text: this.$t('Fixed term'), value: '1' }]
      }
      else if (val == 'payment_type') {
        return [{ text: this.$t('ContractPayment'), value: 'ContractPayment' },
                { text: this.$t('SeparatePayment'), value: 'SeparatePayment' },
                { text: this.$t('SinglePayment'), value: 'SinglePayment'}]
      }
      else if (val == 'rent_review_type') {
         return [{ text: this.$t('PaymentRentReviewIndex'), value: 'PaymentRentReviewIndex' },
                 { text: this.$t('PaymentRentReviewPercent'), value: 'PaymentRentReviewPercent' },
                 { text: this.$t('PaymentRentReviewCostBased'), value: 'PaymentRentReviewCostBased'},
                 { text: this.$t('PaymentRentReviewOther'), value: 'PaymentRentReviewOther'},
                 { text: this.$t('PaymentRentReviewRevenueBased'), value: 'PaymentRentReviewRevenueBased'},
                 { text: this.$t('PaymentRentReviewNone'), value: 'PaymentRentReviewNone'},
                 { text: this.$t('Undefined'), value: "Undefined" }]
      }
      else if (val == 'invoicing_basis') {
         return [{ text: this.$t('InvoicingBasisPcs'), value: 'InvoicingBasisPcs'},
                 { text: this.$t('InvoicingBasisArea'), value: 'InvoicingBasisArea'},
                 { text: this.$t('InvoicingBasisGradedArea'), value: 'InvoicingBasisGradedArea'},
                 { text: this.$t('InvoicingBasisOtherInvoicingBasis'), value: 'InvoicingBasisOtherInvoicingBasis'},
                 { text: this.$t('Undefined'), value: 'Undefined'}]
      }
      else if (val == 'payment_domain') {
         return [{ text: this.$t('Asunnon vuokra'), value: 'Asunnon vuokra' },
                 { text: this.$t('Autopaikan vuokra, verollinen'), value: 'Autopaikan vuokra, verollinen' },
                 { text: this.$t('Autopaikkavuokra'), value: 'Autopaikkavuokra' },
                 { text: this.$t('Kiinteistönhoidon kohdistus / Sote'), value: 'PaymentReKiinteistönhoidon kohdistus / SotentReviewCostBased'},
                 { text: this.$t('Kustannusperusteinen vuokra'), value: 'Kustannusperusteinen vuokra'},
                 { text: this.$t('Käyttäjäpalveluiden verollinen laskutus'), value: 'Käyttäjäpalveluiden verollinen laskutus'},
                 { text: this.$t('Käyttäjäpalveluiden laskutus'), value: 'Käyttäjäpalveluiden laskutus'},
                 { text: this.$t('Käyttäjäpalvelut'), value: 'Käyttäjäpalvelut'},
                 { text: this.$t('Laitteiden vuokra'), value: 'Laitteiden vuokra'},
                 { text: this.$t('Lisävuokra / kunnossapito'), value: 'Lisävuokra / kunnossapito'},
                 { text: this.$t('Maanvuokra'), value: 'Maanvuokra'},
                 { text: this.$t('Muu vuokra'), value: 'Muu vuokra'},
                 { text: this.$t('Muut korvaukset'), value: 'Muut korvaukset'},
                 { text: this.$t('Pääoma/Investointivastike'), value: 'Pääoma/Investointivastike'},
                 { text: this.$t('Pääoma/Lisävuokra'), value: 'Pääoma/Lisävuokra'},
                 { text: this.$t('Pääoma/Tuottovaatimus'), value: 'Pääoma/Tuottovaatimus'},
                 { text: this.$t('Pääomavuokra'), value: 'Pääomavuokra'},
                 { text: this.$t('Sähkönmyynti'), value: 'Sähkönmyynti'},
                 { text: this.$t('Sähkön myynti'), value: 'Sähkön myynti'},
                 { text: this.$t('Tilavuokra'), value: 'Tilavuokra'},
                 { text: this.$t('Tontinvuokra, verollinen'), value: 'Tontinvuokra, verollinen'},
                 { text: this.$t('Työsuhdeasunnon vuokra'), value: 'Työsuhdeasunnon vuokra'},
                 { text: this.$t('Vesimaksu'), value: 'Vesimaksu'},
                 { text: this.$t('Yleiskustannus'), value: 'Yleiskustannus'},
                 { text: this.$t('Ylläpitovuokra'), value: 'Ylläpitovuokra'},
                 { text: this.$t('Tilavuokra'), value: 'Tilavuokra'}]
      }
      else if (val == 'payment_basis') {
         return [{ text: this.$t('SpecialCompensation'), value: 'SpecialCompensation'},
                 { text: this.$t('AdministrativeCost'), value: 'AdministrativeCost'},
                 { text: this.$t('RepairLiabilityRent'), value: 'RepairLiabilityRent'},
                 { text: this.$t('AdditionalRent'), value: 'AdditionalRent'},
                 { text: this.$t('BasicRent'), value: 'BasicRent'},
                 { text: this.$t('CapitalRent'), value: 'CapitalRent'},
                 { text: this.$t('SpaceTypeRent'), value: 'SpaceTypeRent'},
                 { text: this.$t('MaintenanceRent'), value: 'MaintenanceRent'},
                 { text: this.$t('Undefined'), value: 'Undefined'}]
      }
      else if (val == 'is_discount_payment') {
         return [{ text: this.$t('No'), value: '0' },
                 { text: this.$t('Yes'), value: '1' }]
      }
      else if (val == 'is_square_based') {
         return [{ text: this.$t('No'), value: '0' },
                 { text: this.$t('Yes'), value: '1' }]
      }
      else if (val == 'status') {
         return [{ text: this.$t('Proposal'), value: '0' },
                 { text: this.$t('ProjectPlanning'), value: '1' },
                 { text: this.$t('RequirementInvestigation'), value: '2' },
                 { text: this.$t('ImplementationPlanning'), value: '3' },
                 { text: this.$t('Programmed'), value: '4' },
                 { text: this.$t('Construction'), value: '5' },
                 { text: this.$t('WarrantyPeriod'), value: '6' },
                 { text: this.$t('Finished'), value: '7' },
                 { text: this.$t('Suspended'), value: '8' },
                 { text: this.$t('Unknown'), value: '9' }]
      }
      else if (val == 'automatic_billing_allowed') {
         return [{ text: this.$t('No'), value: '0' },
                 { text: this.$t('Yes'), value: '1' }]
      }
      return null
    },
    hasFilterContent (value) {
      return this.customFilterContent.hasOwnProperty(value)
    },
    getFilterContent (value) {
      if (this.hasFilterContent(value)) {
        return this.customFilterContent[value]
      }
      return []
    },
    hideFilters (filter) {
      const filtersToHide = [
        'net_floor_area', 'contract_netfloorarea', 'contract_state', 'contract_status', 'last_created_invoice_date', 'net_sum', 'net_square_rent', 'gross_square_rent',
        'gross_sum', 'external_url', 'building_net_floor_area_from_space'
      ]
      return filtersToHide.includes(filter)
    },
    toggleDefaultSearchDialog: function () {
      this.$emit('showDefaultSearchDialog')
    },
    getFormattedValueFromArray (row, header) {
      let value = null
      if (Array.isArray(row[header.array]) && header.valueIndex != null) {
        value = row[header.array]?.[header.valueIndex]
      }
      return helpers.format.formatData(value, header.format)
    },
  }
}
</script>
<style>
.filters .v-expansion-panel__body {
  padding-bottom: 0.5em;
}
.filters .v-text-field.v-input--is-label-active {
  border-width: 1px !important;
  /* color: #ff0000 !important; */
}
.col-align-right {
  text-align: right;
  padding-right: 0.5em;
}
.hide-expansion-panel .v-expansion-panel-header {
  display: none !important;
}
.viewsize-scroll thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 2;
}
.viewsize-scroll .v-table__overflow {
  max-height: 90vh;
  overflow: scroll;
}
.icon_padding {
  padding-right: 0.5em;
}
.viewsize-scroll .v-data-table__wrapper {
  overflow: scroll;
  max-height: 90vh;
}
.hide-long-text {
  width: 50vw;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}
.hide-long-text:hover {
  white-space: normal;
}
.buttons-container {
  display: flex;
  padding-left: 120px;
  justify-content: center;
  overflow: auto;
}
.buttons-container2 {
  display: flex;
  justify-content: right;
}
.widthAdjust {
  width: 25%;
  flex: 1 1 auto;
}
.multiselect_autocomplete .v-select__selections {
  overflow: hidden;
  flex-wrap: nowrap;
}

.multiselect_autocomplete span {
  overflow: unset !important;
}

.multiselect_autocomplete .v-select__slot {
  min-height: 34px;
}

@media screen and (max-width: 760px) {
  .widthAdjust {
    width: 100%;
  }
}
</style>
