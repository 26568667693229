<template>
  <div
    v-if="item.prospect_type_icon == null"
  />
  <v-row
    v-else-if="item.prospect_type_icon.length > 0"
    align="center"
    justify="end"
    no-gutters
  >
    {{ formatData(item, header.value, header.format) }}
    <v-btn
      fab
      x-small
      elevation="0"
      class="no-hover mx-1"
      :class="item.prospect_type_icon"
    >
      <v-icon>
        {{ item.prospect_type_icon }}
      </v-icon>
    </v-btn>
  </v-row>
  <v-menu
    v-else
    :offset-y="true"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        outlined
        rounded
        text
        class="lowercase-button"
        v-bind="attrs"
        v-on="on"
      >
        {{ currentValue ? currentValue : $t("Select") }}
        <v-icon right>
          expand_more
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="option in getOptions()"
          :key="option.id"
          @click="updateContractState(item, option.id)"
        >
          <v-list-item-title>{{ $t(option.label) }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import helpers from "../../../helpers"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "RentalTypeColumn",
  props: {
    header: { type: Object, default: null },
    item: { type: Object, default: null },
  },
  data: function () {
    return {
      selectedValue: null,
    }
  },
  computed: {
    ...mapGetters("app", ["definitionsByGroupLabel", "definitionById"]),
    currentValue () {
    

      if (this.selectedValue) {
        const reason = this.definitionById(this.selectedValue)
        if(reason){
          return reason.label
        }
      }

      return this.item.rental_type_translated
    },
  },
  methods: {
    ...mapActions("leasing", [
      "patchRentalStatus",
    ]),
    formatData (row, header, format, isLink = false) {
      const value = isLink
        ? helpers.format.escapeHtml(row[header])
        : row[header]
      return helpers.format.formatData(value, format)
    },
    getOptions () {
      return this.definitionsByGroupLabel("type_of_renting")
    },

    async updateContractState (item, value) {
      this.selectedValue = value

      const patch = [
        {
          op: "replace",
          path: "defTypeOfRenting",
          value,
        },
      ]

      this.patchRentalStatus({
        patch,
        unitId: item.unitId,
        curUnitPartyId: item.curUnitPartyId,
        contractNumber: item.contractNumber,
      })
    },
  },
}
</script>

<style scoped>

.lowercase-button {
  text-transform: unset;
}

.no-background-hover::before {
  background-color: transparent !important;
}

.no-hover {
  pointer-events: none;
}

.autorenew {
  background-color: var(--c-color-accent) !important;
  color: white;
}

.icecream {
  background-color: #f389d6 !important;
  color: white !important;
}

.construction {
  background-color: #9b2ac9 !important;
  color: white;
}

.spa {
  background-color: #4caf50 !important;
  color: white;
}
</style>
