<template>
  <v-col>
    <v-container>
      <v-row
        class="section fill-height"
      >
        <v-col>
          <h4 class="text--secondary">
            {{ $t('taskEsg.sustainabilityInvestmentType') }}
          </h4>
        </v-col>
      </v-row>
      <v-row
        class="section fill-height"
      >
        <v-col cols="12">
          <v-select
            v-model="esg.esgCodeId"
            :label="$t('taskEsg.esgCode')"
            :items="esgCodes"
            item-text="text"
            item-value="value"
            :clearable="!task.finished"
            :readonly="task.finished"
            @change="$emit('esgChanged', esg)"
          />
        </v-col>
      </v-row>
      <v-spacer />
      <br>
      <v-row
        class="section fill-height"
      >
        <v-col>
          <h4 class="text--secondary">
            {{ $t('taskEsg.energySavingsPotential') }}
          </h4>
        </v-col>
      </v-row>
      <v-row
        class="section fill-height"
      >
        <v-col cols="12">
          <v-text-field
            v-model.number="esg.production"
            :label="$t('taskEsg.production')"
            type="number"
            :clearable="!task.finished"
            :readonly="task.finished"
            @change="$emit('esgChanged', esg)"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="esg.energySavings"
            :label="$t('taskEsg.energySavings')"
            type="number"
            :clearable="!task.finished"             
            :readonly="task.finished"
            @change="$emit('esgChanged', esg)"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="esg.co2Reduction"
            :label="$t('taskEsg.co2Reduction')"
            type="number"
            :clearable="!task.finished"
            :readonly="task.finished"
            @change="$emit('esgChanged', esg)"
          />
        </v-col>
      </v-row>
      <v-spacer />
      <br>
      <v-row
        class="section fill-height"
      >
        <v-col>
          <h4 class="text--secondary">
            {{ $t('taskEsg.savingsPotential&Profitability') }}
          </h4>
        </v-col>
      </v-row>
      <v-row
        class="section fill-height"
      >
        <v-col cols="12">
          <CurrencyField 
            :value="task.monetarySavings"
            :label="$t('taskEsg.monetarySavings')"
            :currency="currency"
            type="number"
            :readonly="task.finished"
            @input="monetarySavingsChanged"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="esg.paybackPeriod"
            :label="$t('taskEsg.paybackPeriod')"
            readonly
            @change="$emit('esgChanged', esg)"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="esg.investmentYield"
            :label="$t('taskEsg.yieldOnTotalInvestment')"
            readonly
            @change="$emit('esgChanged', esg)"
          />
        </v-col>
      </v-row>
      <v-col class="section-divider">
        <v-spacer />
        <v-divider />
        <br>
      </v-col>
      <v-container>
        <v-row
          class="section fill-height"
        >
          <v-col>
            <h4 class="text--secondary">
              {{ $t('ESG') }}
            </h4>
          </v-col>
        </v-row>
        <v-row
          class="section fill-height"
        >
          <v-col>
            <v-col class="section-switch">
              <v-switch
                v-model="esg.energyEfficiency"
                :label="$t('taskEsg.energyEfficiency')"
                :disabled="task.finished"
                @change="$emit('esgChanged', esg)"
              />
            </v-col>
            <v-col class="section-switch">
              <v-switch
                v-model="esg.euTaxonomy"
                :label="$t('taskEsg.euTaxonomy')"
                :disabled="task.finished"
                @change="$emit('esgChanged', esg)"
              />
            </v-col>
            <v-col class="section-switch">
              <v-switch
                v-model="esg.sbti"
                :label="$t('taskEsg.sbti')"
                :disabled="task.finished"
                @change="$emit('esgChanged', esg)"
              />
            </v-col>
          </v-col>
          <v-col>
            <v-col class="section-switch">
              <v-switch
                v-model="esg.gbCertification"
                :label="$t('taskEsg.gbCertification')"
                :disabled="task.finished"
                @change="$emit('esgChanged', esg)"
              />
            </v-col>
            <v-col class="section-switch">
              <v-switch
                v-model="esg.gresb"
                :label="$t('taskEsg.gresb')"
                :disabled="task.finished"
                @change="$emit('esgChanged', esg)"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-container>
      <v-col class="section-divider">
        <v-spacer />
        <v-divider />
        <br>
      </v-col>
    </v-container>
  </v-col>
</template>
<script>
import helpers from '../../helpers'
import CurrencyField from '../CurrencyField'
export default {
  name: 'TaskEsg',
  components: {
    CurrencyField,
  },
  props: {
    task: {
      type: Object,
       default : () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    },
    currency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
      esg: {
        energyEfficiency: this.task.energyEfficiency ?? false,
        euTaxonomy: this.task.euTaxonomy ?? false,
        sbti: this.task.sbti ?? false,
        gbCertification: this.task.gbCertification ?? false,
        gresb: this.task.gresb ?? false,
        esgCodeId: this.task.esgCodeId ?? null,
        production: this.task.production ?? null,
        energySavings: this.task.energySavings ?? null,
        co2Reduction: this.task.co2Reduction ?? null,
        monetarySavings: this.task.monetarySavings ?? null,
        paybackPeriod: this.task.budget > 0 && this.task.monetarySavings > 0 ? this.calculatePaybackPeriod(this.task.budget, this.task.monetarySavings) : null,
        investmentYield: this.task.budget > 0 && this.task.monetarySavings > 0 ? this.calculateInvestmentYield(this.task.budget, this.task.monetarySavings) : null
      },
      esgCodes: helpers.ltpConstants.taskEsgCodes(),
    }
  },
  watch: {
    'esg.monetarySavings' (money) {
      if (this.task.budget > 0 && this.esg.monetarySavings > 0) {
        this.esg.paybackPeriod = this.calculatePaybackPeriod(this.task.budget, this.task.monetarySavings)
        this.esg.investmentYield = this.calculateInvestmentYield(this.task.budget, this.task.monetarySavings)
      } else {
        this.esg.paybackPeriod = null
        this.esg.investmentYield = null
      }
      if (typeof money === 'string' && money.length === 0) {
        this.esg.monetarySavings = null
      }
    },
    'task.budget' () {
      if (this.task.budget > 0 && this.esg.monetarySavings > 0) {
        this.esg.paybackPeriod = this.calculatePaybackPeriod(this.task.budget, this.task.monetarySavings)
        this.esg.investmentYield = this.calculateInvestmentYield(this.task.budget, this.task.monetarySavings)
      } else {
        this.esg.paybackPeriod = null
        this.esg.investmentYield = null
      }
    },
    'esg.production' (production) {
      if (typeof production === 'string' && production.length === 0) {
        this.esg.production = null
      }
    },
    'esg.energySavings' (energy) {
      if (typeof energy === 'string' && energy.length === 0) {
        this.esg.energySavings = null
      }
    },
    'esg.co2Reduction' (reduction) {
      if (typeof reduction === 'string' && reduction.length === 0) {
        this.esg.co2Reduction = null
      }
    },
  },
  methods: {
    calculatePaybackPeriod (budget, monetarySavings) {
      return budget / monetarySavings
    },
    calculateInvestmentYield (budget, monetarySavings) {
      return monetarySavings / budget
    },
    monetarySavingsChanged (value) {
      this.esg.monetarySavings = value
      this.$emit('esgChanged', this.esg)
    },
  }
}
</script>
<style scoped>
.section-divider {
  padding-top: 2em;
  padding-bottom: 1em;
}
</style>