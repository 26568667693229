
<template>
  <div>
    <v-tab-item
      :key="3"
    >
      <v-form
        ref="form1"
        class="pt-2"
      >
        <ValidationObserver ref="observer">
          <h3 class="ml-2">
            {{ $t('create_rent_contract.contract_signing') }}
          </h3>
          <h4 class="mt-4 ml-2">
            {{ $t('create_rent_contract.select_signatories') }}
          </h4>

          <ValidationProvider
            v-slot="{ errors }"
            :rules="`${isOutRent ? 'required' : ''}`"
          >
            <v-select
              v-model="landlordSignatory"
              :items="landlordPersonParties"
              :label="isOutRent ? $t('create_rent_contract.landlord_signatory') : $t('create_rent_contract.landlord_signatory')"
              :error-messages="errors[0] || landlordSignatory && !landlordHasEmail ? $t('Required') + ' — ' + $t('create_rent_contract.error_no_email') : ''"
              :disabled="!isNewOrDraft && isElectronicSigning"
              required
              :hint="$t('Required')"
              persistent-hint
              item-value="id"
              item-text="name"
              class="mt-4 ml-2"
              @change="onDataChanged()"
            />
            <div
              v-if="!isNewOrDraft && isElectronicSigning" 
              class="mt-4 ml-6"
            >
              {{ showSignatotyState(landlordSigned, landlordSignatoryDate) }}
            </div>
          </ValidationProvider>
       
          <div 
            v-for="(signatory, index) in tenantSignatories"
            :key="index"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :rules="`${isOutRent && index === 0 ? 'required' : ''}`"
            >
              <v-row
                align="center"
              >
                <v-col>
                  <v-select
                    v-model="signatory.id"
                    :items="tenantPersonParties"
                    :label="isOutRent && index === 0 ? $t('create_rent_contract.tenant_signatory') : $t('create_rent_contract.tenant_signatory')"
                    :error-messages="errors[0] || isElectronicSigning && (signatory.email === '' || signatory.email === null) ? $t('Required') + ' — ' + $t('create_rent_contract.error_no_email') : ''"
                    :disabled="!isNewOrDraft && isElectronicSigning"
                    required
                    :hint="$t('Required')"
                    persistent-hint
                    item-value="id"
                    item-text="name"
                    class="mt-8 ml-2"
                    @change="onSignatoryChange(signatory.id, index)"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-show="index != 0"
                    small
                    icon
                    class="mt-8 ml-2"
                    @click="removeTenantSignatory(index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div 
                v-if="!isNewOrDraft && isElectronicSigning"
                class="mt-4 ml-6"
              >
                {{ showSignatotyState(signatory.signed, signatory.signatoryDate) }}
              </div>
            </ValidationProvider>
          </div>
          <div
            v-if="tenantType == 'company' || tenantSignatories.length < 2"
          >
            <v-btn
              :disabled="!isNewOrDraft && isElectronicSigning"
              rounded
              depressed
              class="mt-8 ml-2 blue-button"
              @click="addTenantSignatoryField"
            >
              <v-icon small>
                add
              </v-icon>
              {{ $t('create_rent_contract.add_tenant_signatory') }}
            </v-btn>
          </div>
          <h4 class="mt-6 ml-2">
            {{ $t('create_rent_contract.select_signature_method') }}
          </h4>
          <v-radio-group
            v-model="signatureMethod"
            :disabled="!isNewOrDraft"
            class="form-fields mt-2 ml-2"
          >
            <v-radio
              :label="$t('create_rent_contract.esignature')"
              :disabled="!canUseElectronicSigning"
              value="eSignature"
            />
            <v-radio
              :label="$t('create_rent_contract.no_esignature')"
              value="noESignature"
            />
          </v-radio-group>

          <h4 class="ml-2">
            {{ $t('create_rent_contract.preview_the_contract') }}
          </h4>
          <p 
            v-if="isNewOrDraft"
            class="mt-2 ml-2 mb-2"
          >
            {{ signatureMethod === 'noESignature' ? $t('create_rent_contract.esignature_infotext2') : $t('create_rent_contract.esignature_infotext') }}
          </p>
          <v-btn
            rounded
            depressed
            class="blue-button"
            @click="$emit('downloadContract')"
          >
            <v-icon small>
              download
            </v-icon>
            {{ $t('create_rent_contract.download_contract') }}
          </v-btn>

          <div
            v-if="showValidationMessage && requiredFieldValidationMessages.length > 0"
            cols="12"
          >
            <CdsAlert
              type="danger"
              :title="$t('Following mandatory contract fields should be filled')"
              class="mt-4"
            >
              <ul>
                <li
                  v-for="requiredFieldValidationMessage in requiredFieldValidationMessages"
                  :key="requiredFieldValidationMessage"
                >
                  {{ requiredFieldValidationMessage }}
                </li>
              </ul>
            </CdsAlert>
          </div>
        </ValidationObserver>
      </v-form>
    </v-tab-item>
  </div>
</template>
    
<script>
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import helpers from '../../../../helpers'
import humanize from '../../../../helpers/humanize'
import CdsAlert from '../../../CircleDesignSystem/CdsAlert.vue'

const getValidDate = (val) => {
  const date = moment(val, "DD.MM.YYYY");
  return date.isValid() ? date.format("YYYY-MM-DD") : undefined
}

export default {
  name: "SendContractForSigningTabVue",
  components: {
    ValidationProvider,
    ValidationObserver,
    CdsAlert,
  },
  props: {
    tenantId: {
      type: Number,
      default: undefined
    },
    otherTenantId: {
      type: Number,
      default: undefined
    },
    landlordId: {
      type: Number,
      default: undefined
    },
    idLandlordContactPerson: {
      type: Number,
      default: undefined
    },
    tenantType: {
      type: String,
      default: undefined
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    requiredFieldValidationMessages: {
      type: Array,
      default: () => []
    },
    showValidationMessage: {
      type: Boolean,
      default: false
    },
    allParties: {
      type: Array,
      default: () => []
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    settings: {
      type: Object,
      default: () => undefined
    },
    firstTabTenantTypeChange: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      signatureMethod: 'eSignature', 
      isElectronicSigning: true,
      landlordSigned: false,
      landlordSignatoryDate: undefined,
      landlordSignatory: this.idLandlordContactPerson ? this.idLandlordContactPerson : undefined,
      tenantSignatories: [
        {
          id: undefined,
          name: undefined,
          email: undefined,
          signatoryDate: undefined,
          signed: false,
        }
      ],
      landlordHasEmail: undefined,
      tenantSignatoryIds: [],
      landlordPersonParties: undefined,
      tenantPersonParties: undefined,
      signedContract: undefined,
      signedPlace: undefined,
      signedDate: undefined,
      retrievalProgress: 0,
      isRetrievingFile: false,
      isPageValid: false,
      canUseElectronicSigning: true
    }
  },
  computed: {
    isNewOrDraft () {
      return !this.contract || this.contract?.isDraft
    },
  },
  watch: {
    async showValidationMessage () {
      this.isPageValid = Object.keys(this.$refs).length > 0 ? await this.$refs.observer.validate() : false
      this.$emit("isPageValidChanged", this.isPageValid)
    },
    contract () {
      if (this.contract) {
        this.updateDataFromExistingContract()
        this.isSignerSigned()
      }
    },
    signatureMethod () {
      if (this.canUseElectronicSigning == false) {
        this.signatureMethod = 'noESignature'
      }
      this.isElectronicSigning = this.signatureMethod === 'eSignature'
      this.onDataChanged()
    },
    tenantType () {
      if (this.tenantId || this.tenantType === 'person') {
        this.getTenantPersons()
      }
    },
    firstTabTenantTypeChange () {
      if (this.firstTabTenantTypeChange) {
        this.tenantSignatories = [
          {
            id: undefined,
            name: undefined,
            email: undefined,
            signatoryDate: undefined,
            signed: false,
          }
        ]
        this.getTenantPersons()
      }
    },
    tenantId () {
      if (this.tenantId || this.tenantType === 'person') {
        this.getTenantPersons()
      }
    },
    otherTenantId () {
      this.getTenantPersons()
    },
    landlordId () {
        if (this.landlordId) {
          this.getLandlordPersons()
        }
    },
    landlordSignatory () {
        if (this.landlordSignatory) {
          if (this.idLandlordContactPerson) {
            const landlord = this.allParties.filter(party => party.id == this.landlordSignatory).map(p => (
              {
                name: p.name,
                id: p.id,
                email: p.email
              })
              );
            this.landlordHasEmail = landlord[0].email === null || landlord[0].email.length < 1 ? false : true
            return this.allParties.filter(party => party.id == this.idLandlordContactPerson).map(p => (
            {
              name: p.name,
              id: p.id,
              email: p.email
            })
            );
          }
        }
    },
    allParties () {
      this.getLandlordPersons()
      this.getTenantPersons()
    },
    settings: function (newVal) {  
      if (this.settings.length === 0) {
        this.canUseElectronicSigning = true;
      }
      else {
        this.canUseElectronicSigning = this.settings.canUseElectronicSigning
      }
      
      if (this.canUseElectronicSigning == false) {
        this.signatureMethod = 'noESignature'
      }
    }
  },
  methods: {
    showSignatotyState (isSigned, signatoryDate) {
      if (!isSigned) {
        if (["ContractStateExpired","ContractStateSigned"].includes(this.contract.contractSigningState)) {
          return this.$t("Signature date unknown")
        } else if (this.contract.contractSigningState === "ContractStateSigningRequestSent") {
          return this.$t("Signature is waiting")      
        }
        return ""
      }
      return this.$t("Signed on") + this.getHumanreadableDate(signatoryDate)
    },
    async isSignerSigned () {
      if (this.isNewOrDraft || !this.isElectronicSigning)
        return
      const signatories = await this.$rambollfmapi.contracts.contract.signers(this.contract.contractPDFId)
      if (signatories != null) {
        signatories.forEach(s => {
          if (s.signerId === this.landlordSignatory) {
            this.landlordSignatoryDate = s.signedDate
            this.landlordSigned = s.isSigned
          } else {
            this.tenantSignatories.forEach(t => {
              if (t.id === s.signerId) {
                t.signatoryDate = s.signedDate
                t.signed = s.isSigned
              }
            }) 
          }
        })
      } 
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    getHumanreadableDate (date) {
      return humanize.date(date, 'time')
    },
    addTenantSignatoryField () {
      this.tenantSignatories.push({
        id: undefined,
        name: undefined,
        email: undefined
      })
    },
    getTenantPersons () {
      if (this.tenantType === 'person') {
        this.tenantPersonParties = this.allParties.filter(party => party.id == this.tenantId || party.id == this.otherTenantId).map(
          p => (
          {
            name: p.name,
            email: p.email,
            id: p.id,
          })
        );
      } else {
        this.tenantPersonParties = this.allParties.filter(party => party.type == 1 && party.company_id == this.tenantId).map(p => (
          {
            name: p.name,
            email: p.email,
            id: p.id,
          })
        );
      }
	},
	getLandlordPersons () {
		this.landlordPersonParties = this.allParties.filter(party => party.type == 1 && party.company_id == this.landlordId).map(p => (
        {
          name: p.name,
          email: p.email,
          id: p.id,
        })
      );
	},
    downloadSignedContract () {
      const id = this.contract.contractPDFId
      this.isRetrievingFile = true
      const progress = {
        retrievalProgress: 0
      }
      this.retrievalProgress = 0
      const self = this
      this.$rambollfmapi.documents
        .download(id, progress)
        .then(res => {
          self.isRetrievingFile = false
          self.retrievalProgress = 100
          const blob = new Blob([res.data], { type: 'octet/stream' })
          const filename = res.headers["content-disposition"].match(/filename\s*=\s*(.+);/i)[1].replaceAll('"', '');
          helpers.saveAs(blob, filename)
        })
        .catch(() => {
          this.isRetrievingFile = false
          // this.showFileInfo('Dokumenttia ei pystytä lataamaan', 'error')
        })
    },
    removeTenantSignatory (index) {
      this.tenantSignatories.splice(index, 1)
      this.tenantSignatoryIds.splice(index, 1)
      this.onDataChanged()
    },
    onSignatoryChange (id, index) {
      const signatoryData = this.allParties.filter(party => party.id == id).map(p => (
        {
          name: p.name,
          email: p.email,
          id: p.id,
        })
      );
      this.tenantSignatories[index].name = signatoryData[0].name
      this.tenantSignatories[index].email = signatoryData[0].email
      this.tenantSignatoryIds[index] = signatoryData[0].id    
      this.onDataChanged()
    },
    onSignedDateChange (val) {
      this.signedDate = getValidDate(val) ?? ""
      this.onDataChanged()
    },
    async updateDataFromExistingContract () {
      this.landlordSignatory = this.contract.landlordSignatory
      this.tenantSignatoryIds = this.contract.tenantSignatories
      this.signedPlace = this.contract.signedPlace
      this.signedDate = this.contract.signedDate
      this.isElectronicSigning = this.contract.isElectronicSigning
      this.signatureMethod = this.contract.isElectronicSigning === true ? 'eSignature' : 'noESignature'

      if (this.allParties) {
        this.tenantSignatories = []
        this.tenantSignatoryIds.forEach(id => {
          const idData = this.allParties.filter(party => party.id === id).map(p => (
            {
              name: p.name,
              email: p.email,
              id: p.id,
            }
          ))
          this.tenantSignatories.push(idData[0]) 
        })
        if (this.tenantSignatories.length == 0) {
          this.tenantSignatories = [
            {
              id: undefined,
              name: undefined,
              email: undefined,
              signatoryDate: undefined,
              signed: false,
            }
          ]
        }
      }
	},
	async onDataChanged () {
    await new Promise(resolve => setTimeout(resolve, 50))
    const tenantEmails = []
    this.tenantSignatories.map(t => tenantEmails.push(t.email))
    let tenantsHasEmails = false
    if (!tenantEmails.includes(null) && !tenantEmails.includes(e => e.length < 1))
      tenantsHasEmails = true
		const data = {
        landlordSignatory: this.landlordSignatory,
        tenantSignatories: this.tenantSignatoryIds,
        signedContract: this.signedContract,
        signedPlace: this.signedPlace,
        signedDate: !this.isElectronicSigning ? this.signedDate : undefined,
        isElectronicSigning: this.isElectronicSigning,
        landlordHasEmail: this.landlordHasEmail,
        tenantsHasEmails: tenantsHasEmails
      }

      this.$emit('updated', data)
	}
  }
}
</script>

<style>
.v-input--checkbox {
  display: inline-flex;
}
</style>