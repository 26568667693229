<template>
  <div>
    <SkipTo
      :label="$t('skip-to-site-content')"
      to="#site-content"
    />
    <nav :aria-label="$t('SubMenu')">
      <v-row
        justify="space-between"
        align="center"
        no-gutters
      >
        <v-tabs
          class="menu ml-5"
          right
        >
          <v-tab
            :to="{ name: 'sites.info' }"
            exact
          >
            {{ $t('Site page') }}
          </v-tab>
      
          <v-tab
            v-if="hasApplicationPermissionByName('KOHDE_BUDJETJAKULULEHTI')"
            :to="{ name: 'sites.budgetAndExpenses' }"
          >
            {{ $t('Budget and expense information') }}
          </v-tab>
      
          <v-tab
            v-if="hasApplicationPermissionByName('KOHDE_LISATIEDOTLEHTI')"
            :to="{ name: 'sites.additionalInfo' }"
          >
            {{ $t('Additional information') }}
          </v-tab>

          <v-tab            
            v-if="hasApplicationPermissionByName('SALKUTUS_OIKEUS') || hasApplicationPermissionByName('SALKUTUS_KATSELU') || hasApplicationPermissionByName('SALKUTUS_HYVAKSYNTA')"
            :disabled="buildingsForCurrentSite.length === 0"
            :to="{ name: 'sites.buildingRatings' }"
          >
            {{ $t('buildingRating') }}
          </v-tab>

          <v-tab
            v-if="hasApplicationPermissionByName('ESG')"
            :to="{ name: 'sites.esg' }"
          >
            {{ $t('ESG') }}
          </v-tab>
          <v-tab
            v-if="hasApplicationPermissionByName('KOHDE_VUOKRAUSLEHTI')"
            :to="{ name: 'sites.renting' }"
          >
            {{ $t('Renting') }}
          </v-tab>
          <v-tab
            v-if="hasApplicationPermissionByName('LEASING')"
            :to="{ name: 'sites.rentingprocesses' }"
          >
            {{ $t('leasing.rentingProcess') }}
          </v-tab>

          <v-tab
            v-if="hasApplicationPermissionByName('KOHDE_VUOKRASOPLEHTI')"
            :to="{ name: 'sites.rentalcontracts' }"
          >
            {{ $t('site.rental_contracts') }}
          </v-tab>
          <template>
            <v-tooltip 
              top
              :disabled="hasApplicationPermissionByName('KOHDE_POHJAKUVAT') && isFacilityManagementAvailable"
            >
              <template #activator="{ on, attrs }">
                <div
                  style="display: flex;"
                  v-on="on"
                >
                  <v-tab
                    :to="{ name: 'sites.facilitymanagement' }"
                    :disabled="!hasApplicationPermissionByName('KOHDE_POHJAKUVAT') || !isFacilityManagementAvailable"
                    v-bind="attrs"
                    :aria-label="`${$t('Facility management')}: ${getFacilityManagementTooltipText()}`"
                    v-on="on"
                  >
                    {{ $t('Facility management') }}
                  </v-tab>
                </div>
              </template>
              <span>
                {{ getFacilityManagementTooltipText() }}
              </span>
            </v-tooltip>
          </template>
          <v-tab
            v-if="cemeteriesForCurrentSite.length > 0"
            :to="{ name: 'sites.graveyardmap' }"
          >
            {{ $t('Graveyard map') }}
          </v-tab>
          <v-tab
            :to="{ name: 'sites.documents' }"
            :disabled="!hasApplicationPermissionByName('DOKUMENTTIEN_HALLINTA')"
            exact
          >
            {{
              $t('Documents')
            }}
          </v-tab>
          <v-tab
            :to="{ name: 'sites.photos' }"
            :disabled="!hasApplicationPermissionByName('VALOKUVAN_LISAYS')"
            exact
          >
            {{
              $t('Photos')
            }}
          </v-tab>
          <v-tab
            v-if="hasApplicationPermissionByName('TEKNISET_ARVOT')"
            :to="{ name: 'sites.technicalValues' }"
            exact
          >
            {{
              $t('technicalValues')
            }}
          </v-tab>
        <!-- style="margin: 0; height: inherit" -->
        </v-tabs>
        <div class="pr-4">
          <v-btn
            icon
            outlined
            color="#797766"
            @click="updateFavourites()"
          >
            <span v-if="isFavourite">
              <v-icon color="#009DF0">
                star
              </v-icon>
              <span class="d-sr-only">{{ $t('RemoveFromFavourites') }}</span>
            </span>
            <span v-else>
              <v-icon>
                star_border
              </v-icon>
              <span class="d-sr-only">{{ $t('AddToFavourites') }}</span>
            </span>
          </v-btn>
          <!-- style="margin: 0; margin-left: 0.5em; height: inherit" -->
          <v-btn
            v-if="siteView"
            icon
            outlined
            color="#797766"
            @click="refresh(siteId)"
          >
            <v-icon>refresh</v-icon>
            <span class="d-sr-only">{{ $t('Refresh') }}</span>
          </v-btn>
        </div>
      </v-row>
    </nav>
    <v-divider />
    <router-view :key="siteId" />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import SkipTo from '../components/general/SkipTo'

export default {
  name: 'Sites',
  components: {
    SkipTo,
  },

  data () {
    return {
      isFavourite: false,
      isFacilityManagementAvailable: false,
      estateDwgOnly: true,
    }
  },
  computed: {
    siteId () {
      return Number(this.$route.params.id_site)
    },
    calendarDate () {
      return this.$store.state.app.currentDate
    },
    siteView () {
      const route = this.$route.name
      return !(route.endsWith('documents') || route.endsWith('photos'))
    },
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapGetters('sites', ['buildingsForCurrentSite', 'cemeteriesForCurrentSite',]),
    ...mapState('app', ['sites']),
  },
  watch: {
    async siteId () {
      this.isFavourite = false
      this.checkFacilityManagementAvailability()
      await this.loadSiteData()
      this.$store.dispatch('app/getFavourites')
    },
    calendarDate: async function () {
      this.checkFacilityManagementAvailability()
      this.loadSiteData()
    },
    $route (to, from) {
      if (from.path.includes('facilitymanagement')) {
        this.checkFacilityManagementAvailability()
      }
    }
  },
  async created () {
    this.checkFacilityManagementAvailability()
    await this.loadSiteData()
  },
  methods: {
    ...mapMutations('sites', ['setCurrentSite', 'setSiteTabLocation', 'removeSite']),
    ...mapMutations('app', ['setDwgsAvailable']),
    ...mapActions('sites', [
      'loadSite',
      'loadSiteAdditionalReports',
      'loadSiteReports',
      'loadSiteParties',
      'getSitePermissions'
    ]),
    ...mapActions('leasing', [
      'LoadLeasingSiteWidgetData',
    ]),
    getFacilityManagementTooltipText () {
      if (!this.hasApplicationPermissionByName('KOHDE_POHJAKUVAT') && !this.isFacilityManagementAvailable) {
        return this.$t('Facility management usergroup info');
      } else if (this.hasApplicationPermissionByName('KOHDE_POHJAKUVAT') && !this.isFacilityManagementAvailable) {
        return this.$t('Facility management no files info');
      } else {
        return this.$t('Facility management usergroup info');
      }
    },
    updateFavourites: async function () {
      if (
        this.$store.state.app.favourites.find(f => f === this.siteId) &&
        this.isFavourite
      ) {
        await this.$rambollfmapi.accounts.favourites().delete(this.siteId)
        this.$store.dispatch('app/getFavourites')
        this.isFavourite = false
      } else if (
        !this.$store.state.app.favourites.find(f => f === this.siteId) &&
        !this.isFavourite
      ) {
        await this.$rambollfmapi.accounts.favourites().post(this.siteId)
        this.$store.dispatch('app/getFavourites')
        this.isFavourite = true
      } else {
        this.$store.dispatch('app/getFavourites')
      }
    },
    refresh: function () {
      this.removeSite({ siteId: this.siteId })
      this.setSiteTabLocation({ siteId: this.siteId, location: this.$route.name })
      this.loadSiteData()
    },
    loadSiteData: async function () {
      this.setCurrentSite({ siteId: this.siteId })
      await this.loadSite(this.siteId)
      this.loadSiteParties(this.siteId)
      this.loadSiteReports(this.siteId)
      this.LoadLeasingSiteWidgetData()
      this.getSitePermissions(this.siteId)
      if (this.$route.name.endsWith('additionalInfo')) {
        this.loadSiteAdditionalReports(this.siteId)
      }
      if (this.$store.state.app.favourites.find(f => f === this.siteId)) {
        this.isFavourite = true
      } else {
        this.isFavourite = false
      }
    },
    async checkFacilityManagementAvailability () {        
      if (this.$route.name.endsWith('facilitymanagement')) {
        this.isFacilityManagementAvailable = true
        return
      }
      this.isFacilityManagementAvailable = false
      const idSite = this.$route.params.id_site
      const queryString = { query: { site_id: idSite, time: this.calendarDate } }
      const floors = await this.$rambollfmapi.floors.list(queryString)   
      floors.map(f => {
        if (f.has_dwg || f.has_xml) {
          if (idSite === this.$route.params.id_site) {
            this.isFacilityManagementAvailable = true
            this.setDwgsAvailable(true)
          }
        }
      })
      if (!this.isFacilityManagementAvailable) {
        const estates = await this.$rambollfmapi.estates.list({ query: { siteId: idSite, time: this.calendarDate } })
        estates.forEach(estate => {
          if (estate.has_dwg) {
            this.isFacilityManagementAvailable = true
          }
        })
      }     
    }
  }
}
</script>
<style scoped>
.menu {
  width: auto;
}
.menu .v-btn__content {
  min-width: 200px !important;
}
</style>
