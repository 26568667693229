<template>
  <div>
    <BaseModal
      @cancel="emit"
    >
      <template #title>
        {{ $t('Edit tenants') }}
      </template>
      <template #content>
        <v-form
          v-if="unit"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-btn
              text
              @click="changeMonth(-1)"
            >
              <v-icon
                dark
                left
              >
                keyboard_arrow_left
              </v-icon>
            </v-btn>
            <v-text-field
              v-model="selectedDate"
              type="date"
            />
            <v-btn
              text
              @click="changeMonth(1)"
            >
              <v-icon
                right
                dark
              >
                keyboard_arrow_right
              </v-icon>
            </v-btn>
          </v-row>
          <br>
          <v-calendar
            v-if="events"
            ref="calendar"
            type="month"
            :value="selectedDate"
            :weekdays="weekdays"
            class="calendar-style"
            :weekday-format="formatWeekday"
            :month-format="formatMonth"
            :disabled="disabled"
            @change="resetDateSpan"
          >
            <template #day="{ date }">
              <v-btn
                v-for="event in eventForDay(date)"
                :key="event.id"
                block
                :color="event.color"
                class="fill-btn"
                :style="{ width: '100%' }"
                :class="{
                  bgnevent: isFirstInstance(date, event),
                  mdlevent: isFirstInstanceInSpan(date, event),
                  endevent: isLastInstance(date, event),
                  emptyevent: event.id === 0,
                  notrentable: event.id === -1,
                }"
                @click="
                  selectLink(event, date)
                  warnings = false
                  dateWarning = false
                  endDateWarning = false
                  showTenantDialog = true
                "
              >
                <span class="buttontext">
                  {{ event.name }}
                </span>
              </v-btn>
            </template>
          </v-calendar>
        </v-form>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer />
    </BaseModal>

    <v-dialog 
      v-model="showTenantDialog"
      persistent
      max-width="700"
    >
      <BaseModal
        @cancel="
          showTenantDialog = false
          selectedLink = null
          dateWarning = false
          endDateWarning = false
        "
      >
        <template #title>
          {{ $t('Edit tenant information') }}
        </template>
        <template #content>
          <v-expansion-panels
            v-if="selectedLink" 
            id="noRadius"
            :value="[0]"
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ selectedLink.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list
                  dense
                  class="info-box"
                >
                  <v-list-item
                    v-if="selectedLink.id === 0"
                    class="tile"
                  >
                    <v-list-item-content class="tile-content">
                      {{
                        $t('Tenant')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <v-autocomplete
                        v-model="selectedLink.tenant"
                        small
                        :items="parties"
                        clearable
                        item-text="name"
                        item-value="id"
                        class="borderless"
                        append-outer-icon="edit"
                        :placeholder="$t('Select')"
                        menu-props="offsetY"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    class="tile"
                  >
                    <v-list-item-content class="tile-content">
                      {{
                        $t('VAT')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <v-select
                        v-model="unitData.alv"
                        small
                        :items="[
                          { text: $t('Yes'), value: true },
                          { text: $t('No'), value: false },
                        ]"
                        item-text="text"
                        item-value="value"
                        class="borderless"
                        append-outer-icon="edit"
                        :placeholder="alvValue(unit.alv)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="tile">
                    <v-list-item-content class="tile-content">
                      {{
                        $t('Start date')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <v-text-field
                        v-model="selectedLink.start_date"
                        clearable
                        class="borderless"
                        type="date"
                        :disabled="disabled"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="tile">
                    <v-list-item-content class="tile-content">
                      {{
                        $t('End date')
                      }}
                    </v-list-item-content>
                    <v-list-item-content class="tile-content">
                      <v-text-field
                        v-model="selectedLink.end_date"
                        class="borderless"
                        clearable
                        type="date"
                        :disabled="disabled"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template #hideCancel>
          <p />
        </template>
        <template #footer>
          <v-row>
            <div v-if="dateWarning">
              <strong>{{ $t('Error!') }}</strong>
              <slot v-if="endDateWarning">
                {{
                  $t('Start date') + ', ' + $t('End date')
                }}
              </slot>
              <slot v-else>
                {{
                  $t('Unit already has a tenant in chosen period')
                }}
              </slot>
            </div>
            <div v-if="tenantEmptyWarning">
              <strong>{{ $t('Error!') }}</strong>
              {{
                $t('You must select tenant')
              }}
            </div>
          </v-row>
          <v-row
            v-if="selectedLink && !warnings"
            v-show="!disabled"
            align="end"
            justify="end"
          >
            <v-btn
              outlined
              rounded
              text
              @click="
                showTenantDialog = false
                selectedLink = null
                dateWarning = false
                endDateWarning = false
              "
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              v-if="selectedLink.id !== 0"
              color="danger"
              text
              class="error"
              @click="
                showDeleteWarning = true
                showEndContractWarning = false
                dateWarning = false
                endDateWarning = false
                warnings = true
              "
            >
              {{ $t('Delete') }}
            </v-btn>
            <v-btn
              v-if="
                selectedLink.id !== 0 &&
                  selectedLink.start_date <= currentDate &&
                  selectedLink.end_date > currentDate &&
                  selectedLink.end_date !== null
              "
              text
              class="warning"
              @click="
                showDeleteWarning = false
                showEndContractWarning = true
                dateWarning = false
                endDateWarning = false
                warnings = true
              "
            >
              {{ $t('End rental contract') }}
            </v-btn>
            <ExportTenantToFm
              v-if="
                hasApplicationPermissionByName('VUOKRASOPIMUKSET') &&
                  selectedLink !== null &&
                  allPartyLinks.find((obj) => {
                    return obj.id === selectedLink.id
                  }) !== undefined
              "
              :building-code="buildingCode"
              :area="unit.area"
              :lease="selectedLink"
              :tenant="selectedLink.name"
              :start-date="selectedLink.start_date"
              :end-date="
                allPartyLinks.find((obj) => {
                  return obj.id === selectedLink.id
                }).end_date || null
              "
              :link-id="selectedLink.id"
            />
            <v-btn
              :disabled="!selectedLink || !valid ||isSaving"
              rounded
              depressed
              color="primary"
              @click="saveLink"
            >
              {{ buttonText() }}
            </v-btn>
          </v-row>
          <v-row
            v-if="
              selectedLink &&
                showDeleteWarning &&
                !showEndContractWarning &&
                warnings
            "
            align="end"
            justify="end"
          >
            <strong>{{
              $t('Do you really want to delete? History data will be lost')
            }}</strong>
          </v-row>
          <v-row
            v-if="
              selectedLink &&
                !showDeleteWarning &&
                showEndContractWarning &&
                warnings
            "
            align="end"
            justify="end"
          >
            <strong>{{
              $t('Do you really want to end rental contract?')
            }}</strong>
          </v-row>
          <v-row
            v-if="
              selectedLink &&
                showDeleteWarning &&
                !showEndContractWarning &&
                warnings
            "
            align="end"
            justify="end"
          >
            <v-btn
              outlined
              rounded
              text
              @click="
                showDeleteWarning = false
                warnings = false
              "
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              v-if="selectedLink.id !== 0"
              color="danger"
              text
              class="error"
              @click="
                deleteLink()
                showTenantDialog = false
                showEndContractWarning = false
                warnings = false
              "
            >
              {{ $t('Delete') }}
            </v-btn>
          </v-row>
          <v-row
            v-if="
              selectedLink &&
                !showDeleteWarning &&
                showEndContractWarning &&
                warnings
            "
            align="end"
            justify="end"
          >
            <v-btn
              outlined
              rounded
              text
              @click="
                showEndContractWarning = false
                warnings = false
              "
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              v-if="selectedLink.id !== 0"
              text
              class="warning"
              @click="
                endContract()
                showTenantDialog = false
                showEndContractWarning = false
                warnings = false
              "
            >
              {{ $t('End rental contract') }}
            </v-btn>
          </v-row>
        </template>
      </BaseModal>
    </v-dialog>
  </div>
</template>
<script>
import ExportTenantToFm from './ExportTenantToFm'
import { mapGetters } from 'vuex'
import moment from 'moment'
import BaseModal from '../../components/general/BaseModal.vue'
export default {
  name: 'TenantModifyForm',
  components: {
    ExportTenantToFm,
    BaseModal,
  },
  props: {
    disabled: Boolean,
    parties: {
      type: Array, 
      default: () => []
      },
    unit: { 
      type: Object,
      default: null
    },
    links: {
      type: Array,
      default: () => []
    },
    buildingCode: {
      type: String,
      default: ''
    }
  },
   data () {
    return {
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      weekdaysString: ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
      monthsString: [
        'Tammi',
        'Helmi',
        'Maalis',
        'Huhti',
        'Touko',
        'Kesä',
        'Heinä',
        'Elo',
        'Syys',
        'Loka',
        'Marras',
        'Joulu'
      ],
      firstDate: null,
      lastDate: null,
      modifiedUnits: {},
      allPartyLinks: [],
      selectedLink: null,
      events: [],
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedDate: moment(new Date()).format('YYYY-MM-DD'),
      valid: true,
      dayValues: {},
      dateWarning: false,
      warnings: false,
      showDeleteWarning: false,
      showEndContractWarning: false,
      showTenantDialog: false,
      tenantEmptyWarning: false,
      unitData: {},
      childMessage: null,
      contractDatas: [],
      isSaving: false,
      endDateWarning: false,
    }
  },
  computed: {
    ...mapGetters('app', [
      'definitionByLabel',
      'definitionLabelById',
      'hasApplicationPermissionByName'
    ])
  },
  watch: {
    unit: {
      deep: true,

      handler (oldVal, newVal) {
        if (this.unit !== null && this.unit !== undefined) {
          this.$rambollfmapi.units
            .partyLinks(this.unit.id)
            .list(this.selectedDate, true)
            .then(res => {
              this.allPartyLinks = res
            })

          this.unitData = {
            alv: this.unit.alv !== null ? this.unit.alv : false
          }
        }
      }
    },
    allPartyLinks: function (links) {
      if (links.length > 0) {
        this.contractDatas = []
        links.forEach(party => {
          this.$rambollfmapi.units
            .partyLinks(this.unit.id)
            .rentalContracts()
            .getByLinkId(party.id_party)
            .then(res => {
              res.forEach(r => {
                this.contractDatas.push({
                  idParty: party.id_party,
                  start_date: r.party_start_date,
                })
              })
            })
        })
      }
    },
    selectedDate: {
      handler (newVal, oldVal) {
        this.$rambollfmapi.units
        .partyLinks(this.unit.id)
        .list(this.selectedDate, true)
        .then(res => {
          this.allPartyLinks = res
        })
      }
    }
  },
  async mounted () {
    if (this.unit !== null && this.unit !== undefined) {
      this.allPartyLinks = await this.$rambollfmapi.units
              .partyLinks(this.unit.id)
              .list(this.selectedDate, true)
      this.unitData = {
              alv: this.unit.alv !== null ? this.unit.alv : false
            }
    }
  },
  methods: {
    emit (event) {
      this.selectedLink = null
      this.$emit('childToParent', false)
      this.$emit('update')
      this.close()
    },
    buttonText () {
      if (this.selectedLink && !this.warnings) {
        if (this.selectedLink.id === 0) {
          return this.$t('Add tenant')
        } else if (this.selectedLink.id !== 0) {
          return this.$t('Save')
        }
      } else {
        return this.$t('Save')
      }
    },
    alvValue () {
      if (this.selectLink && !this.warnings) {
        if (this.unit.alv === true) {
          return this.$t('Yes')
        } else {
          return this.$t('No')
        }
      }
    },
    resetDateSpan (value) {
      this.firstDate = null
      this.lastDate = null
      this.dateWarning = false
      this.endDateWarning = false
    },
    isFirstInstance (date, event) {
      if (event.id === 0) {
        return false
      } else if (event.id === -1) {
        if (moment(event.start).isSame(date, 'day')) {
          return true
        } else {
          return false
        }
      } else if (
        moment(this.getLink(event.id).start_date).isSame(date, 'day')
      ) {
        return true
      } else {
        return false
      }
    },
    isLastInstance (date, event) {
      if (event.id === 0 || event.id === -1) {
        return false
      } else if (date === this.lastDate) {
        return true
      } else if (moment(event.end).isSame(date, 'day')) {
        return true
      } else {
        return false
      }
    },
    isFirstInstanceInSpan (date, event) {
      if (event.id === 0 || event.id === -1) {
        return false
      } else if (moment(date).isSame(this.firstDate, 'day')) {
        return true
      } else {
        return false
      }
    },
    getLink (linkId) {
      return this.allPartyLinks.find(link => link.id === linkId)
    },
    setDates (date) {
      if (this.firstDate === null) {
        this.firstDate = JSON.parse(JSON.stringify(date))
      }
      if (this.lastDate === null) {
        this.lastDate = JSON.parse(JSON.stringify(date))
      }
      if (moment(date).isBefore(this.firstDate)) {
        this.firstDate = JSON.parse(JSON.stringify(date))
      }
      if (moment(date).isAfter(this.lastDate)) {
        this.lastDate = JSON.parse(JSON.stringify(date))
      }
    },
    formatWeekday (date) {
      return this.weekdaysString[date.weekday]
    },
    formatMonth (date) {
      return this.monthsString[date.month - 1]
    },
    close () {
      this.selectedDate = moment(new Date()).format('YYYY-MM-DD')
      this.selectedLink = null
      this.dateWarning = false
      this.endDateWarning = false
      this.$emit('update')
      this.$emit('close')
    },
    changeMonth (int) {
      this.selectedDate = moment(this.selectedDate)
        .add(int, 'month')
        .format('YYYY-MM-DD')
    },
    deleteLink () {
      if (this.unit !== null && this.unit !== undefined) {
        this.$rambollfmapi.units
          .partyLinks(this.unit.id)
          .fullDelete(this.unit.id, this.selectedLink.id)
          .then(res => {
            this.refresh()
          })
      }
    },
    async saveLink () {
      // prevent saving end date earlier than start date
      if (this.selectedLink.start_date === null || this.selectedLink.start_date > this.selectedLink.end_date) {
        this.endDateWarning = true
      } else {
        this.endDateWarning = false
      }      
      // variable to prevent repeated clicking
      this.isSaving = true
      const overlaps = this.checkForOverlaps()
      if (!this.endDateWarning && overlaps === 0) {
        if (this.selectedLink.id === 0) {
          //prevent saving if tenant is not selected
          if (!this.selectedLink.tenant) {
              this.tenantEmptyWarning = true
              this.dateWarning = false
            } else {
              // createlink in API reduces one day from the end date so add one extra here to compensate
              let endDate = this.selectedLink.end_date !== null ? new Date(this.selectedLink.end_date) : null
              if (endDate !== null) {
                endDate.setDate(endDate.getDate() + 1)
              }
              const newLink = {
                id: 0,
                id_party: this.selectedLink.tenant,
                id_annotation: this.definitionByLabel('Tenant').id,
                start_date: this.selectedLink.start_date,
                end_date: endDate,
                group: 3,
                order: 0
              }

              if (this.unit !== undefined && this.unit !== null) {
                var createdLink = await this.$rambollfmapi.units
                  .partyLinks(this.unit.id)
                  .post(this.unit.id, newLink)
                // Create unit patch version
                const unitPatch = Object.keys(this.unitData).map(key => {
                  return {
                    value: this.unitData[key],
                    path: key,
                    op: 'replace'
                  }
                })
                await this.$rambollfmapi.units.patch(
                  this.unit.id,
                  newLink.start_date,
                  unitPatch
                  )
                const contractData = {
                     contract_number: null,
                     contract_area: null
                }
                await this.$rambollfmapi.units
                  .partyLinks(this.unit.id)
                  .rentalContracts()
                  .post(createdLink.id, contractData)

                this.refresh()
              }
            }
        } else {
          const unitPatch = [
            {
              value: this.unitData.alv,
              path: '/alv',
              op: 'replace'
            }
          ]
          const linkPatch = [
            {
              value: this.selectedLink.start_date,
              path: '/start_date',
              op: 'replace'
            },
            {
              value: this.selectedLink.end_date,
              path: '/end_date',
              op: 'replace'
            }
          ]
          if (this.unit !== undefined && this.unit !== null) {
            await this.$rambollfmapi.units.patch(
              this.unit.id,
              this.currentDate,
              unitPatch
            )
            await this.$rambollfmapi.units
              .partyLinks(this.unit.id)
              .purePatch(this.unit.id, this.selectedLink.id, 0, linkPatch)

            let currentLink = this.getLink(this.selectedLink.id)

            if (currentLink.end_date !== this.selectedLink.end_date) {
              let terminationDate = null
              if (this.selectedLink.end_date != null) {
                terminationDate = this.currentDate
              } 
              const contractPatch = [
                {
                  value: terminationDate,
                  path: '/termination_date',
                  op: 'replace'
                }
              ]
              await this.$rambollfmapi.units
                .partyLinks(this.unit.id)
                .rentalContracts()
                .patch(this.selectedLink.id, contractPatch)
            }
            this.refresh()
          }
        }
      } else {
        this.dateWarning = true
        this.tenantEmptyWarning = false
      }
      this.isSaving = false
    },
    refresh () {
      this.selectedLink = null
      this.dateWarning = false
      this.endDateWarning = false
      this.tenantEmptyWarning = false
      this.showTenantDialog = false
      if (this.unit !== undefined && this.unit !== null) {
        this.$rambollfmapi.units
          .partyLinks(this.unit.id)
          .list(this.selectedDate, true)
          .then(res => {
            this.allPartyLinks = res
          })
      }
    },
    generateColor (seed) {
      const color = Math.floor(
        Math.abs(Math.sin(seed) * 16777215) % 16777215
      ).toString(16)
      return '#' + color
    },
    selectLink (selection, date) {
      const startDay = moment(selection.start).format('YYYY-MM-DD')
      let endDay = selection.end
      if (selection.end !== null) {
        endDay = moment(selection.end).format('YYYY-MM-DD')
      }

      if (selection.id === 0) {
        this.selectedLink = {
          id: selection.id,
          name: this.$t('New tenant'),
          start_date: startDay,
          end_date: null
        }
        return
      }
      const found = this.allPartyLinks.find(link => {
        return link.id === selection.id
      })
      this.selectedLink = {
        id: found.id,
        name: selection.name,
        start_date: startDay,
        end_date: endDay
      }
    },
    checkForOverlaps () {
      const startDate = this.selectedLink.start_date ? moment(this.selectedLink.start_date).startOf('day').valueOf() : null
      const endDate = this.selectedLink.end_date ? moment(this.selectedLink.end_date).endOf('day').valueOf() : null
      const found = this.allPartyLinks.filter(link => {
        return (
          link.id !== this.selectedLink.id && (link.end_date === null || startDate <= Date.parse(link.end_date)) &&
          (endDate >= Date.parse(link.start_date) || endDate === null)
        )
      })
      return found.length
    },
    eventForDay (day) {
      this.setDates(day)
      const dayTime = new Date(day)
      const found = this.allPartyLinks.filter(link => {
        const startDate = moment(link.start_date)
        const endDate = moment(link.end_date)
        return (
          startDate.isSameOrBefore(dayTime, 'date') &&
          (link.end_date === null || endDate.isSameOrAfter(dayTime, 'date'))
        )
      })
      // We get an previously ended link if there is one and get it's end_date to help marking correct unrentable period
      const past = this.allPartyLinks.filter(link => {
        const endDate = moment(link.end_date)
        return endDate.isSameOrBefore(dayTime, 'date')
      })
      const pastLinkEnd = past.map(x => {
        return moment(new Date(x.end_date)).format('YYYY-MM-DD')
      })
      // We get link starting in future to get it's start-date to help marking correct unrentable period
      const future = this.allPartyLinks.filter(link => {
        const startDate = moment(link.start_date)
        return startDate.isAfter(dayTime, 'date')
      })
      const futureLinkStart = future.map(x => {
        return moment(new Date(x.start_date)).format('YYYY-MM-DD')
      })

      if (found.length === 0) {
        if (
          this.unit !== undefined &&
          this.unit.not_rentable !== 'rentable' &&
          this.unit.not_rentable !== 'rentable.koy'
        ) {
          // if unit is non-rentable...
          if (pastLinkEnd.length === 0 && futureLinkStart.length === 0) {
            return [
              {
                name: this.$t(this.unit.not_rentable),
                id: -1,
                start: new Date(day),
                end: new Date(day),
                color: '#cccccf'
              }
            ]
          }
          if (pastLinkEnd.length > 0 && futureLinkStart.length === 0) {
            if (day >= pastLinkEnd) {
              return [
                {
                  name: this.$t(this.unit.not_rentable),
                  id: -1,
                  start: new Date(day),
                  end: new Date(day),
                  color: '#cccccf'
                }
              ]
            }
          }
          if (pastLinkEnd.length > 0 && futureLinkStart.length > 0) {
            if (day >= pastLinkEnd && day < futureLinkStart) {
              return [
                {
                  name: this.$t(this.unit.not_rentable),
                  id: -1,
                  start: new Date(day),
                  end: new Date(day),
                  color: '#cccccf'
                }
              ]
            }
          }
        } else {
          return [
            {
              name: this.$t('Empty'),
              id: 0,
              start: new Date(day),
              end: new Date(day),
              color: '#FFFFFF'
            }
          ]
        }
      }
      return found.map(link => {
        return {
          name: this.getPartyName(link.id_party),
          start: link.start_date,
          end: link.end_date,
          id: link.id,
          color: this.generateColor(link.id)
        }
      })
    },
    async validate () {
      await this.patch()
      this.$emit('close')
    },
    getTenantForUnit (unit) {
      const partyLink = this.getLinkForUnit(unit)
      if (!partyLink) {
        return { name: this.$t('Empty') }
      } else {
        return this.parties.find(p => p.id === partyLink.id_party)
      }
    },
    getPartyName (id) {
      return this.parties.find(p => p.id === id).name
    },
    getLinkForUnit (unit) {
      return this.allPartyLinks.find(upl => {
        return upl.id_parent === unit.id
      })
    },
    endContract () {
      if (this.selectedLink.end_date === null) {
        this.selectedLink.end_date = moment(new Date()).format('YYYY-MM-DD')
      }
      this.saveLink()
      }
  }
}
</script>
<style scoped>
.v-datatable tbody tr td v-text-field {
  width: 10px;
}

#noRadius {
  border-radius: 1.3em;
}

.fill-btn {
  border-radius: 0;
  box-shadow: 0;
}

.buttontext {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.calendar-style {
  border: 1px solid lightblue;
}

.calendar-style.v-calendar-weekly >>> div.v-calendar-weekly__day {
  padding: 32px 0 4px 0;
  border-right: 0;
}

.calendar-style.v-calendar-weekly >>> .fill-btn {
  /* box-shadow: 0 0.1em 0.5em 0.1em rgba(0, 0, 0, 0.5) !important; */
  box-shadow: none !important;
  border-bottom: 0.3em solid rgba(0, 0, 0, 0.5) !important;
  color: transparent;
  /* border: 1px solid rgba(0, 0, 0, 0.5) !important; */
}

.fill-btn >>> div {
  align-items: left;
}

.calendar-style.v-calendar-weekly >>> .bgnevent {
  border-top-left-radius: 1em !important;
  border-bottom-left-radius: 1em !important;
  padding-left: 0.2em;
  margin-left: 0.1em;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.calendar-style.v-calendar-weekly >>> .mdlevent {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.calendar-style.v-calendar-weekly >>> .endevent {
  border-top-right-radius: 1em !important;
  border-bottom-right-radius: 1em !important;
}

.calendar-style.v-calendar-weekly >>> .emptyevent {
  color: lightblue;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding-right: 0.2em;
  margin-right: 0.1em;
}

.calendar-style.v-calendar-weekly >>> .notrentable {
  color: white;
  border-top-left-radius: 0em !important;
  border-bottom-left-radius: 0em !important;
  border: none !important;
  box-shadow: none !important;
  padding-right: 0.2em;
  margin-right: 0.1em;
}

.calendar-style.v-calendar-weekly >>> .v-btn__content {
  width: 100%;
}

/* div .v-calendar-weekly {
  border-right: 0 !important;
  background-color: pink !important;
}

div >>> .v-btn {
  background-color: lime !important;
} */
</style>
