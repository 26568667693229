<template>
  <v-expansion-panels
    v-if="carpark"
    class="my-2"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="start"
        >
          <div
            text-right
          >
            {{ $t('leasing.vacancy_rate_assumption') }}
          </div>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <MetadataForm
          :metadata="getMetadataForCarPark(carpark)"
          :data="getDataForCarpark(carpark)"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import MetadataForm from '../../../components/MetadataForm'
import humanize from '../../../helpers/humanize'

export default {
  name: 'CarpakVacancyRateAssumptionInfo',
  components: {
    MetadataForm
  },
  props: {
    carpark: { type: Object, default: null },
  },
  methods: {
    getVacancyRateAssumptionDate (carpark) {
      return carpark.vacancy_rate_assumption_date ? `${carpark.vacancy_rate_assumption_user}, ${humanize.date(carpark.vacancy_rate_assumption_date)}` : '';
    },
    getDataForCarpark (carpark) {
      return {
        vacancy_rate_assumption: carpark.vacancy_rate_assumption,
        vacancy_rate_assumption_updated: this.getVacancyRateAssumptionDate(carpark), 
      }
    },
    getMetadataForCarPark () {
      return {
        vacancy_rate_assumption: { isShown: true, category: 'None' },
        vacancy_rate_assumption_updated: { isShown: true, category: 'None' },
      }
    }
  }
}
</script>
<style scoped>
</style>
